import { Card, ListGroup, Spinner } from "react-bootstrap";
import ObservationListItem from "../ListItems/ObservationListItem";

export default function ObservationsList({ observations, isFetching }) {
  return (
    <Card>
      <ListGroup variant="flush">
        {isFetching ? (
          <div class="d-flex justify-content-center m-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          observations?.map((observation) => (
            <>
              <ObservationListItem observation={observation} />
            </>
          ))
        )}
      </ListGroup>
    </Card>
  );
}
