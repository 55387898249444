import React from "react";

import FormSearchCases from "./Modals/FormSearchCases";
import FormRegisterCase from "./FormRegisterCase";
import BaseLayout from "../../baseLayout";

function SupportCase() {
  return (
    <BaseLayout title={"Cadastro de Atendimento"} page={"support_case"}>
      <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
        <div className="d-flex align-items-center justify-content-end col-12 col-sm-12 col-md-12 col-lg-6">
          <FormSearchCases />
        </div>

        <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-3"></div>
      </div>
      <p className="fs-5 text-left">
        Preencha os campos para cadastrar um novo atendimento
      </p>

      <FormRegisterCase />
    </BaseLayout>
  );
}

export default SupportCase;
