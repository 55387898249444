import {
  Document,
  PDFViewer,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Container, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { format, parseISO } from "date-fns";
import { fontSemiBold } from "../../utils/fontWeight";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 15,
    paddingBottom: 20,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    marginLeft: 0,
    marginRight: 20,
    padding: 0,
    flexGrow: 1,
  },
  headerTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  headerSubtitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
  },
  headerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  body: {
    fontSize: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    border: "1 solid #c9c9c9",
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  bodyTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  bodySubtitle: {
    fontSize: 10,
    paddingBottom: 2,
    marginBottom: 5,
  },
  itemDiv: {
    marginTop: 10,
    borderBottom: "1 solid #c9c9c9",
    textAlign: "justify",
  },
});

const Reports = ({ cases, observations, id }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerDiv}>
            <Text style={styles.headerSubtitle}>
              Atendimento: <Text style={styles.headerTitle}>#{id}</Text>
            </Text>
          </View>

          <View style={styles.body}>
            <View style={styles.table}>
              <Text style={styles.bodySubtitle}>
                Título: <Text style={styles.bodyTitle}>{cases.title}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Cliente:{" "}
                <Text style={styles.bodyTitle}>{cases.client_name}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Descrição:{" "}
                <Text style={styles.bodyTitle}>
                  {cases?.description
                    ? cases.description
                    : "Nenhuma descrição cadastrada."}
                </Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Observação:{" "}
                <Text style={styles.bodyTitle}>
                  {cases?.obs ? cases.obs : "Nenhuma observação cadastrada."}
                </Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Responsável:{" "}
                <Text style={styles.bodyTitle}>{cases.employee_name}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Data criação:{" "}
                <Text style={styles.bodyTitle}>
                  {cases?.created_at
                    ? format(parseISO(cases.created_at), "dd/MM/yyyy HH:mm")
                    : null}
                </Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Atualizado em:{" "}
                <Text style={styles.bodyTitle}>
                  {cases?.updated_at
                    ? format(parseISO(cases?.updated_at), "dd/MM/yyyy HH:mm")
                    : null}
                </Text>
              </Text>
            </View>

            <Text style={{ ...styles.headerSubtitle, marginTop: 10 }}>
              Histórico
            </Text>

            {observations.map((item) => {
              return (
                <View key={item.id} style={styles.itemDiv}>
                  <Text
                    style={{
                      ...styles.bodySubtitle,
                      ...fontSemiBold,
                    }}
                  >
                    {format(parseISO(item.created_at), "dd/MM/yyyy HH:mm")}
                  </Text>
                  <Text
                    style={{
                      ...styles.bodySubtitle,
                      lineHeight: 1.8,
                    }}
                  >
                    {item.observation}
                  </Text>
                </View>
              );
            })}

            {!observations || observations?.length === 0 ? (
              <View style={styles.itemDiv}>
                <Text style={styles.bodySubtitle}>
                  Nenhum histórico registrado
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const SupportCaseDetail = ({ show, handleClose, cases, observations, id }) => {
  const returnFileNamePDF = () => {
    return `atendimento-${id}-${new Date().getTime()}`;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="xl"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Atendimento disponível para download</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          {isMobile ? (
            <PDFDownloadLink
              document={
                <Reports cases={cases} observations={observations} id={id} />
              }
              fileName={returnFileNamePDF()}
            >
              Download: {returnFileNamePDF()}.pdf
            </PDFDownloadLink>
          ) : (
            <PDFViewer width={"100%"} height={"900px"} title="Relatório">
              <Reports cases={cases} observations={observations} id={id} />
            </PDFViewer>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default SupportCaseDetail;
