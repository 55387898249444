import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";
import { SelectState } from "../../../components/Selects/SelectState";
import { SelectCity } from "../../../components/Selects/SelectCity";
import ClientsTable from "../../../components/Tables/ClientsTable";
import ClientsEditForm from "../../../components/EditForms/ClientEditForm";

export default function FormPesquisaClientes(props) {
  const [modalShow1, setModalShow1] = React.useState(false);
  const [selectedUf, setSelectedUf] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [editClient, setEditClient] = useState(0);
  const { register, handleSubmit, reset } = useForm();

  const onSubmitSearchUser = (values) => {
    setSearchFilter(values);
  };

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow1(true)}
      >
        <i className="bi bi-funnel"></i>&nbsp; Listar Clientes
      </Button>
      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i className="bi bi-funnel"></i>&nbsp; Consulta de clientes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#edebe9" }}>
          <Form onSubmit={handleSubmit(onSubmitSearchUser)}>
            <Form.Group>
              <p className="fs-5 fw-bold">Preencha o(s) campo(s)</p>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Nome do cliente">
                    <Form.Control
                      type="text"
                      name="name"
                      {...register("name")}
                      placeholder="Insira o nome do cliente"
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Data">
                    <Form.Control
                      type="date"
                      {...register("date")}
                      name="date"
                      placeholder="Insira uma data válida"
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectState
                    onChange={setSelectedUf}
                    selected={selectedUf}
                    register={register}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectCity uf={selectedUf} register={register} />
                </div>
                <div className="w-100">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                    <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                      <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                        <Button
                          className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                          type="submit"
                          style={{ backgroundColor: "#DAA520" }}
                        >
                          <i className="bi bi-funnel"></i>&nbsp; Consultar
                          clientes
                        </Button>
                      </div>
                      <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                        {searchFilter ? (
                          <Button
                            className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                            type="button"
                            onClick={() => {
                              setSearchFilter();
                              reset();
                              setSelectedUf("");
                            }}
                            style={{ backgroundColor: "#DAA520" }}
                          >
                            <i className="bi bi-funnel"></i>&nbsp; Limpar
                            Filtros
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form>
          {editClient == 0 ? (
            <div className="w-100">
              <div className="col-12" style={{ height: 400 }}>
                <div style={{ maxHeight: 100 }}>
                  <ClientsTable
                    setEditClient={setEditClient}
                    filter={searchFilter}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {editClient != 0 ? (
            <ClientsEditForm
              client={editClient}
              setEditClient={setEditClient}
            />
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
