import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/pt-br";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import "./styles.css";
import CalendarEventCommitmentModal from "../../Modals/CalendarEventCommitmentModal";

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main 'About' page in Storybook
 */
export function AppointmentsCalendar({
  localizer = mLocalizer,
  showDemoLink = true,
  ...props
}) {
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  );

  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [
    calendarEventCommitmentModalShow,
    setCalendarEventCommitmentModalShow,
  ] = useState(false);

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event);
    setCalendarEventCommitmentModalShow(true);
  };

  const events = props.events?.map((event) => {
    const container = {};

    container.id = event.id;
    container.title = event.title;
    container.commitment_type_id = event.commitment_type_id;
    container.start = new Date(Date.parse(event.start_date));
    container.end = new Date(Date.parse(event.end_date));
    container.status = event.status;
    container.user_id = event.user_id;
    container.client_id = event.client_id;
    container.obs_general = event.obs_general;
    container.notification_lawyer_time = event.notification_lawyer_time;

    return container;
  });

  return (
    <Fragment>
      <div className="height600" {...props}>
        <CalendarEventCommitmentModal
          event={selectedEvent}
          {...{
            calendarEventCommitmentModalShow,
            setCalendarEventCommitmentModalShow,
            types: props.types,
          }}
        />
        <Calendar
          components={components}
          defaultDate={defaultDate}
          events={events}
          localizer={localizer}
          max={max}
          showMultiDayTimes
          step={60}
          views={views}
          messages={{
            agenda: "Agenda",
            allDay: "Dia Todo",
            month: "Mês",
            day: "Dia",
            today: "Hoje",
            previous: "Anterior",
            next: "Próximo",
            date: "Data",
            noEventsInRange: "Não há eventos no período",
            time: "Horário",
            tomorrow: "Amanhã",
            week: "Semana",
            work_week: "Dias úteis",
            yesterday: "Ontem",
          }}
          // onSelectSlot={(e) => handleSelect(e)}
          onSelectEvent={(e) => handleSelectedEvent(e)}
        />
      </div>
    </Fragment>
  );
}
AppointmentsCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  showDemoLink: PropTypes.bool,
};
