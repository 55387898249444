import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import axiosAPI from "../../axiosAPI";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { SelectState } from "../../components/Selects/SelectState";
import { SelectCity } from "../../components/Selects/SelectCity";
import { Button, Spinner } from "react-bootstrap";
import { SelectEmployeesByRoles } from "../../components/Selects/SelectEmployeesByRoles";
import { toast } from "react-toastify";

export default function FormCadastrarCliente(props) {
  const { register, handleSubmit, reset } = useForm();

  const [cpf, setCpf] = React.useState("");
  const [selectedUf, setSelectedUf] = useState();
  const [isSubmiting, setIsSubmitting] = useState(false);

  function handleDocumentCpf(value) {
    setCpf(value);
  }

  const onSubmit = (values) => {
    setIsSubmitting(true);
    axiosAPI
      .post("/clients", values)
      .then((result) => {
        if (result.status == 200) {
          reset();
          setSelectedUf("");
          setIsSubmitting(false);
          toast.success("Cliente cadastrado ");
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  };

  return (
    <>
      {isSubmiting ? (
        <div class="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h5>Preencha os campos para cadastrar um novo cliente</h5>
          <br />
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Data de entrada">
                <Form.Control
                  type="date"
                  id="data_entrada"
                  name="data_entrada"
                  {...register("data_entrada")}
                  placeholder="Insira a data de entrada"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <FloatingLabel label="Nome do cliente ou razão social">
                <Form.Control
                  type="text"
                  id="inputNome"
                  name="cliente"
                  {...register("cliente")}
                  placeholder="Insira o nome do cliente"
                  required
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <FloatingLabel label="CPF ou CNPJ do cliente">
                <InputMask
                  mask={
                    cpf.length < 15 ? "999.999.999-999" : "99.999.999/0001-99"
                  }
                  maskChar=""
                  className="form-control"
                  name="documento"
                  {...register("documento", {
                    onChange: (e) => handleDocumentCpf(e.target.value),
                  })}
                  placeholder="Insira o cpf do cliente"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <SelectEmployeesByRoles
              role={2}
              register={register}
              label={"Advogado"}
              option={"Selecione o advogado..."}
            />
          </div>
          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel label="Telefone">
                <InputMask
                  mask="(99) 99999-9999"
                  className="form-control"
                  name="telefone_celular"
                  {...register("telefone_celular")}
                  placeholder="Insira um telefone com DDD"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel label="E-mail">
                <Form.Control
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  {...register("email")}
                  placeholder="Insira um e-mail válido"
                />
              </FloatingLabel>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <FloatingLabel label="Endereço">
                <Form.Control
                  type="text"
                  className="form-control"
                  id="endereco"
                  name="endereco"
                  {...register("endereco")}
                  placeholder="Insira seu endereço"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <SelectState onChange={setSelectedUf} register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <SelectCity uf={selectedUf} register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Form.Control
                as="textarea"
                name="client_observation"
                {...register("client_observation")}
                placeholder="Observações gerais"
                style={{ height: "100px" }}
              />
            </div>
          </div>

          <br />
          <div className="d-flex align-items-center justify-content-center col-12 col-sm-12 col-md-5 col-lg-3">
            <Button
              type="submit"
              className="shadow-sm fs-6 rounded-2 fw-normal text-dark borderColor"
              style={{ backgroundColor: "#DAA520" }}
              id="btnRegisterClient"
            >
              <i className="bi bi-people"></i>&nbsp; Cadastrar cliente
            </Button>
          </div>
        </Form>
      )}
    </>
  );
}
