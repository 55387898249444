import React, { useEffect, useState } from "react";

import "animate.css";
import "../Presentation/styles.css";
import "./styles.css";

import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

function ResetPassword() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    const payload = {
      ...values,
      token: token,
    };

    if (payload.password !== payload.password_confirmation) {
      toast.error("Senha e Repetir senha contém valores diferentes.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }

    setLoading(true);
    axiosAPI
      .post("/auth/new-password", payload)
      .then((data) => {
        if (data.status === 200) {
          toast.success("Senha resetada com sucesso. Faça login novamente.", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
          navigate("/login");
        }
      })
      .catch((error) => {
        const message = error.data.message;
        let errorMessage = message;

        if (!errorMessage) {
          errorMessage =
            "Ops, ocorreu um erro na solicitação. Tente novamente.";
        }

        if (message.includes("user")) {
          errorMessage =
            "Usuário não encontrado. Digite um e-mail já cadastrado anteriormente.";
        } else if (message.includes("token")) {
          errorMessage =
            "Token inválido ou expirado. Faça novamente a solicitação de resetar senha.";
          navigate("/esqueci-minha-senha");
        }

        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setValue("email", searchParams.get("email"));
  }, []);

  return (
    <>
      <Navbar />

      <section className="section-body">
        <div className="container-reset-password">
          <div
            className="d-flex flex-wrap justify-content-center p-2 mt-5 w-100 shadow"
            id="boxLogin"
          >
            <div className="container ">
              <p className="fs-5 fw-bold text-black text-center p-3">
                Resetar minha senha
              </p>

              <p className="text-black">
                Por favor, insira no campo abaixo sua nova senha.
                <br />
                <br /> <strong>Recomendação: </strong>
                Insira uma senha contendo 6 ou mais caracteres, pelo menos 1
                letra maiúsculas e 1 ou mais números e/ou caracteres especiais.
              </p>

              <div className="content-form-forgot-password">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FloatingLabel label="E-mail" className="mb-3">
                    <Form.Control
                      type="email"
                      {...register("email")}
                      disabled={true}
                      id="email"
                      defaultValue={searchParams.get("email")}
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Senha" className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder="Senha"
                      id="password"
                      {...register("password")}
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Repetir senha" className="mb-3">
                    <Form.Control
                      type="password"
                      id="password_confirmation"
                      placeholder="Repetir senha"
                      {...register("password_confirmation")}
                      required
                    />
                  </FloatingLabel>

                  {loading ? (
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 mt-5 rounded-2 w-100 fw-normal"
                      style={{ backgroundColor: "#DAA520" }}
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="text-white"
                      />
                      <span className="text-white">&nbsp;Enviando...</span>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 mt-5 rounded-2 w-100 fw-normal text-black borderColor"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      <i className="bi bi-check-circle"></i>&nbsp; Resetar senha
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </>
  );
}

export default ResetPassword;
