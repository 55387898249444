import { Card, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import { useUserFiles } from "../../hooks/useUserFiles";
import UserFileListItem from "../../components/ListItems/UserFileListItem";

export default function UserFilesList({ category, lawsuit_id, support_case_id }) {
  const { files, isLoading, isFetching } = useUserFiles(null, {
    category: category,
    lawsuit_id: lawsuit_id,
    support_case_id: support_case_id,
  });

  return (
    <Card>
      <ListGroup variant="flush">
        {isFetching ? (
          <div class="d-flex justify-content-center m-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          files?.map((file) => (
            <>
              <UserFileListItem file={file} />
            </>
          ))
        )}
      </ListGroup>
    </Card>
  );
}
