import { useEffect, useState } from "react";
import axiosAPI from "../axiosAPI";

export const useCheckUserEntitiesPermissions = (userId) => {
    const [entitiesPermissions, setEntitiesPermissions] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState();

    function handleResponse(data, loading, error){
        setEntitiesPermissions(data);
        setLoading(loading);
        setError(error);
    }

    useEffect(() => {
        axiosAPI.get(`user/permissions/entities/${userId}`)
        .then((response) => {
            handleResponse(response.data.permissions, false, false)
        })
    }, [userId])

    return {
        entitiesPermissions, isLoading, isError
    }
}