import React, { useState } from "react";
import { useEmployees } from "../../hooks/useEmployees";
import { Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axiosAPI from "../../axiosAPI";
import CreateUserModal from "../Modals/CreateUserModal";
import UserPermissionsModal from "../Modals/UserPermissionsModal";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

export default function EmployeesTable({
  filter,
  setEditEmployee,
  deleteEmployee,
}) {
  const { employees, isLoading, isFetching } = useEmployees(undefined, filter);
  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const [userPermissionsModalShow, setUserPermissionsModalShow] =
    useState(false);
  const [clickedUser, setClickedUser] = useState();
  const queryClient = useQueryClient();

  function deleteEmployee(employeeId) {
    axiosAPI
      .delete("/employees/" + employeeId)
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["employees", "list"] });
        toast.success("Funcionário deletado.", { position: "bottom-right" });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  }

  function createUserEmployee(employeeId, employeeName) {
    setClickedUser({ id: employeeId, name: employeeName });
    setCreateUserModalShow(true);
  }

  function employeeUserPermissions(employeeId, employeeName) {
    setClickedUser({ id: employeeId, name: employeeName });
    setUserPermissionsModalShow(true);
  }

  return (
    <>
      {isFetching ? (
        <div class="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          size="sm"
          style={{
            height: 50,
            overflow: "auto",
            fontSize: "16px",
          }}
        >
          <thead>
            <tr>
              <th>Criado em</th>
              <th>Nome</th>
              <th>Função</th>
              {/* <th>OAB</th>
                    <th>Nacionalidade</th>
                    <th>Estado Civil</th>
                    <th>Data de Nascimento</th>
                    <th>Endereço</th>
                    <th>Cidade</th>
                    <th>Estado</th> */}
              <th>Telefone celular</th>
              <th>Editar</th>
              <th>Gerenciar Usuário</th>
              <th>Deletar</th>
            </tr>
          </thead>
          <tbody>
            {employees?.map((employee) => (
              <tr>
                <td>{format(parseISO(employee.created_at), "dd/MM/yyyy")}</td>
                <td>{employee.name}</td>
                <td>{employee.employee_type}</td>
                {/* <td>{employee.oab}</td>
                            <td>{employee.nationality}</td>
                            <td>{employee.marital_status}</td>
                            <td>{employee.birth_date}</td>
                            <td>{employee.address}</td>
                            <td>{employee.city}</td>
                            <td>{employee.state}</td> */}
                <td>{employee.number}</td>
                <td>
                  <button onClick={() => setEditEmployee(employee)}>
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </td>
                {employee.employee_user_id == null ? (
                  <td>
                    <CreateUserModal
                      id={employee.employee_id}
                      name={employee.name}
                    />
                  </td>
                ) : (
                  <td>
                    <UserPermissionsModal
                      id={employee.employee_user_id}
                      name={employee.name}
                    />
                  </td>
                )}
                <td>
                  <button onClick={() => deleteEmployee(employee.employee_id)}>
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}
