
import { useEffect, useState } from "react";
import axiosAPI from "../axiosAPI";

export const useFavoriteLawsuits = ( ) => {
    const [favoriteLawsuits, setFavoriteLawsuits] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState();

    function handleResponse(data, loading, error){
        setFavoriteLawsuits(data);
        setLoading(loading);
        setError(error);
    }

    useEffect(() => {
        axiosAPI.get('/favorite/lawsuits')
        .then((response) => handleResponse(response.data.lawsuits, false, false))
    }, [])

    return {
        favoriteLawsuits, isLoading, isError
    };
}