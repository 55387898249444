import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Spinner, Button, Form, FloatingLabel } from "react-bootstrap";
import InputMask from "react-input-mask";
import axios from "axios";
import IntlCurrencyInput from "react-intl-currency-input";
import { toast } from "react-toastify";

import axiosAPI from "../../axiosAPI";

import AuthContext from "../../contexts/AuthContext/AuthContext";

import { SelectClients } from "../../components/Selects/SelectClients";
import SelectLawsuitClientPositions from "../../components/Selects/SelectLawsuitClientPositions";
import SelectLawsuitStatuses from "../../components/Selects/SelectLawsuitStatuses";
import SelectLawsuitTypes from "../../components/Selects/SelectLawsuitTypes";
import SelectLawsuitRites from "../../components/Selects/SelectLawsuitRites";

export default function FormCadastrarProcesso(props) {
  const { user, triggerModalUpgradePlan, updateLawsuitsRegistered } =
    useContext(AuthContext);
  const [isSubmiting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, reset, control } = useForm();

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const onSubmit = (values) => {
    if (!disabledActionSubmit()) {
      setIsSubmitting(true);

      axiosAPI
        .post("/lawsuits", values)
        .then((result) => {
          if (result.status == 200) {
            updateLawsuitsRegistered(1);
            reset();
            setIsSubmitting(false);
            toast.success("Processo cadastrado");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error.data?.message) {
            toast.error(error.data.message);
          } else {
            toast.error(
              "Ops, ocorreu um erro. Tente novamente ou entre em contato com o administrador do sistema."
            );
          }
        });
    } else {
      triggerModalUpgradePlan(true);
    }
  };

  const disabledActionSubmit = () => {
    if (user) {
      if (user.lawsuits_registered === null) {
        return false;
      }

      if (user.total_lawsuits_registered >= user.lawsuits_registered) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {isSubmiting ? (
        <div className="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <SelectClients register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel
                controlId="floatingInput"
                label="Número do processo"
              >
                <InputMask
                  mask="9999999-99.9999.9.99.9999"
                  className="form-control"
                  name="lawsuit_number"
                  {...register("lawsuit_number")}
                  placeholder="Insira o número do processo"
                  required
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Título">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="lawsuit_title"
                  {...register("lawsuit_title")}
                  placeholder="Insira um título descritivo para o processo"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Palavra chave">
                <InputMask
                  className="form-control"
                  name="lawsuit_keyword"
                  {...register("lawsuit_keyword")}
                  placeholder="Insira a palavra chave"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className=" d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Parte Contrária">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="lawsuit_author"
                  {...register("lawsuit_author")}
                  placeholder="Insira o nome da parte contrária"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Data ingresso">
                <Form.Control
                  type="date"
                  className="form-control"
                  name="lawsuit_creation_date"
                  {...register("lawsuit_creation_date")}
                  placeholder="Insira a data ingresso"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Fórum">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="forum"
                  placeholder="Insira o fórum"
                  {...register("forum")}
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Comarca">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="district_court"
                  placeholder="Insira a comarca"
                  {...register("district_court")}
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitClientPositions register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitStatuses register={register} />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitTypes register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitRites register={register} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <FloatingLabel
              controlId="floatingInput"
              label="Valor arbitrado a causa"
            >
              <Controller
                control={control}
                name="lawsuit_budget"
                render={({ field }) => (
                  <IntlCurrencyInput
                    {...field}
                    onChange={(e, value) => field.onChange(value)}
                    className="form-control"
                    currency="BRL"
                    config={currencyConfig}
                  />
                )}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex align-items-center justify-content-center col-12 col-sm-12 col-md-5 col-lg-3 mt-2 mb-5">
            <Button
              type="submit"
              className={
                "d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor btn-bg-primary" +
                (disabledActionSubmit() ? " custom-disabled" : "")
              }
            >
              <i className="bi bi-hdd-network"></i>&nbsp; Cadastrar processo
            </Button>
          </div>
        </Form>
      )}
    </>
  );
}
