import { Container } from "react-bootstrap";
import BaseLayout from "../../baseLayout";
import NotificationsList from "./Components/NotificationsList";

function Notifications() {
  return (
    <BaseLayout title={"Notificações"} page={true}>
      <Container>
        <NotificationsList />
      </Container>
    </BaseLayout>
  );
}

export default Notifications;
