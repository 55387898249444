import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";

import LawsuitsTable from "../../../components/Tables/LawsuitsTable";
import LawsuitEditForm from "../../../components/EditForms/LawsuitEditForm";
import { SelectClients } from "../../../components/Selects/SelectClients";
import { SelectOppositeParty } from "../../../components/Selects/SelectOppositeParty";
import SelectLawsuitClientPositions from "../../../components/Selects/SelectLawsuitClientPositions";
import SelectLawsuitStatuses from "../../../components/Selects/SelectLawsuitStatuses";
import SelectLawsuitTypes from "../../../components/Selects/SelectLawsuitTypes";
import SelectLawsuitRites from "../../../components/Selects/SelectLawsuitRites";

export default function FormConsultarProcessos(props) {
  const [modalShow1, setModalShow1] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState();
  const [editLawsuit, setEditLawsuit] = useState(0);
  const { register, handleSubmit, reset } = useForm();

  const onSubmitSearchLawsuit = (values) => {
    setSearchFilter(values);
  };

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow1(true)}
      >
        <i className="bi bi-funnel"></i>&nbsp; Consultar processos
      </Button>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i className="bi bi-funnel"></i>&nbsp; Consultar processos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#edebe9" }}>
          <p className="fs-5 fw-bold">Consultar</p>
          <Form onSubmit={handleSubmit(onSubmitSearchLawsuit)}>
            <div className="d-flex flex-wrap">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <Form.Group className="mb-3">
                  <FloatingLabel label="Numero do processo">
                    <InputMask
                      mask="9999999-99.9999.9.99.9999"
                      className="form-control"
                      name="lawsuit_number"
                      {...register("lawsuit_number")}
                      placeholder="Insira o número do processo"
                    />
                  </FloatingLabel>
                </Form.Group>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <SelectClients register={register} />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <SelectOppositeParty register={register} />
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-10">
                <FloatingLabel controlId="floatingInput" label="Título">
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="lawsuit_title"
                    {...register("lawsuit_title")}
                    placeholder="Insira um título descritivo para o processo"
                  />
                </FloatingLabel>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectLawsuitClientPositions register={register} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectLawsuitStatuses register={register} />
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectLawsuitTypes register={register} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectLawsuitRites register={register} />
                </div>
              </div>
            </div>
            <div className="w-100">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                  <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                      type="submit"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      <i className="bi bi-funnel"></i>&nbsp; Consultar Processos
                    </Button>
                  </div>
                  <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                    {searchFilter ? (
                      <Button
                        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                        type="button"
                        onClick={() => {
                          setSearchFilter();
                          reset();
                        }}
                        style={{ backgroundColor: "#DAA520" }}
                      >
                        <i className="bi bi-funnel"></i>&nbsp; Limpar Filtros
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>

          {editLawsuit == 0 ? (
            <div className="w-100">
              <div className="col-12">
                <LawsuitsTable
                  setEditLawsuit={setEditLawsuit}
                  filter={searchFilter}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {editLawsuit != 0 ? (
            <LawsuitEditForm
              lawsuit={editLawsuit}
              setEditLawsuit={setEditLawsuit}
              modalShow={modalShow1}
              setModalShow={setModalShow1}
            />
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
