import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Col, Container, ListGroup, Row, Spinner, Tab } from "react-bootstrap";
import BaseLayout from "../../baseLayout";
import axiosAPI from "../../axiosAPI";

import { useCommitments } from "../../hooks/useCommitments";

import { AppointmentsCalendar } from "../../components/Calendars/AppointmentsCalendar/AppointmentsCalendar";
import ModalReportCalendar from "../../components/Modals/ModalDialog";
import CriarCompromisso from "./Modals/CriarCompromisso";

import "./styles.css";

function Calendar() {
  const { commitments, isFetching: isFetchingCommitments } = useCommitments();
  const [isViewReportsModal, setIsViewReportsModal] = useState(false);
  const [types, setTypes] = useState([]);

  // status done não aparece

  const getRelatorio = () => {
    setIsViewReportsModal(true);
  };

  const fetchAllTypesCommitments = async () => {
    await axiosAPI
      .get("/type-commitments")
      .then((res) => {
        setTypes(res.data.types);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchAllTypesCommitments();
  }, []);

  return (
    <BaseLayout title={"Calendário"} page={"calendar"}>
      <Container>
        <Row className="mb-5">
          <Col xs={12}>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#compromissos"
              mb={2}
            >
              <Row>
                <Col xs={12} lg={3}>
                  <ListGroup>
                    <ListGroup.Item
                      variant="secondary"
                      action
                      href="#compromissos"
                    >
                      Compromissos
                    </ListGroup.Item>
                    <ListGroup.Item
                      variant="secondary"
                      action
                      onClick={getRelatorio}
                    >
                      Emitir relatório
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col xs={12} lg={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="#compromissos">
                      <Row>
                        <Col className="col-12 col-md-5">
                          <CriarCompromisso {...{ types }} />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          {isFetchingCommitments ? (
                            <div className="loader-spinner">
                              <Spinner animation="border" variant="warning" />
                            </div>
                          ) : (
                            <AppointmentsCalendar
                              events={commitments}
                              {...{ types }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>

        <ModalReportCalendar
          data={commitments}
          show={isViewReportsModal}
          handleClose={() => setIsViewReportsModal(!isViewReportsModal)}
          types={types}
        />
      </Container>
    </BaseLayout>
  );
}

export default Calendar;
