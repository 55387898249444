import { format, parseISO } from "date-fns";
import {
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
  Accordion,
} from "react-bootstrap";

export default function ActivityLogItemList({ activity, isFetching }) {
  const translations = {
    updated: "Atualização no Cadastro",
    created: "Cadastrado realizado",
    author: "Autor",
    title: "Título",
  };

  function listProperties(current) {
    return (
      <ListGroup>
        {Object.keys(current).map((item) => {
          if (item === "updated_at") {
            return null;
          }
          if (item == "budget") {
            return (
              <ListGroup.Item key={item.id}>
                Valor: R$ {Intl.NumberFormat("pt-BR").format(current[item])}
              </ListGroup.Item>
            );
          }
          if (item.includes("id")) {
            return null;
          }
          return (
            <ListGroup.Item key={item.id}>
              {translations[item] ?? item}: {current[item]}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }

  return (
    <ListGroup>
      {isFetching ? (
        <div class="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <>
          {activity.map((log) => (
            <ListGroup.Item
              variant={log.event == "created" ? "success" : "secondary"}
              key={log.id}
            >
              <Container>
                <Row className="mt-3">
                  <Col>
                    {translations[log.event]}
                    <br />
                    Por: {log.name} {!log.owner_id && "(Administrator)"}
                  </Col>
                  <Col style={{ fontSize: 14, textAlign: "right" }}>
                    {format(parseISO(log.created_at), "dd/MM/yyyy HH:mm:ss")}
                  </Col>
                </Row>
                <hr />
                {log.event == "updated" && (
                  <Accordion flush>
                    <Accordion.Item eventKey={log.id + "-CURRENT"}>
                      <Accordion.Header>Atual</Accordion.Header>
                      <Accordion.Body>
                        {listProperties(log.properties.attributes)}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={log.id + "-OLD"}>
                      <Accordion.Header>Antigo</Accordion.Header>
                      <Accordion.Body>
                        {listProperties(log.properties.old)}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </Container>
            </ListGroup.Item>
          ))}
        </>
      )}
    </ListGroup>
  );
}
