import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import "./styles.css";

import axiosAPI from "../../axiosAPI";

import AuthContext from "../../contexts/AuthContext/AuthContext";
import FirstLoadContext from "../../contexts/ModalTools/ModalTools";
import NotificationsContext from "../../contexts/Notifications/NotificationsContext";

import { useCheckAuthorizedPages } from "../../hooks/useCheckAuthorizedPages";

//import SidebarRight from "../SidebarRight";
import ModalDeleteAccount from "../../components/Menu/ModalDeleteAccount";
import ModalFirstLoadPresentation from "../../components/Modals/ModalFirstLoadPresentation";
import ModalUpgradePlan from "../../components/Modals/ModalUpgradePlan";
import IconCoroa from "../../assets/coroa.png";

function DropLogin() {
  const navigate = useNavigate();
  const { modalUpgradePlan, handleLogout, getUser, triggerModalUpgradePlan } =
    useContext(AuthContext);
  const { notifications } = useContext(NotificationsContext);
  const { authorizedPages, isLoading } = useCheckAuthorizedPages();
  const { isFirstLoad, updateFirstLoad } = useContext(FirstLoadContext);
  const [viewModalDeleteAccount, setViewModalDeleteAccount] = useState(false);
  const [visibleSideBarRight, tVisibleSideBarRight] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [permissions, setPermissions] = useState({
    timesheet: false,
    chat: false,
    calcjuris: false,
    quantiko: false,
  });

  const handleLogoutClick = () => {
    handleLogout();
  };

  useEffect(() => {
    getUser();
    axiosAPI(`/user/permissions/entities/${user.id}`).then(({ data }) => {
      setPermissions(data.permissions);
      setLoadingPermissions(false);
    });
  }, [user]);

  //TODO LINK CORRECT TO ACTION DELETE ACCOUNT
  const handleDeleteAccount = () => {
    setViewModalDeleteAccount(false);
    axiosAPI
      .delete(`/user/softdelete/${user.id}`)
      .then(({ data }) => {
        toast.info(
          "Sua solicitação de exclusão de conta foi solicitada com sucesso."
        );
        navigate("/login");
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  };

  const showModalTools = () => {
    if (loadingPermissions) {
      return false;
    }

    if (isFirstLoad) {
      return true;
    }

    return false;
  };

  const returnCountNotifications = () => {
    if (notifications) {
      if (notifications.commitments) {
        return notifications.commitments.filter((n) => n.already_read == 0)
          .length;
      }
    }

    return 0;
  };

  return (
    <>
      <ModalFirstLoadPresentation
        show={() => showModalTools()}
        update={() => updateFirstLoad()}
        permissions={permissions}
      />

      <ModalUpgradePlan
        show={modalUpgradePlan}
        update={() => triggerModalUpgradePlan()}
      />

      <div className="menuActions">
        {/* Content */}
        <a className="navbar-brand dashboard" href="/">
          <p className="easy-title mb-0">
            easy<span className="vog"> VOG</span>
          </p>
        </a>
        <div className="menuActions__separator separatorLogo"></div>

        {/* Content */}
        <a
          href="https://api.whatsapp.com/send?l=pt_br&amp;phone=+5551992542818&amp;text=Gostaria de informações sobre o EASYVOG!"
          target="_blank"
        >
          <button class="wh-ap-btn"></button>
        </a>
        <div className="menuActions__separator"></div>

        {/* Content */}
        <i className="bi bi-person"></i>
        <div className="menuAction__content">
          <span className="menuAction__text nav-bar-dashboard-name-user fw-normal">
            Olá {JSON.parse(localStorage.getItem("user")).name}
          </span>
        </div>
        <div className="menuActions__separator"></div>

        {/* Content */}
        <Link to="/notificacoes">
          <div className="menuAction__content" style={{ marginLeft: "0px" }}>
            <span className="menuAction__text nav-bar-dashboard-name-user fw-normal">
              <i className="bi bi-bell"></i>
              {returnCountNotifications() > 0 ? (
                <span className="badge-notification badge">
                  {returnCountNotifications()}
                </span>
              ) : null}
            </span>
          </div>
        </Link>
        <div className="menuActions__separator"></div>

        {/* Content */}
        <a href="" className="menuAction" onClick={handleLogoutClick}>
          <div className="menuAction__content">
            <i className="bi bi-box-arrow-right"></i>
            &nbsp;&nbsp;<span className="d-none d-sm-inline">Sair</span>
          </div>
        </a>
        <div className="menuActions__separator"></div>

        {/* Content */}
        {/* <SidebarRight /> */}
        <div className="sidebar-right">
          {/* Icon */}
          <div
            className={
              visibleSideBarRight
                ? "menu-hamburguer checked"
                : "menu-hamburguer"
            }
            onClick={() => {
              tVisibleSideBarRight((v) => !v);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          {/* Menu */}
          <div
            className={
              visibleSideBarRight
                ? "sidebar-right menu open"
                : "sidebar-right menu hide"
            }
          >
            {isLoading ? (
              <div className="loader-spinner" style={{ minWidth: "170px" }}>
                <Spinner animation="border" variant="warning" />
              </div>
            ) : (
              <>
                <ul className="sidebar-right list-items">
                  <Link to="/dashboard">
                    <li>
                      <span>Dashboard &nbsp;&nbsp;</span>
                      <i className="bi bi-house"></i>
                    </li>
                  </Link>

                  {authorizedPages?.client ? (
                    <Link to="/cadastro-cliente">
                      <li>
                        <span>Clientes &nbsp;&nbsp;</span>
                        <i className="bi bi-person-plus"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.support_case ? (
                    <Link to="/atendimento">
                      <li>
                        <span>Atendimento &nbsp;&nbsp;</span>
                        <i className="bi bi-headset"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.lawsuit ? (
                    <Link to="/processos">
                      <li>
                        <span>Processos &nbsp;&nbsp;</span>
                        <i className="bi bi-info-circle"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.employee ? (
                    <Link to="/equipe">
                      <li>
                        <span>Equipe &nbsp;&nbsp;</span>
                        <i className="bi bi-people"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.calendar ? (
                    <Link to="/calendario">
                      <li>
                        <span>Calendário &nbsp;&nbsp;</span>
                        <i className="bi bi-calendar-event"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {/* <Link to="/financeiro">
                    <li>
                      <span>Financeiro &nbsp;&nbsp;</span>
                      <i className="bi bi-graph-up-arrow"></i>
                    </li>
                  </Link> */}

                  {/* <Link to="/encaminhamento">
                    <li>
                      <span>Encaminhar &nbsp;&nbsp;</span>
                      <i className="bi bi-envelope"></i>
                    </li>
                  </Link> */}

                  {authorizedPages?.file ? (
                    <Link to="/arquivos">
                      <li>
                        <span>Arquivos &nbsp;&nbsp;</span>
                        <i className="bi bi-folder"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.favorite ? (
                    <Link to="/favoritos">
                      <li>
                        <span>Favoritos &nbsp;&nbsp;</span>
                        <i className="bi bi-star"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.timesheet && permissions.timesheet ? (
                    <Link to="/timesheet">
                      <li>
                        <span>Timesheet &nbsp;&nbsp;</span>
                        <i className="bi bi-file-spreadsheet"></i>
                      </li>
                    </Link>
                  ) : null}

                  {authorizedPages?.chat && permissions.chat ? (
                    <Link to="/chat">
                      <li>
                        <span>Chat &nbsp;&nbsp;</span>
                        <i className="bi bi-chat-left-dots"></i>
                      </li>
                    </Link>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.calcjuris && permissions.calcjuris ? (
                    <a
                      href="https://www.softwaresadvog.com.br/calcjuris/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>
                        <span>CalcJuris &nbsp;&nbsp;</span>
                        <img
                          src={IconCoroa}
                          alt="icon premium"
                          id="icon-premium"
                          width="18"
                        />
                      </li>
                    </a>
                  ) : (
                    ""
                  )}

                  {authorizedPages?.quantiko && permissions.quantiko ? (
                    <a
                      href="https://www.softwaresadvog.com.br/quantiko/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>
                        <span>QuantiKo &nbsp;&nbsp;</span>
                        <img
                          src={IconCoroa}
                          alt="icon premium"
                          id="icon-premium"
                          width="18"
                        />
                      </li>
                    </a>
                  ) : (
                    ""
                  )}

                  {user.owner_id === null && (
                    <a
                      href="https://www.easyvogplanos.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>
                        <span>Planos &nbsp;&nbsp;</span>
                        <i className="bi bi-bag-dash"></i>
                      </li>
                    </a>
                  )}

                  <li>
                    <Button
                      onClick={() =>
                        setViewModalDeleteAccount(!viewModalDeleteAccount)
                      }
                      rel="noreferrer"
                    >
                      Excluir conta
                    </Button>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
        <ModalDeleteAccount
          show={viewModalDeleteAccount}
          handleClose={() => setViewModalDeleteAccount(!viewModalDeleteAccount)}
          handleSubmit={handleDeleteAccount}
        />
      </div>
    </>
  );
}

export default DropLogin;
