import "./styles.css";
import NavbarDashboard from "../../components/NavbarDashboard";
import Menu from "../../components/Menu";
import FormConsultarFuncionario from "./Modals/FormConsultarFuncionario";
import FormNovoFuncionario from "./FormNovoFuncionario";
import ListUsersModal from "../../components/Modals/ListUsersModal";
import AuthorizedPages from "../../authorizedPages";

function Team() {
  return (
    <>
      <NavbarDashboard />

      <Menu />

      <div className="height"></div>

      <div className="container d-flex flex-wrap justify-content-center">
        <div className="col-12 col-sm-12 col-md-10 col-lg-10">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 titlePage">
              <p className="fs-4 text-center fw-bold">Cadastro de equipe</p>
            </div>

            <AuthorizedPages page={"employee"}>
              <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                <div className="d-flex align-items-center justify-content-end col-12 col-sm-12 col-md-12 col-lg-6">
                  <FormConsultarFuncionario />
                </div>

                <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-3">
                  <ListUsersModal />
                </div>
              </div>
              <h5>Preencha os campos para cadastrar um novo funcionário</h5>
              <br />
              <FormNovoFuncionario />
            </AuthorizedPages>
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
