import { useState } from "react";
import axiosAPI from "../axiosAPI";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function useAuth() {
  let navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [modalUpgradePlan, setModalUpgradePlan] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setAuthenticated(true);
    }

    if (
      localStorage.getItem("user") &&
      localStorage.getItem("user") !== "undefined"
    ) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  async function handleLogin(form, setLoadingLogin) {
    try {
      const { data } = await axiosAPI.post("auth/login", form);

      localStorage.setItem("token", JSON.stringify(data.access_token));
      localStorage.setItem("user", JSON.stringify(data.user));

      axiosAPI.defaults.headers.Authorization = `Bearer ${data.access_token}`;
      navigate("/dashboard");
    } catch (error) {
      toast.error("Senha ou E-mail incorretos.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoadingLogin(false);
    }
  }

  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axiosAPI.defaults.headers.Authorization = undefined;
    navigate("/login");
  }

  async function getUser() {
    try {
      const { data } = await axiosAPI.get("auth/user-profile");

      localStorage.setItem("user", JSON.stringify(data));

      setUser(data);
    } catch (error) {
      console.log(error);
    }
  }

  function triggerModalUpgradePlan(action = false) {
    setModalUpgradePlan(action);
  }

  function updateUsersRegistered(users_registered) {
    const localUser = JSON.parse(localStorage.getItem("user"));

    const user = {
      ...localUser,
      total_users_registered:
        parseInt(localUser.total_users_registered) + parseInt(users_registered),
    };

    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  function updateUsersCaptured(users_captured, op) {
    const localUser = JSON.parse(localStorage.getItem("user"));

    let total_users_captured = parseInt(localUser.total_users_captured);

    if (op === "add") {
      total_users_captured += parseInt(users_captured);
    } else if (op === "remove") {
      total_users_captured -= parseInt(users_captured);
    }

    const user = {
      ...localUser,
      total_users_captured: parseInt(total_users_captured),
    };

    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  function updateLawsuitsRegistered(lawsuits_registered) {
    const localUser = JSON.parse(localStorage.getItem("user"));

    const user = {
      ...localUser,
      total_lawsuits_registered:
        parseInt(localUser.total_lawsuits_registered) +
        parseInt(lawsuits_registered),
    };

    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  function updateCloudStorage(cloud_storage) {
    const localUser = JSON.parse(localStorage.getItem("user"));

    const user = {
      ...localUser,
      total_cloud_storage: localUser.total_cloud_storage + cloud_storage,
    };

    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  return {
    authenticated,
    user,
    modalUpgradePlan,
    handleLogin,
    handleLogout,
    getUser,
    triggerModalUpgradePlan,
    updateUsersRegistered,
    updateUsersCaptured,
    updateLawsuitsRegistered,
    updateCloudStorage,
  };
}
