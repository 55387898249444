import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

function ButtonLogin() {
  return (
    <Link to="/login" id="login">
      <div className="btn-login">
        <i className="bi bi-person"></i>
        <span>Entrar</span>
      </div>
    </Link>
  );
}

export default ButtonLogin;
