import React, { useContext } from "react";

import { useUsers } from "../../hooks/useUsers";
import { Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { format, parseISO } from "date-fns";

import axiosAPI from "../../axiosAPI";

import AuthContext from "../../contexts/AuthContext/AuthContext";

import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function UsersTable() {
  const { user, triggerModalUpgradePlan, updateUsersCaptured } =
    useContext(AuthContext);
  const { users, isLoading, isError } = useUsers();

  const queryClient = useQueryClient();

  function deleteUser(userId) {
    axiosAPI
      .delete("/users/" + userId)
      .then((result) => {
        if (result.status == 200) {
          updateUsersCaptured(1, "remove");
          queryClient.invalidateQueries({ queryKey: ["users"] });
          toast.success("Usuário deletado", { position: "bottom-right" });
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function captureNotifications(user) {
    if (disabledActionSubmit() && !user.captured) {
      return triggerModalUpgradePlan(true);
    }

    axiosAPI
      .put("/user/capture-notifications/" + user.id)
      .then((result) => {
        if (result.status == 200) {
          updateUsersCaptured(1, user.captured ? "remove" : "add");
          queryClient.invalidateQueries({ queryKey: ["users"] });
          toast.success("Operação realizada", { position: "bottom-right" });
        }
      })
      .catch((error) => {
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(
            "Ops, ocorreu um erro. Tente novamente ou entre em contato com o administrador do sistema."
          );
        }
      });
  }

  const disabledActionSubmit = () => {
    if (user) {
      if (user.captured_names === null) {
        return false;
      }

      if (user.total_users_captured >= user.captured_names) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {isLoading ? (
        <div class="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Criado em</th>
              <th>Deletar</th>
              {/* <th>Capturar</th> */}
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {format(parseISO(user.created_at), "dd/MM/yyyy HH:mm:ss")}
                </td>
                <td>
                  <button onClick={() => deleteUser(user.id)}>
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
                {/* <td>
                  {user.captured ? (
                    <button onClick={() => captureNotifications(user)}>
                      <i class="bi bi-x-circle"></i>
                      &nbsp;&nbsp;remover
                    </button>
                  ) : (
                    <button onClick={() => captureNotifications(user)}>
                      <i class="bi bi-arrow-left-right"></i>
                      &nbsp;&nbsp;adicionar
                    </button>
                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}
