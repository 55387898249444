import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axiosAPI from "../../axiosAPI";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useSupportCases } from "../../hooks/useSupportCases";
import ButtonPDF from "../../components/ButtonPDF";
import ModalSupportCases from "../../components/Modals/SupportCases";

export default function SupportCasesTable({ filter, setEditForm }) {
  const { cases, isFetching } = useSupportCases(undefined, filter);
  const queryClient = useQueryClient();
  const [isViewModal, setIsViewModal] = useState(false);

  function deleteSupport(supportId) {
    axiosAPI
      .delete("/support_case/" + supportId)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["support_case", "list"] });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function limitText(text) {
    if (text) {
      if (text.length <= 30) {
        return text;
      } else {
        return text.substring(0, 30) + "...";
      }
    }
    return "";
  }

  function returnTitlePDFByFilterCases() {
    if (filter) {
      if (filter.client_id && cases) {
        if (cases.length > 0) {
          const title = cases[0].client_name;
          return title;
        }
      }
    }

    return "Todos";
  }

  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <>
          <ButtonPDF
            isFetching={isFetching}
            setFnCallback={() => setIsViewModal(true)}
          />
          <Table
            striped
            bordered
            hover
            responsive
            size="sm"
            style={{
              display: "block",
              height: 400,
              overflow: "auto",
              fontSize: "16px",
            }}
          >
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#edebe9",
                }}
              >
                <th>Cliente</th>
                <th>Titulo</th>
                <th>Descrição</th>
                <th>Observação</th>
                <th>Responsável</th>
                <th>Data criação</th>
                <th>Atualizado em</th>
                <th>Editar</th>
                <th
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#edebe9",
                  }}
                >
                  Deletar
                </th>
              </tr>
            </thead>
            <tbody>
              {cases?.map((support) => (
                <tr key={support.id}>
                  <td>{support.client_name}</td>
                  <td>
                    <Link to={`/atendimento/${support.id}`}>
                      {limitText(support?.title)}
                    </Link>
                  </td>
                  <td>{limitText(support?.description)}</td>
                  <td>{limitText(support?.obs)}</td>
                  <td>{support.employee_name}</td>

                  <td>
                    {format(parseISO(support.created_at), "dd/MM/yyyy HH:mm")}
                  </td>

                  <td>
                    {format(parseISO(support.updated_at), "dd/MM/yyyy HH:mm")}
                  </td>
                  <td>
                    <button onClick={() => setEditForm(support)}>
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  </td>
                  <td>
                    <button onClick={() => deleteSupport(support.id)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <ModalSupportCases
        show={isViewModal}
        handleClose={() => setIsViewModal(!isViewModal)}
        cases={cases}
        filter={returnTitlePDFByFilterCases()}
      />
    </>
  );
}
