import React from "react";

import "./styles.css";

import { Link } from "react-router-dom";

import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Video from "../../components/Video";

function Questions() {
  const refBtnSign = React.useRef(null);
  const refBtnProcess = React.useRef(null);

  React.useEffect(() => {
    const btnSign = refBtnSign.current;
    const btnProcess = refBtnProcess.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Adiciona a classe da animação quando o elemento estiver visível
          btnSign.classList.add(
            "animate__animated",
            "animate__fadeIn",
            "animate__fast"
          );
          btnProcess.classList.add(
            "animate__animated",
            "animate__fadeIn",
            "animate__fast"
          );
        } else {
          // Remove a classe da animação quando o elemento não estiver visível
          btnSign.classList.remove(
            "animate__animated",
            "animate__fadeIn",
            "animate__fast"
          );
          btnProcess.classList.remove(
            "animate__animated",
            "animate__fadeIn",
            "animate__fast"
          );
        }
      });
    });

    observer.observe(btnSign);
    observer.observe(btnProcess);

    // Limpa o observador quando o componente for desmontado
    return () => {
      observer.unobserve(btnSign);
      observer.unobserve(btnProcess);
    };
  }, []);

  return (
    <>
      <Navbar />

      <body>
        <Banner />
        <Video />

        <div className="section-process-acess container-fluid">
          <div className="container d-flex flex-wrap justify-content-around align-items-center">
            <div
              ref={refBtnSign}
              className="col-12 col-sm-12 col-md-4 col-lg-4 d-grid align-items-center marginTop"
            >
              <li className="btn fs-6">
                <a
                  href="https://www.easyvogplanos.com/"
                  target="_blank"
                  className="text-white"
                  rel="noreferrer"
                >
                  Assinar agora um plano
                </a>
              </li>
              <p className="fs-6 text-center text-black text-bold">
                Tenha acesso a nossas ferramentas
              </p>
            </div>

            <div
              ref={refBtnProcess}
              className="col-12 col-sm-12 col-md-4 col-lg-4 d-grid align-items-center marginTop"
            >
              <li className="btn fs-6">
                <Link to="/login" className="text-white">
                  Acesso à processos
                </Link>
              </li>
              <p className="fs-6 text-center text-black">
                Área exclusiva para assinantes
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </>
  );
}

export default Questions;
