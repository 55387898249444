import {  useEffect, useRef } from "react";


export const usePrevious = value => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        if(ref.current !== value) {
            ref.current = value;
        }
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    if(value) {
        return ref.current;
    }else{
        return undefined;
    }
  }