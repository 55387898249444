import React, { useState } from "react";
import NavbarDashboard from "../../components/NavbarDashboard";

import Menu from "../../components/Menu";

import FormConsultarProcessos from "./Modals/FormConsultarProcessos";
import FormCadastrarProcesso from "./FormCadastrarProcesso";
import BaseLayout from "../../baseLayout";

function LawSuit() {
  return (
    <BaseLayout title={"Cadastro de Processos"} page={"lawsuit"}>
      <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
        <div className="d-flex align-items-center justify-content-end col-12 col-sm-12 col-md-12 col-lg-6">
          <FormConsultarProcessos />
        </div>

        <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-3"></div>
      </div>
      <p className="fs-5 text-left">
        Preencha os campos para cadastrar um novo processo
      </p>

      <FormCadastrarProcesso />
    </BaseLayout>
  );
}

export default LawSuit;
