import {
  Document,
  PDFViewer,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Container, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { format, parseISO } from "date-fns";
import { fontSemiBold } from "../../utils/fontWeight";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    marginLeft: 0,
    marginRight: 20,
    padding: 5,
    flexGrow: 1,
  },
  headerTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  headerSubtitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
  },
  headerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  body: {
    fontSize: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    border: "1 solid #c9c9c9",
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  bodyTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  bodySubtitle: {
    width: '100%',
    fontSize: 10,
    paddingBottom: 2,
    marginBottom: 5,
  },
  itemDiv: {
    marginTop: 10,
    borderBottom: "1 solid #c9c9c9",
    textAlign: "justify",
  },
});

const Reports = ({ cases, filter }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerDiv}>
            {cases && cases?.length > 0 ? (
              <Text style={styles.headerSubtitle}>
                Atendimentos: <Text style={styles.headerTitle}>{filter}</Text>
              </Text>
            ) : null}
          </View>

          {cases.map((support_case) => {
            return (
              <View key={support_case.id} style={styles.body}>
                <View wrap={false} style={styles.table}>
                  <Text style={styles.bodySubtitle}>
                    Cliente:{" "}
                    <Text style={styles.bodyTitle}>
                      {support_case.client_name}
                    </Text>
                  </Text>

                  <Text style={styles.bodySubtitle}>
                    Título:{" "}
                    <Text style={styles.bodyTitle}>{support_case?.title}</Text>
                  </Text>

                  <Text style={styles.bodySubtitle}>
                    Descrição:{" "}
                    <Text style={styles.bodyTitle}>
                      {support_case?.description
                        ? support_case.description
                        : "Nenhuma descrição cadastrada."}
                    </Text>
                  </Text>

                  <Text style={styles.bodySubtitle}>
                    Observação:{" "}
                    <Text style={styles.bodyTitle}>
                      {support_case?.obs
                        ? support_case.obs
                        : "Nenhuma observação cadastrada."}
                    </Text>
                  </Text>

                  <Text style={styles.bodySubtitle}>
                    Responsável:{" "}
                    <Text style={styles.bodyTitle}>
                      {support_case?.employee_name}
                    </Text>
                  </Text>

                  <Text style={styles.bodySubtitle}>
                    Criado em:{" "}
                    <Text style={styles.bodyTitle}>
                      {format(
                        parseISO(support_case.created_at),
                        "dd/MM/yyyy HH:mm"
                      )}
                    </Text>
                  </Text>
                </View>
              </View>
            );
          })}

          {!cases || cases?.length === 0 ? (
            <View style={styles.itemDiv}>
              <Text style={styles.bodySubtitle}>
                Nenhum atendimento registrado
              </Text>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

const ModalSupportCases = ({ show, handleClose, cases, filter }) => {
  const returnFileNamePDF = () => {
    return `atendimentos-${new Date().getTime()}`;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="xl"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Atendimentos disponíveis para download</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          {isMobile ? (
            <PDFDownloadLink
              document={<Reports cases={cases} filter={filter} />}
              fileName={returnFileNamePDF()}
            >
              Download: {returnFileNamePDF()}.pdf
            </PDFDownloadLink>
          ) : (
            <PDFViewer width={"100%"} height={"900px"} title="Relatório">
              <Reports cases={cases} filter={filter} />
            </PDFViewer>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ModalSupportCases;
