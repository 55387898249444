import { useState } from "react";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useEmployeeTypes } from "../../hooks/useEmployeeTypes";

export default function SelectEmployeeTypes({register, selected}){
    const {employeeTypes, isLoading, isFetching} = useEmployeeTypes();
    const [selectedType, setSelectedType] = useState(selected);

    const handleEmployeeType = (value) => {
        setSelectedType(value);
    }

    if(isFetching){
        return (<></>)
    }

    return (<>
                <FloatingLabel
                    label="Função"
                >
                    <Form.Select 
                            value={selectedType}
                            class="form-select form-control" 
                            name='employee_role' 
                            {...register('employee_role', {
                                onChange: (e) => {handleEmployeeType(e.target.value)}
                                }
                            )}
                    >
                        <option value="">Selecione uma opção</option>
                        {employeeTypes?.map( employeeType => 
                                <option key={employeeType.id} value={employeeType.id}>{employeeType.title}</option> 
                                )}
                    </Form.Select>
                </FloatingLabel>
        </>)

}