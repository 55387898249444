import React, { useContext, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import { useForm } from "react-hook-form";

import axiosAPI from "../../axiosAPI";

import AuthContext from "../../contexts/AuthContext/AuthContext";

import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

export default function CreateUserModal(props) {
  const { user, triggerModalUpgradePlan, updateUsersRegistered } =
    useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const queryClient = useQueryClient();

  const onSubmitCreateUser = (values) => {
    if (!disabledActionSubmit()) {
      axiosAPI
        .post("user/employee", values)
        .then((data) => {
          if (data.status === 201) {
            updateUsersRegistered(1);
            toast.success("Usuário cadastrado.", {
              position: toast.POSITION.TOP_CENTER,
            });
            setModalShow(false);
            queryClient.invalidateQueries({
              queryKey: ["employees", "list"],
            });
          }
        })
        .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
    } else {
      triggerModalUpgradePlan(true);
    }
  };

  const disabledActionSubmit = () => {
    if (user) {
      if (user.users_registered === null) {
        return false;
      }
      
      if (user.total_users_registered >= user.users_registered) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <button onClick={() => setModalShow(true)}>
        <i class="bi bi-person-plus"></i>
      </button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xs"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i class="bi bi-person-workspace"></i>&nbsp; Criar Usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-5 fw-bold">{props.name}</p>
          <Form onSubmit={handleSubmit(onSubmitCreateUser)}>
            <Form.Group>
              <FloatingLabel label="Email">
                <Form.Control
                  className="form-control"
                  name="email"
                  {...register("email")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="Senha">
                <Form.Control
                  className="form-control"
                  name="password"
                  {...register("password")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="Confirme a Senha">
                <Form.Control
                  className="form-control"
                  name="password_confirmation"
                  {...register("password_confirmation")}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Control
              className="form-control"
              type="hidden"
              value={props.name}
              name="name"
              {...register("name")}
            />
            <Form.Control
              className="form-control"
              type="hidden"
              value={props.id}
              name="employee_id"
              {...register("employee_id")}
            />

            <div className="w-100">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                  <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor btn-bg-primary"
                      onClick={() => setModalShow(false)}
                      type="button"
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                    <Button
                      className={
                        "d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor btn-bg-primary" +
                        (disabledActionSubmit() ? " custom-disabled" : "")
                      }
                      type="submit"
                    >
                      <i className="bi bi-funnel"></i>&nbsp; Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
