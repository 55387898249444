import React, { useState } from "react";
import { Col, FloatingLabel, Form, ListGroup, Row } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import DeleteIconButton from "../IconButtons/DeleteIconButton";
import EditIconButton from "../IconButtons/EditIconButton";
import axiosAPI from "../../axiosAPI";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function ObservationListItem({ observation }) {
  const [observationEditText, setObservationEditText] = useState();
  const queryClient = useQueryClient();

  function deleteObservation(observation) {
    axiosAPI
      .delete(`observations/${observation.id}`)
      .then((data) => {
        queryClient.invalidateQueries({
          queryKey: ["observations", "list"],
        });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function editObservation(observation) {
    //invalidate query here
    axiosAPI
      .patch(`observations/${observation.id}`, {
        observation: observationEditText,
      })
      .then((data) => {
        queryClient.invalidateQueries({
          queryKey: ["observations", "list"],
        });
        toast.success("Entrada no histórico editada", {
          position: "bottom-right",
        });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  return (
    <ListGroup.Item>
      <Row>
        <Col xs={12} style={{ textAlign: "right", marginBottom: "10px" }}>
          <span style={{ fontSize: "14px" }}>
            {format(parseISO(observation.created_at), "dd/MM/yyyy HH:mm")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "justify " }}>
          {observation.observation}
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ textAlign: "right", marginTop: "10px" }}>
          <EditIconButton
            action={editObservation}
            param={observation}
            title={"Editar Entrada no Histórico"}
          >
            <FloatingLabel label="Observação">
              <Form.Control
                as="textarea"
                rows={15}
                style={{ height: "100%", textAlign: "justify " }}
                className="form-control"
                defaultValue={observation.observation}
                onChange={(e) => setObservationEditText(e.target.value)}
              />
            </FloatingLabel>
          </EditIconButton>

          <DeleteIconButton action={deleteObservation} param={observation} />
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
