import { useQuery } from '@tanstack/react-query'
import axiosAPI from '../axiosAPI';

export const useCommitments = (commitmentId, filter = false) => { 

    const query = useQuery( ["commitments"], () => 
        axiosAPI.get('/commitments')
        .then((res) => res.data)
    );

    return {commitments: query.data?.commitments, isLoading: query.isLoading, isError: query.error, isFetching: query.isFetching}

}
