import React, { useState } from "react";
import { useLawsuits } from "../../hooks/useLawsuits";
import { Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axiosAPI from "../../axiosAPI";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ButtonPDF from "../../components/ButtonPDF";
import ModalLawsuits from "../../components/Modals/Lawsuits";

export default function LawsuitsTable({
  filter,
  setEditLawsuit,
  deleteLawsuit,
}) {
  const { lawsuits, isFetching } = useLawsuits(undefined, filter);
  const queryClient = useQueryClient();
  const [isViewModal, setIsViewModal] = useState(false);

  function deleteLawsuit(lawsuitId) {
    axiosAPI
      .delete("/lawsuits/" + lawsuitId)
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["lawsuits", "list"] });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function favoriteLawsuit(lawsuitId) {
    axiosAPI
      .patch("/lawsuits/favorite/" + lawsuitId)
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["lawsuits", "list"] });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function removeFavoriteLawsuit(lawsuitId) {
    axiosAPI
      .delete("/lawsuits/favorite/" + lawsuitId)
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["lawsuits", "list"] });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function returnTitlePDFByFilterLawsuits() {
    if (filter) {
      if (filter.client_id && lawsuits) {
        if (lawsuits.length > 0) {
          const title = lawsuits[0].client_name;
          return title;
        }
      }
    }

    return "Todos";
  }

  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <>
          <ButtonPDF
            isFetching={isFetching}
            setFnCallback={() => setIsViewModal(true)}
          />
          <Table
            striped
            bordered
            hover
            responsive
            size="sm"
            style={{
              display: "block",
              height: 400,
              overflow: "auto",
              fontSize: "16px",
            }}
          >
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#edebe9",
                }}
              >
                <th>Cliente</th>
                <th>Número</th>
                <th>Fórum</th>
                <th>Comarca</th>
                <th>Parte Contrária</th>
                <th>Posição Cliente</th>
                <th>Status</th>
                <th>Rito</th>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Editar</th>
                <th
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#edebe9",
                  }}
                >
                  Deletar
                </th>
                <th
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#edebe9",
                  }}
                >
                  Favorito
                </th>
              </tr>
            </thead>
            <tbody>
              {lawsuits?.map((lawsuit) => (
                <tr key={lawsuit.id}>
                  <td>{lawsuit.client_name}</td>
                  <td>
                    <Link to={`/processo/${lawsuit.id}`}>{lawsuit.number}</Link>
                  </td>
                  <td>{lawsuit.forum}</td>
                  <td>{lawsuit.district_court}</td>
                  <td>{lawsuit.author}</td>
                  <td>{lawsuit.position}</td>
                  <td>{lawsuit.status}</td>
                  <td>{lawsuit.rite}</td>
                  <td>{lawsuit.type}</td>
                  <td>
                    {" "}
                    R$ {Intl.NumberFormat("pt-BR").format(lawsuit.budget)}
                  </td>
                  <td>
                    <button onClick={() => setEditLawsuit(lawsuit)}>
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  </td>
                  <td>
                    <button onClick={() => deleteLawsuit(lawsuit.id)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                  {lawsuit.favorite == 0 ? (
                    <td>
                      <button onClick={() => favoriteLawsuit(lawsuit.id)}>
                        <i className="bi bi-star"></i>
                      </button>
                    </td>
                  ) : (
                    <td>
                      <button onClick={() => removeFavoriteLawsuit(lawsuit.id)}>
                        <i className="bi bi-star-fill"></i>
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <ModalLawsuits
        show={isViewModal}
        handleClose={() => setIsViewModal(!isViewModal)}
        lawsuits={lawsuits}
        filter={returnTitlePDFByFilterLawsuits()}
      />
    </>
  );
}
