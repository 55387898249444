import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";
import EmployeeEditForm from "../../../components/EditForms/EmployeeEditForm";
import EmployeesTable from "../../../components/Tables/EmployeesTable";
import SelectEmployeeTypes from "../../../components/Selects/SelectEmployeeTypes";
import { SelectState } from "../../../components/Selects/SelectState";
import { SelectCity } from "../../../components/Selects/SelectCity";

export default function FormConsultarFuncionario(props) {
  const [modalShow1, setModalShow1] = React.useState(false);
  const [selectedUf, setSelectedUf] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [editEmployee, setEditEmployee] = useState(0);
  const { register, handleSubmit, reset } = useForm();

  const onSubmitSearchEmployee = (values) => {
    setSearchFilter(values);
  };

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow1(true)}
      >
        <i class="bi bi-person-workspace"></i>&nbsp; Listar funcionários
      </Button>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i class="bi bi-person-workspace"></i>&nbsp; Buscar funcionários
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#edebe9" }}>
          <p className="fs-5 fw-bold">Consulta por nome do funcionário</p>
          <Form onSubmit={handleSubmit(onSubmitSearchEmployee)}>
            <Form.Group className="mb-3">
              <FloatingLabel label="Nome do funcionário">
                <Form.Control
                  className="form-control"
                  name="name"
                  {...register("name")}
                  placeholder="Insira o nome do funcionário"
                />
              </FloatingLabel>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <SelectEmployeeTypes register={register} />
                </div>
              </div>
              <div class="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectState onChange={setSelectedUf} register={register} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectCity uf={selectedUf} register={register} />
                </div>
              </div>
            </Form.Group>
            <div className="w-100">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                  <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                      type="submit"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      <i className="bi bi-funnel"></i>&nbsp; Consultar
                      Funcionarios
                    </Button>
                  </div>
                  <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                    {searchFilter ? (
                      <Button
                        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                        type="button"
                        onClick={() => setSearchFilter()}
                        style={{ backgroundColor: "#DAA520" }}
                      >
                        <i className="bi bi-funnel"></i>&nbsp; Limpar Filtros
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>

          {editEmployee == 0 ? (
            <div className="w-100">
              <div className="col-12">
                <EmployeesTable
                  setEditEmployee={setEditEmployee}
                  filter={searchFilter}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {editEmployee != 0 ? (
            <EmployeeEditForm
              employee={editEmployee}
              setEditEmployee={setEditEmployee}
            />
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
