import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useUsers } from "../../hooks/useUsers";

export default function SelectEmployeesUsers({ register, selected, label = null }) {
  const { users, isLoading, isFetching } = useUsers();
  const [selectedUser, setSelectedUser] = useState(selected);

  const handleEmployeeUser = (value) => {
    setSelectedUser(value);
  };

  if (isFetching) {
    return (
      <FloatingLabel label={label ?? "Funcionários"}>
        <Form.Select className="form-select form-control" name="employee">
          <option value="">Carregando...</option>
        </Form.Select>
      </FloatingLabel>
    );
  }

  return (
    <>
      <FloatingLabel label={label ?? "Funcionários"}>
        <Form.Select
          value={selectedUser}
          className="form-select form-control"
          name="user_id"
          {...register("user_id", {
            onChange: (e) => {
              handleEmployeeUser(e.target.value);
            },
          })}
        >
          <option value="">Selecione uma opção</option>
          {users?.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}
