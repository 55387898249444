export const commons = [
  {
    id: 1,
    title: "O QUE É O EASYVOG",
    body: "EASYVOG é um sistema jurídico de gerenciamentos de processos!",
  },
  {
    id: 2,
    title: "COMO ME CADASTRO NO EASYVOG",
    body: "Clique no canto superior esquerdo em “entrar” e será direcionado para a página de cadastro, á direita na pergunta: AINDA NÃO É CADASTRADO? Coloque seu nome, e-mail e senha repetida de confirmação! Aceite a politica de privacidade e se cadastre!",
  },
  {
    id: 3,
    title: "COMO É O PLANO GRATUÍTO",
    body: "No primeiro cadastro o usuário será direcionado ao plano gratuito, onde as funcionalidades serão básicas. ",
  },
  {
    id: 4,
    title: "COMO É O PLANO PAGO",
    body: "Ao se logar no EASYVOG, bem no seu nome de identificação você clica e vai para a página de contratação dos planos.",
  },
  {
    id: 5,
    title: "QUAIS OS PRIMEIROS PASSOS DE UTILIZAÇÃO DO EASYVOG",
    body: "1º Passo: Aba EQUIPES você cadastra todos os seus funcionários que poderão entrar no sistema. Preencha os campos para cadastrar seus funcionários.  Após clique em “listar funcionários”, onde aparecerão em lista todos os funcionários cadastrados. A seguir em Gerenciar Usuário cadastre seus e-mails e senhas para que tenham acesso individual no sistema.  Após no mesmo campo Gerenciar Usuário, informe quais abas cada funcionário poderá ter acesso na sua conta.<br><br>2º passo: Aba CLIENTES: Cadastre todos seus clientes! Informe qual advogado será encarregado de cada cliente.<br><br>3º Passo: Aba PROCESSOS: Cadastre todos seus processos. Informe quias destes são seus “favoritos” clicando na “estrela” á direita. Estes processos poderão serem vistos na aba FATORITOS.<br>",
  },
  {
    id: 6,
    title: "COMO CONSULTAR ANDAMENTOS DE PROCESSOS",
    body: "Na aba PROCESSOS clique em “consultar processos”, de imediato aparecerá a relação de sues processos. Temos a opção de busca por número, nome e outros atributos do processo.Veja que o número do processo tem cor azul. Clique no número do processo e aparecerá um LOG de atividades, que mostra quem fez alterações no cadastro do processo, por data e hora. Temos ARQUIVOS onde serão arquivados documentos relacionados com os processos. Temos o HISTÓRICO onde serão relatados andamentos de processos e outras informações necessárias",
  },
  {
    id: 7,
    title: "COMO CADASTRAR COMPROMISSOS ",
    body: "Na aba CALENDÁRIO temos como “criar compromissos” os quais aparecerão sempre no sino de ALERTA ao lado do nome do usuário.",
  },
  /*{
    id: 8,
    title: 'FINANCEIRO COMO FUNCIONA',
    body: 'Temos a rotina dos CRÉDITOS e DÉBITOS. O usuário cadastra seus RECEITAS e DESPESAS como também o CENTRO DE CUSTO e cadastra suas CONTAS BANCÁRIAS, onde haverá o fluxo financeiro do escritório. Há a possibilidade de relatórios, tanto de RECEITAS como de DESPESAS em determinados períodos como também o SALDO (receita – despesa).'
  },*/
  {
    id: 9,
    title: "TIMESHEET – O QUE É E COMO FUNCIONA",
    body: "Temos o TIMESHEET onde o usuário irá ver os valores a serem cobrados dos clientes no final do mês pela carga horária trabalhada para cada cliente. O usuário estipula um valor hora inicialmente, após os compromissos para cada cliente serão lançados para verificação do valor final a ser cobrado.",
  },
  {
    id: 10,
    title: "CHAT – COMUNICAÇÃO INTERNA",
    body: "Temos o CHAT interno, onde os funcionários trocam entre si mensagens. Importante que as mensagens não podem ser apagadas.",
  },
];