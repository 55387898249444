import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";

export default function TextBoxObservations({ entity, id }) {
  const [observationText, setObservationText] = useState("");
  const queryClient = useQueryClient();

  function submitObservation() {
    axiosAPI
      .post("/observations", {
        observation: observationText,
        entity: entity,
        entity_id: id,
      })
      .then((result) => {
        if (result.status == 200) {
          queryClient.invalidateQueries({ queryKey: ["observations", "list"] });
          toast.success("Observação adicionada.", { position: "bottom-right" });
          setObservationText("");
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  }

  return (
    <>
      <div
        style={{
          padding: 2,
          border: "1px solid rgba(0, 0, 0, 0.175)",
          borderRadius: "5px",
          marginTop: "10px"
        }}
      >
        <FloatingLabel controlId="floatingInput" label="Entrada no Histórico">
          <Form.Control
            as="textarea"
            style={{ height: "100px" }}
            value={observationText}
            className="form-control"
            name="timesheet_description"
            onChange={(e) => setObservationText(e.target.value)}
          />
        </FloatingLabel>
      </div>
      <Button
        onClick={() => submitObservation()}
        className="shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
      >
        Enviar
      </Button>
    </>
  );
}
