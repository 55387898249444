import React from "react";
import { useCheckAuthorizedPages } from "./hooks/useCheckAuthorizedPages";

import { Spinner } from "react-bootstrap";

const AuthorizedPages = ({ page, children }) => {
  const { authorizedPages, isFetching } = useCheckAuthorizedPages();

  if (authorizedPages && !isFetching) {
    if (page in authorizedPages) {
      return children;
    }

    if (page === true) {
      return children;
    }

    return <h6>Permissão negada.</h6>;
  }

  return (
    <div class="d-flex justify-content-center m-5">
      <Spinner animation="border" variant="warning" />
    </div>
  );
};

export default AuthorizedPages;
