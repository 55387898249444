import { useQuery } from '@tanstack/react-query'
import axiosAPI from '../axiosAPI';

export const useLawsuitRites = ( ) => {

    const query = useQuery(['LawsuitRites'], () => 
        axiosAPI.get('/lawsuit_rites')
        .then((res) => res.data)
    );

    return {
        lawsuitRites: query.data?.lawsuit_rites,
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}