export const testFullNameRegex = (name) => {
    const nameSplited = name.split(' ');

    if (!nameSplited || name.length <= 1) {
        return false;
    }

    if (!nameSplited[1]) {
        return false;
    }

    return true;
};