import { Badge, Row } from "react-bootstrap";
import { useChatMessages } from "../../../hooks/useChatMessages";
import { usePrevious } from "../../../hooks/usePrevious";

export default function ConversationsListItem({ user }) {
  const {
    messages,
    isLoading: isLoadingChatMessages,
    isFetching: isFetchingChatMessages,
  } = useChatMessages({ chatId: user.id, filter: { last: true } });

  const prevMessageId = usePrevious(messages?.id);

  return (
    <>
      {prevMessageId != undefined && prevMessageId != messages?.id && (
        <Badge bg="success">novo</Badge>
      )}

      <p
        style={{
          fontWeight: 500
        }}
      >
        {user.name}
      </p>

      {messages?.message ? (
        <p
          className="small text-left m-0"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#4f4f4f",
          }}
        >
          <i className="bi bi-chat-left-dots"></i>&nbsp;&nbsp;
          {messages.message}
        </p>
      ) : null}
    </>
  );
}
