import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useLawsuitTypes } from "../../hooks/useLawsuitTypes";
import { Controller, useFormContext } from "react-hook-form";

export default function SelectLawsuitTypes({ register, selected }) {
  const { lawsuitTypes, isLoading, isError } = useLawsuitTypes();
  const methods = useFormContext();

  // if selected then its controlled
  if (selected) {
    return (
      <>
        {isLoading ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Tipo Processo">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_type"
                {...methods?.register("lawsuit_type")}
                aria-label="Tipo Processo"
                required
              >
                <option>Carregando... </option>
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : methods ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Tipo Processo">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_type"
                aria-label="Tipo Processo"
                {...methods.register("lawsuit_type")}
                defaultValue={selected}
              >
                <option value="">Selecione ...</option>
                {lawsuitTypes?.map((lawsuitTypes) => (
                  <option key={lawsuitTypes.id} value={lawsuitTypes.id}>
                    {lawsuitTypes.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : (
          ""
        )}
      </>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Tipo Processo">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_type"
                {...register("lawsuit_type")}
                aria-label="Tipo Processo"
                required
              >
                <option>Carregando... </option>
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : (
          <div>
            <FloatingLabel controlId="floatingInput" label="Tipo Processo">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_type"
                aria-label="Tipo Processo"
                {...register("lawsuit_type")}
              >
                <option value="">Selecione ...</option>
                {lawsuitTypes?.map((lawsuitTypes) => (
                  <option key={lawsuitTypes.id} value={lawsuitTypes.id}>
                    {lawsuitTypes.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </div>
        )}
      </>
    );
  }
}
