import React, { useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";

import BaseLayout from "../../baseLayout";
import ChatBox from "./Components/ChatBox";
import ConversationsList from "./Components/ConversationsList";

function Chat() {
  const [selectedChat, setSelectedChat] = useState(false);
  
  return (
    <BaseLayout title={"Chat"} page={"chat"}>
      <Container>
        <Row>
          <Col xs={12} lg={3}>
            <ConversationsList {...{ selectedChat, setSelectedChat }} />
          </Col>

          <Col xs={12} lg={9}>
            {selectedChat ? (
              <Stack gap={3}>
                <ChatBox {...{ selectedChat }} />
              </Stack>
            ) : null}
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}

export default Chat;
