function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function convertMsToHM(seconds) {
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    
    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    
    minutes = minutes % 60;
    
    return `${padTo2Digits(hours)} horas ${padTo2Digits(minutes)} minutos`;
}

export {convertMsToHM}