import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axiosAPI from "../../axiosAPI";

import { SelectClients } from "../Selects/SelectClients";
import SelectEmployeesUsers from "../Selects/SelectEmployeesUsers";

import { DevTool } from "@hookform/devtools";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function SupportCaseForm({ supportCase, setEdit }) {
  const methods = useForm();
  const queryClient = useQueryClient();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [supportOwner, setSupportOwner] = useState(
    supportCase.employee_id == user.id ? 1 : 2
  );

  const onSubmitEdit = (values) => {
    const payload = {
      client_id: values.client_id,
      title: values.title,
      description: values.description,
      obs: values.obs,
      employee_id: supportOwner == 1 ? null : values.user_id,
    };

    axiosAPI
      .patch(`/support_case/${supportCase.id}`, payload)
      .then((result) => {
        if (result.status == 200) {
          queryClient.invalidateQueries({ queryKey: ["support_case", "list"] });
          toast.success("Atendimento editado", { position: "bottom-right" });
          setEdit(false);
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmitEdit)}>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <SelectClients
                register={methods.register}
                selected={supportCase.client_id}
                required
              />
            </div>

            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Título">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="title"
                  defaultValue={supportCase?.title}
                  {...methods.register("title")}
                  required
                />
              </FloatingLabel>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-7 mt-3">
              <Form.Control
                as="textarea"
                name="description"
                defaultValue={supportCase?.description}
                style={{ height: "150px" }}
                {...methods.register("description")}
              />
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-7 mt-3 mb-3">
              <Form.Control
                as="textarea"
                name="obs"
                defaultValue={supportCase?.obs}
                style={{ height: "100px" }}
                {...methods.register("obs")}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel
                controlId="floatingInput"
                label="Esse atendimento é:"
              >
                <Form.Select
                  value={supportOwner}
                  onChange={(e) => setSupportOwner(e.target.value)}
                  className="form-select form-control"
                  name="support_case_owner"
                >
                  <option value="1">Para Mim</option>
                  <option value="2">Para um funcionário</option>
                </Form.Select>
              </FloatingLabel>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              {supportOwner == 2 && (
                <SelectEmployeesUsers
                  register={methods.register}
                  label={"Responsável"}
                  selected={supportCase.employee_id}
                />
              )}
            </div>
          </div>
          <div className="w-100">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5">
              <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                    onClick={() => setEdit(false)}
                    type="button"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    Cancelar
                  </Button>
                </div>
                <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                    type="submit"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <i className="bi bi-funnel"></i>&nbsp; Atualizar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DevTool control={methods.control} /> {/* set up the dev tool */}
        </form>
      </FormProvider>
    </>
  );
}
