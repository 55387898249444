import NavbarDashboard from "../../components/NavbarDashboard";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import "./styles.css";
import Menu from "../../components/Menu";
import FormCadastrarCliente from "./FormCadastrarCliente";
import FormPesquisaClientes from "./Modals/FormPesquisaClientes";
import AuthorizedPages from "../../authorizedPages";

function Customers() {
  return (
    <>
      <NavbarDashboard />
      <Menu />

      <div className="height"></div>

      <div className="container w-75 d-flex flex-column justify-content-center marginBottom">
        <div className="container d-flex justify-content-center titlePage">
          <p className="fs-4 text-center fw-bold">Cadastro de clientes</p>
        </div>

        <AuthorizedPages page={"client"}>
          <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
            <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-6">
              <FormPesquisaClientes />
            </div>
            <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-3"></div>
          </div>
          <br />
          <FormCadastrarCliente />
        </AuthorizedPages>
      </div>
    </>
  );
}

export default Customers;
