import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import {
  Document,
  PDFViewer,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { format, parseISO } from "date-fns";
import { convertMsToHM } from "../../helper/timeConversion";
import { totalPay } from "../../helper/totalPay";
import { useTimesheetReport } from "../../hooks/reports/useTimesheetReport";
import { isMobile } from "react-device-detect";
import { useQueryClient } from "@tanstack/react-query";
import { fontSemiBold } from "../../utils/fontWeight";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    marginLeft: 0,
    marginRight: 20,
    padding: 5,
    flexGrow: 1,
  },
  period: {
    fontSize: 11,
  },
  tableCell: {
    padding: 3,
    fontSize: 10,
    ...fontSemiBold,
  },
  dataTableCell: {
    padding: 3,
    fontSize: 10,
  },
});

// Create Document Component
const MyDocument = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {data.filterDates.start_date || data.filterDates.end_date ? (
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.period}>
                Período: de{" "}
                <Text style={{ ...styles.period, fontWeight: "bold" }}>
                  {data.filterDates.start_date
                    ? format(
                        parseISO(data.filterDates.start_date),
                        "dd/MM/yyyy"
                      )
                    : ""}
                </Text>{" "}
                até{" "}
                <Text style={{ ...styles.period, fontWeight: "bold" }}>
                  {data.filterDates.end_date
                    ? format(parseISO(data.filterDates.end_date), "dd/MM/yyyy")
                    : ""}
                </Text>
              </Text>
            </View>
          ) : null}

          <Table data={data.header}>
            <TableHeader>
              <TableCell style={styles.tableCell}>Cliente</TableCell>
              <TableCell style={styles.tableCell}>Valor Hora</TableCell>
              <TableCell style={styles.tableCell}>Tempo Total</TableCell>
              <TableCell style={styles.tableCell}>Valor Total</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.clientName}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.value}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.totalTime}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.totalValue}
              />
            </TableBody>
          </Table>
          <View
            style={{ color: "white", textAlign: "center", margin: 20 }}
          ></View>
          <Table data={data.timesheet}>
            <TableHeader>
              <TableCell style={styles.tableCell}>Título</TableCell>
              <TableCell style={styles.tableCell}>Descrição</TableCell>
              <TableCell style={styles.tableCell}>Inicio</TableCell>
              <TableCell style={styles.tableCell}>Fim</TableCell>
              <TableCell style={styles.tableCell}>Tempo</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.title}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.description}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.event_start_formated}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) => r.event_end__formated}
              />
              <DataTableCell
                style={styles.dataTableCell}
                getContent={(r) =>
                  convertMsToHM(
                    (new Date(Date.parse(r.event_end)).getTime() -
                      new Date(Date.parse(r.event_start)).getTime()) /
                      1000
                  )
                }
              />
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  );
};

function buildData(
  timesheet,
  timesheetHourPrice,
  timesheetTotalTime,
  filterDates = null
) {
  const formatTimesheet = timesheet.map((t) => {
    return {
      ...t,
      event_start_formated: format(parseISO(t.event_start), "dd/MM/yyyy HH:mm"),
      event_end__formated: format(parseISO(t.event_end), "dd/MM/yyyy HH:mm"),
    };
  });

  const data = [];
  data.header = [
    {
      clientName: timesheet[0]?.client_name,
      value: timesheetHourPrice?.value,
      totalTime: convertMsToHM(timesheetTotalTime?.total_time),
      totalValue: Number(
        totalPay(timesheetTotalTime?.total_time, timesheetHourPrice?.value)
      ).toLocaleString("pt-br", { style: "currency", currency: "BRL" }),
    },
  ];
  data.filterDates = filterDates;
  data.timesheet = formatTimesheet;
  return data;
}

export default function TimesheetRelatorios({ clientId }) {
  const queryClient = useQueryClient();
  const { register, getValues } = useForm();
  const [modalShow, setModalShow] = React.useState(false);
  const {
    timesheetReport,
    timesheetQuery,
    timesheetHourPriceQuery,
    timesheetTotalTimeQuery,
  } = useTimesheetReport(clientId, {
    start_date: getValues("timesheet_start_date"),
    end_date: getValues("timesheet_end_date"),
  });

  const returnFileNamePDF = () => {
    return `relatorio-timesheet-${new Date().getTime()}`;
  };

  function generateBuildData() {
    queryClient.invalidateQueries({ queryKey: ["timesheet"] });
    queryClient.invalidateQueries({ queryKey: ["timesheet_hour_price"] });
    queryClient.invalidateQueries({ queryKey: ["timesheet_total_time"] });
    setModalShow(true);
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          <FloatingLabel controlId="floatingInput" label="Data Início">
            <Form.Control
              type="date"
              class="form-control"
              name="timesheet_start_date"
              {...register("timesheet_start_date")}
              placeholder="Data de Inicio"
            />
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel controlId="floatingInput" label="Data Fim">
            <Form.Control
              type="date"
              class="form-control"
              name="timesheet_end_date"
              {...register("timesheet_end_date")}
              placeholder="Data de Inicio"
            />
          </FloatingLabel>
        </div>
      </div>

      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => generateBuildData()}
      >
        <i className="bi bi-person-workspace"></i>&nbsp; Relatórios
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i className="bi bi-person-workspace"></i>&nbsp; Timesheet /
            Relatórios
          </Modal.Title>
        </Modal.Header>

        {modalShow ? (
          <Modal.Body>
            {!timesheetReport ? (
              <div className="loader-spinner">
                <Spinner animation="border" variant="warning" />
              </div>
            ) : (
              <Container>
                {isMobile ? (
                  <Row>
                    <PDFDownloadLink
                      document={
                        <MyDocument
                          data={buildData(
                            timesheetQuery?.data.timesheet,
                            timesheetHourPriceQuery?.data.price,
                            timesheetTotalTimeQuery?.data.timesheet,
                            {
                              start_date: getValues("timesheet_start_date"),
                              end_date: getValues("timesheet_end_date"),
                            }
                          )}
                        />
                      }
                      fileName={returnFileNamePDF()}
                    >
                      Download: {returnFileNamePDF()}.pdf
                    </PDFDownloadLink>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <PDFViewer width={"100%"} height={"900px"}>
                        <MyDocument
                          data={buildData(
                            timesheetQuery?.data.timesheet,
                            timesheetHourPriceQuery?.data.price,
                            timesheetTotalTimeQuery?.data.timesheet,
                            {
                              start_date: getValues("timesheet_start_date"),
                              end_date: getValues("timesheet_end_date"),
                            }
                          )}
                        />
                      </PDFViewer>
                    </Col>
                  </Row>
                )}
              </Container>
            )}
          </Modal.Body>
        ) : null}
      </Modal>
    </>
  );
}
