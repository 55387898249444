import React, { useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import axiosAPI from "../../axiosAPI";

import AuthContext from "../../contexts/AuthContext/AuthContext";

import { SelectClients } from "../Selects/SelectClients";
import { SelectEmployeesByRoles } from "../Selects/SelectEmployeesByRoles";
import { SelectLawsuits } from "../Selects/SelectLawsuits";
import { SelectSupportCases } from "../Selects/SelectSupportCase";

export default function UploadFileModal(props) {
  const { user, triggerModalUpgradePlan, updateCloudStorage } =
    useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { register, handleSubmit, reset, control } = useForm();
  const [category, setCategory] = useState("general");
  const [progress, setProgress] = useState(0);

  const queryClient = useQueryClient();

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const onSubmitUploadFile = (values) => {
    if (!disabledActionSubmit()) {
      let formData = new FormData();
      const maxAllowedSize = 10 * 1024 * 1024;

      let file = values.file[0];

      if (typeof file === "undefined") {
        setDisabledButton(false);

        toast.error("Nenhum arquivo selecionado.");
        return;
      }

      if (file.type !== "application/pdf") {
        setDisabledButton(false);

        toast.error("É permitido somente arquivo PDF.");
        return;
      }

      if (file.size > maxAllowedSize) {
        setDisabledButton(false);

        toast.error("Tamanho máximo permitido é de 10MB.");
        return;
      }

      formData.append("file", file);
      formData.append("file_category", values.file_category);
      formData.append("file_title", values.file_title);

      if (category == "lawsuit") {
        formData.append("lawsuit_id", values.lawsuit_id);
      }
      if (category == "employee") {
        formData.append("employee_id", values.client_employee_by_employee_id);
      }
      if (category == "client") {
        formData.append("client_id", values.client_id);
      }
      if (category == "support_case") {
        formData.append("support_case_id", values.support_case_id);
      }

      toast.info("Enviando arquivo...");
      setDisabledButton(true);

      axiosAPI
        .post("/files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            let p = Math.round((event.loaded * 100) / event.total);
            setProgress(p);
          },
        })
        .then((result) => {
          if (!result) {
            setDisabledButton(false);
            toast.error("Erro ao realizar upload. Tente novamente.");
            setProgress(0);
            return;
          }
          // queryClient.invalidateQueries({ queryKey: ["userFiles", "list"] });
          updateCloudStorage(file.size);
          toast.success("Arquivo enviado", { position: "bottom-right" });
          setDisabledButton(false);
          setModalShow(false);
          setProgress(0);
        })
        .catch((error) => {
          setDisabledButton(false);
          setProgress(0);

          if (error.data?.message) {
            toast.error(error.data.message);
          } else {
            toast.error(
              "Ops, erro ao realizar upload. Tente novamente ou entre em contato com o administrador do sistema."
            );
          }
        });
    } else {
      setModalShow(false);
      triggerModalUpgradePlan(true);
    }
  };

  const disabledActionSubmit = () => {
    if (user) {
      if (user.total_cloud_storage >= user.cloud_storage) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow(true)}
      >
        <i class="bi bi-person-workspace"></i>&nbsp; Novo Arquivo
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xs"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i class="bi bi-person-workspace"></i>&nbsp; Enviar Arquivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmitUploadFile)}>
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                className="form-control"
                name="file_title"
                {...register("file_title")}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label label="Categoria">
                <Form.Select
                  {...register("file_category", {
                    onChange: (e) => handleCategoryChange(e.target.value),
                  })}
                  value={category}
                  class="form-select form-control"
                  name="file_category"
                  aria-label="categoria_arquivo"
                >
                  <option value="general">Geral</option>
                  <option value="lawsuit">Processo</option>
                  <option value="client">Cliente</option>
                  <option value="employee">Funcionário</option>
                  <option value="support_case">Atendimento</option>
                </Form.Select>
              </Form.Label>
            </Form.Group>
            {category == "client" && <SelectClients register={register} />}

            {category == "employee" && (
              <SelectEmployeesByRoles
                register={register}
                label={"Funcionário"}
                option={"Selecione o funcionário..."}
              />
            )}

            {category == "lawsuit" && (
              <SelectLawsuits
                register={register}
                label={"Processo"}
                option={"Selecione o processo..."}
              />
            )}

            {category == "support_case" && (
              <SelectSupportCases
                register={register}
                label={"Atendimento"}
                option={"Selecione o atendimento..."}
              />
            )}

            <div className="mt-5">
              <div className="d-flex">
                <i
                  className="bi bi-exclamation-triangle"
                  style={{ color: "#dfd815" }}
                ></i>
                &nbsp;
                <p class="fs-6 text-left">Tamanho máximo de PDF: 10MB</p>
              </div>

              <Form.Control
                as="input"
                type="file"
                {...register("file")}
                accept="application/pdf"
              />
            </div>

            <div className="w-100">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                  <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                      onClick={() => setModalShow(false)}
                      type="button"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                    <Button
                      disabled={disabledButton}
                      className={
                        "d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor btn-bg-primary" +
                        (disabledActionSubmit() ? " custom-disabled" : "")
                      }
                      type="submit"
                    >
                      <i className="bi bi-funnel"></i>&nbsp; Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {progress > 0 ? (
              <div className="w-100">
                <ProgressBar
                  animated
                  now={progress}
                  label={`${progress}%`}
                  variant="success"
                  style={{ height: "35px" }}
                />
              </div>
            ) : null}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
