import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useSupportCases } from "../../hooks/useSupportCases";

export function SelectSupportCases({
  register,
  onChange,
  selected,
  filter,
  label,
  option,
}) {
  const { cases, isFetching } = useSupportCases(undefined, filter);
  const [selectedCase, setSelectedCase] = useState(0);

  const handleLawsuitUpdate = (event) => {
    setSelectedCase(event.target.value);
    onChange(event.target.value);
  };

  if (isFetching) {
    return (
      <>
        <FloatingLabel controlId="floatingInput" label={label}>
          <Form.Select class="form-select form-control">
            <option value="0">Carregando...</option>
          </Form.Select>
        </FloatingLabel>
      </>
    );
  }

  return (
    <>
      <FloatingLabel controlId="floatingInput" label={label}>
        <Form.Select
          class="form-select form-control posicao_cliente"
          {...register("support_case_id", {
            onChange: (e) => handleLawsuitUpdate(e),
          })}
          value={selected ? selected : selectedCase}
        >
          <option value="0">{option}</option>
          {cases?.map((support_case) => (
            <option key={support_case.id} value={support_case.id}>
              {support_case.title}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}
