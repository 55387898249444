import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useLawsuits } from "../../hooks/useLawsuits";

export function SelectOppositeParty({
  register,
  onChange,
  selected,
  required = false,
}) {
  const { lawsuits, isLoading } = useLawsuits(undefined);
  const opposites = lawsuits
    ?.map((item) => ({
      id: item.id,
      value: item.author,
      label: item.author,
    }))
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

  const handle = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <FloatingLabel controlId="floatingInput" label="Parte Contrária">
            <Form.Select
              className="form-select form-control"
              name="opposites"
              {...register("opposite_value")}
              aria-label="parte contrária"
              required
            >
              <option value="">Carregando... </option>
            </Form.Select>
          </FloatingLabel>
        </div>
      ) : (
        <FloatingLabel controlId="floatingInput" label="Parte Contrária">
          <Form.Select
            className="form-select form-control"
            name="opposites"
            {...register("opposite_value", {
              onChange: (e) => handle(e),
            })}
            value={selected}
            aria-label="parte contrária"
            required={required ? true : false}
          >
            <option value="">Selecione a parte contrária</option>
            {opposites?.map((opposite) => (
              <option key={opposite.id} value={opposite.value}>
                {opposite.label}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      )}
    </>
  );
}
