import React, { useState } from "react";
import NavbarDashboard from "../../components/NavbarDashboard";
import Form from "react-bootstrap/Form";
import Menu from "../../components/Menu";
import { useForm } from "react-hook-form";
import { SelectClients } from "../../components/Selects/SelectClients";
import TimesheetOverviewModal from "../../components/Modals/TimesheetOverviewModal";
import TimesheetRelatorios from "../../components/Modals/TimesheetRelatorios";
import ClientTimesheet from "./ClientTimesheet/ClientTimesheet";
import BaseLayout from "../../baseLayout";

function TimeSheet() {
  const { register, handleSubmit, reset } = useForm();
  const [selectedClient, setSelectedClient] = useState("nao informado");

  return (
    <BaseLayout title={"Timesheet"} page={"timesheet"}>
      <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
        <div className="d-flex align-items-center justify-content-end col-12 col-sm-12 col-md-12 col-lg-6">
          <TimesheetOverviewModal />
        </div>

        <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-3"></div>
      </div>

      <Form>
        <div className="container">
          <div className="col-6">
            <SelectClients register={register} onChange={setSelectedClient} />
          </div>

          {selectedClient !== "nao informado" ? (
            <div className="mt-5">
              <ClientTimesheet clientId={selectedClient} />
            </div>
          ) : (
            ""
          )}
        </div>
      </Form>
    </BaseLayout>
  );
}

export default TimeSheet;
