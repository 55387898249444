import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axiosAPI from "../../axiosAPI";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Spinner,
  Col,
  ListGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import PieChartGraph from "../Graphs/PieChartGraph";
import { useTimesheetAllTotalTime } from "../../hooks/useTimesheetAllTotalTime";
import { convertMsToHM } from "../../helper/timeConversion";
import { totalPay } from "../../helper/totalPay";

export default function TimesheetOverviewModal(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const {
    timesheetTotalTime,
    isLoading: isLoadingTotalTime,
    isFetching: isFetchingTotalTime,
  } = useTimesheetAllTotalTime();

  function transformDataChart(data) {
    let array = [];

    array.push([
      "Cliente",
      "Horas",
      { role: "tooltip", type: "string", p: { html: true } },
    ]);
    data?.map((client) => {
      array.push([
        client.client_name,
        Number(client.client_time),
        convertMsToHM(client.client_time),
      ]);
    });

    return array;
  }

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor "
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow(true)}
      >
        <i className="bi bi-person-workspace"></i>&nbsp; Visão Geral
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i className="bi bi-person-workspace"></i>&nbsp; Timesheet / Visão Geral
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {isFetchingTotalTime ? (
              <div className="d-flex justify-content-center m-5">
                <Spinner animation="border" variant="warning" />
              </div>
            ) : (
              <Row>
                <Col xs={12} lg={8}>
                  <Tabs
                    defaultActiveKey="TempoClientes"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="TempoClientes"
                      title="Tempo com Clientes"
                    ></Tab>
                  </Tabs>
                  {isLoadingTotalTime ? (
                    <Spinner size="sm" animation="border" variant="success" />
                  ) : (
                    <PieChartGraph
                      data={transformDataChart(timesheetTotalTime)}
                      name={"Tempo com cada Cliente"}
                    />
                  )}
                </Col>

                <Col xs={12} lg={4}>
                  <ListGroup>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Total de Horas</div>
                        {isLoadingTotalTime ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="success"
                          />
                        ) : (
                          convertMsToHM(
                            timesheetTotalTime?.reduce(
                              (a, v) => (a = a + Number(v.client_time)),
                              0
                            )
                          )
                        )}
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Tempo médio dos Eventos</div>
                        média
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Valor total</div>
                        {isLoadingTotalTime ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="success"
                          />
                        ) : (
                          timesheetTotalTime?.reduce(
                            (a, v) =>
                              (a =
                                a +
                                Number(
                                  totalPay(
                                    Number(v.client_time),
                                    Number(v.client_price)
                                  )
                                )),
                            0
                          )
                        )}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
