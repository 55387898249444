import {
  Document,
  PDFViewer,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
//import { id } from "date-fns/locale";
import { isMobile } from "react-device-detect";
import { fontSemiBold } from "../../utils/fontWeight";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    marginLeft: 0,
    marginRight: 20,
    padding: 5,
    flexGrow: 1,
  },
  header: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  headerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1 solid #c9c9c9",
  },
  body: {
    fontSize: 10,
  },
  itemDiv: {
    marginTop: 10,
    borderBottom: "1 solid #c9c9c9",
    textAlign: "justify",
  },
  itemText: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 11,
  },
});

const formateDateTime = (dateTime) => {
  const data = dateTime.split(" ")[0];
  const hora = dateTime.split(" ")[1];
  const dataArray = data.split("-");
  const dataFormatted = `${dataArray[2]}/${dataArray[1]}/${dataArray[0]} às ${hora}`;
  return dataFormatted;
};

const Reports = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerDiv}>
            <Text style={styles.header}>Compromissos</Text>
          </View>
          <View style={styles.body}>
            {data.map((item, index) => {
              return (
                <View key={index} style={styles.itemDiv}>
                  <Text style={styles.itemText}>{`Compromisso ${++index}: ${
                    item.title
                  } será de ${formateDateTime(
                    item.start_date
                  )} até ${formateDateTime(item.end_date)}`}</Text>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ModalReportCalendar = ({ show, handleClose, data, types }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [viewReport, setViewReport] = useState(false);
  const [dataFiltered, setDataFiltered] = useState(null);

  const handleChange = ({ target }) => {
    if (target.value === "") {
      setSelectedType(null);
    } else {
      setSelectedType(target.value);
    }
  };

  /*const renderReport = async () => {
    await setDataFiltered(
      data.filter((item) => item.commitment_type_id == selectedType)
    );
    setViewReport(!viewReport);
  };*/

  const returnFileNamePDF = () => {
    let type = "todos";

    switch (selectedType) {
      case "1":
        type = "interno";
        break;

      case "2":
        type = "externo";
        break;

      case "3":
        type = "audiencia";
        break;

      case "4":
        type = "reuniao-interna";
        break;

      case "5":
        type = "reuniao-externa-cliente";
        break;

      case "6":
        type = "particular";
        break;

      case "7":
        type = "outros";
        break;
    }

    return `relatorio-compromisso-${type}`;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="xl"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Relatório disponível para download</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          <h5>Selecione o tipo de compromisso</h5>
          <Form.Select
            id="commitment_type_id"
            className="form-select form-control"
            onChange={handleChange}
            name="commitment_type_id"
            aria-label="commitment_type_id"
          >
            <option value="">Todos</option>
            {types.map((type) => (
              <option key={type.id} value={type.id}>
                {type.title}
              </option>
            ))}
          </Form.Select>

          {isMobile ? (
            <PDFDownloadLink
              document={
                <Reports
                  data={
                    data && selectedType
                      ? data.filter((item) => {
                          return (
                            item.commitment_type_id == selectedType &&
                            item.status === "open"
                          );
                        })
                      : data && data.filter((data) => data.status === "open")
                  }
                />
              }
              fileName={returnFileNamePDF()}
            >
              Download: {returnFileNamePDF()}.pdf
            </PDFDownloadLink>
          ) : (
            <PDFViewer width={"100%"} height={"900px"} title="Relatório">
              <Reports
                data={
                  data && selectedType
                    ? data.filter((item) => {
                        return (
                          item.commitment_type_id == selectedType &&
                          item.status === "open"
                        );
                      })
                    : data && data.filter((data) => data.status === "open")
                }
              />
            </PDFViewer>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ModalReportCalendar;
