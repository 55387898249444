import React from "react";
import "./styles.css";
import NavbarDashboard from "../../components/NavbarDashboard";
import Menu from "../../components/Menu";
import FavortiteLawsuitCard from "../../components/Cards/FavoriteLawsuitCard";
import AuthorizedPages from "../../authorizedPages";

function Favorites() {
  return (
    <>
      <NavbarDashboard />

      <body className="home">
        <Menu />

        <div className="height"></div>

        <div className="container">
          <div className="container-favorites">
            <AuthorizedPages page={"favorite"}>
              <FavortiteLawsuitCard />
            </AuthorizedPages>
          </div>
        </div>
      </body>
    </>
  );
}

export default Favorites;
