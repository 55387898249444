import { useQueries } from "@tanstack/react-query";
import axiosAPI from "../../axiosAPI";

export const useTimesheetReport = (clientId, filters = null) => {
  let timesheetReport = false;

  const [timesheetQuery, timesheetHourPriceQuery, timesheetTotalTimeQuery] =
    useQueries({
      queries: [
        {
          queryKey: ["timesheet", clientId, filters],
          queryFn: () =>
            axiosAPI
              .get(
                `/timesheet?clientId=${clientId}&startDate=${
                  filters ? filters.start_date : ""
                }&endDate=${filters ? filters.end_date : ""}`
              )
              .then((res) => res.data),
        },

        {
          queryKey: ["timesheet_hour_price", clientId],
          queryFn: () =>
            axiosAPI
              .get(`/timesheet_price?clientId=${clientId}`)
              .then((res) => res.data),
        },

        {
          queryKey: ["timesheet_total_time", clientId, filters],
          queryFn: () =>
            axiosAPI
              .get(
                `/timesheet_total_time?clientId=${clientId}&startDate=${
                  filters ? filters.start_date : ""
                }&endDate=${filters ? filters.end_date : ""}`
              )
              .then((res) => res.data),
        },
      ],
    });

  if (
    timesheetQuery.isFetching ||
    timesheetHourPriceQuery.isFetching ||
    timesheetTotalTimeQuery.isFetching
  ) {
    return timesheetReport;
  } else {
    timesheetReport = true;
    return {
      timesheetReport,
      timesheetQuery,
      timesheetHourPriceQuery,
      timesheetTotalTimeQuery,
    };
  }
};
