import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useUserFiles = (fileId, filter = false) => {
    let url;
    let queryKey;
    
    if(fileId === undefined && !filter){
        queryKey = "list";
        url = '';
    }

    if(fileId){
        queryKey = "detail";
        url = `/${fileId}`;
    }

    if(filter){
        queryKey = "list";
        url = `?name${filter.name ?? ""}
                &category=${filter.category ?? ""}
                &sort=${filter.sort_by ?? ""}
                &client_id=${filter.client_id ?? ""}
                &lawsuit_id=${filter.lawsuit_id ?? ""}
                &employee_id=${filter.employee_id ?? ""}
                &support_case_id=${filter.support_case_id ?? ""}
                &extension=${filter.extension}`;
    }
        
    const query = useQuery(['userFiles', queryKey, url], (url) =>
        axiosAPI.get('/files' + url.queryKey[2])
        .then((res) => res.data)
    );

    return {
        files: query.data?.files, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}