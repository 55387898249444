import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Image, Spinner } from "react-bootstrap";
import axiosAPI from "../../axiosAPI";

export default function ViewFileModal({ id, extension }) {
  const [modalShow, setModalShow] = useState(false);
  const [fileView, setFileView] = useState();

  const extensions = {
    image: ["jpeg", "jpg", "png", "svg", "bmp", "gif", "jfif"],
    audio: ["mp3", "wav", "wma"],
    video: ["avi", "mov", "mp4"],
    text: ["txt", "csv", "json"],
    documents: ["docx", "xlsx", "ppt", "pptx", "pdf"],
  };

  function createModalBody(extension, url) {
    if (extensions.image.includes(extension)) {
      return <Image width="700" className="mx-auto d-block" src={url} />;
    }
    if (extension === "pdf") {
      return (
        <a href={url} target="_blank">
          Abrir PDF
        </a>
      );
    }
  }

  function openFile(id, extension) {
    setModalShow(true);
    setFileView(
      <div class="d-flex justify-content-center m-5">
        <Spinner animation="border" variant="warning" />
      </div>
    );
    axiosAPI.get(`files/${id}`).then((response) => {
      setFileView(createModalBody(extension, response.data.temporary_url));
    });
  }

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => openFile(id, extension)}
      >
        <i class="bi bi-person-workspace"></i>&nbsp; Abrir
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i class="bi bi-person-workspace"></i>&nbsp; título modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{fileView}</Modal.Body>
      </Modal>
    </>
  );
}
