import { useQuery } from '@tanstack/react-query'
import axiosAPI from '../axiosAPI';

export const useLawsuitTypes = ( ) => {

    const query = useQuery(['LawsuitTypes'], () => 
        axiosAPI.get('/lawsuit_types')
        .then((res) => res.data)
    );


    return {
        lawsuitTypes: query.data?.lawsuit_types, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}