import { useEffect, useState } from "react";


export const useCities = ({uf}) => {
    const [cities, setCities] = useState([])

    useEffect(() => {
        if (!uf) return;
        fetch(`https://brasilapi.com.br/api/ibge/municipios/v1/${uf}`)
            .then((response) => response.json())
            .then((data) => setCities ((data)))
    }, [uf])

    return {
        cities
    };
}

