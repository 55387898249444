import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalDeleteAccount({ show, handleClose, handleSubmit }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Você tem certeza?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Ao confirmar, você será redirecionado para tela inicial e sua conta será
        excluída imediatamente!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleSubmit}>
          Prosseguir com exclusão
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDeleteAccount;
