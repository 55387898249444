import { useState } from "react";
import { useCities } from "../../hooks/useCities"
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export const SelectCity = ({ uf, register, selected}) => {
    const [selectedCity, setSelectedCity] = useState(selected);
    const {cities} = useCities({
        uf
    })

    const handleCityUpdate = (event) => {
        setSelectedCity(event.target.value)
    }

    if(cities?.length == 0 || cities?.message == 'UF não encontrada.'){
        return (        
            <FloatingLabel
                controlId="floatingInput"
                label="Cidade"
            >
                <Form.Select 
                    disabled
                    value={selectedCity} 
                    class="form-select form-control" 
                    name='city' 
                    aria-label="Cidade">
                        <option value='0'>Selecione um estado...</option>
                </Form.Select>
            </FloatingLabel>
        )
    }

    return (
        <FloatingLabel
            controlId="floatingInput"
            label="Cidade"
        >
            <Form.Select 
                value={selectedCity} 
                class="form-select form-control" 
                name='city' 
                {...register('city', {onChange: (e) => {handleCityUpdate(e)}})} 
                aria-label="Cidade">
                    <option value=''>Selecione uma Cidade</option>
                    {cities?.map(city => 
                        <option key={city.nome} value={city.nome}>{city.nome}</option>
                    )}

            </Form.Select>
        </FloatingLabel>
    )

}