import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axiosAPI from "../../axiosAPI";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import UsersTable from "../Tables/UsersTable";

export default function ListUsersModal(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow(true)}
      >
        <i class="bi bi-person-workspace"></i>&nbsp; Listar Usuários
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i class="bi bi-person-workspace"></i>&nbsp; Usuários
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UsersTable />
        </Modal.Body>
      </Modal>
    </>
  );
}
