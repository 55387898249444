import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useUsers = () => {
  const query = useQuery(["users"], () =>
    axiosAPI.get("/users").then((res) => res.data)
  );

  return {
    users: query.data?.users,
    isLoading: query.isLoading,
    isError: query.error,
    isFetching: query.isFetching,
  };
};
