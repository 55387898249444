import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useLawsuitClientPositions } from "../../hooks/useLawsuitClientPositions";
import { Controller, useFormContext } from "react-hook-form";

export default function SelectLawsuitClientPositions({ register, selected }) {
  const { lawsuitClientPositions, isLoading, isError } =
    useLawsuitClientPositions();
  const methods = useFormContext();

  if (selected) {
    return (
      <>
        {isLoading ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Posição do Cliente">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_client_position"
                {...methods?.register("lawsuit_client_position")}
                defaultValue={selected}
                aria-label="Posição do Cliente"
                required
              >
                <option>Carregando... </option>
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : methods ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Status Processo">
              <Form.Select
                className="form-select form-control"
                aria-label="Status Processo"
                {...methods?.register("lawsuit_client_position")}
                defaultValue={selected}
              >
                <option value="">Selecione ...</option>
                {lawsuitClientPositions?.map((lawsuitClientPosition) => (
                  <option
                    key={lawsuitClientPosition.id}
                    value={lawsuitClientPosition.id}
                  >
                    {lawsuitClientPosition.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : (
          ""
        )}
      </>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Posição do Cliente">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_status"
                {...register("lawsuit_client_position")}
                aria-label="Posição do Cliente"
                required
              >
                <option>Carregando... </option>
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : (
          <div>
            <FloatingLabel controlId="floatingInput" label="Status Processo">
              <Form.Select
                className="form-select form-control"
                aria-label="Status Processo"
                {...register("lawsuit_client_position")}
              >
                <option value="">Selecione ...</option>
                {lawsuitClientPositions?.map((lawsuitClientPosition) => (
                  <option
                    key={lawsuitClientPosition.id}
                    value={lawsuitClientPosition.id}
                  >
                    {lawsuitClientPosition.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </div>
        )}
      </>
    );
  }
}
