import React from "react";

import "animate.css";
import "./styles.css";

import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import SignPlan from "../../components/SignPlan";
import Video from "../../components/Video";

import { Accordion } from "react-bootstrap";
import { commons } from "./commons";

function Presentation() {
  return (
    <>
      <Navbar />

      <section className="section-body">
        <Banner />
        <Video />
        <SignPlan />

        <div className="questions-accordions">
          <h4>Perguntas frequentes</h4>

          <Accordion>
            {commons.map((common) => (
              <Accordion.Item key={common.id} eventKey={common.title}>
                <Accordion.Header>{common.title}</Accordion.Header>
                <Accordion.Body style={{ color: "black" }}>
                  <div dangerouslySetInnerHTML={{ __html: common.body }} />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>

        <Footer />
      </section>
    </>
  );
}

export default Presentation;
