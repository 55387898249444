import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import BaseLayout from "../../baseLayout";
import { mockNotificationLawyerTime } from "../../utils/mockNotificationLawyerTime";
import NotificationsContext from "../../contexts/Notifications/NotificationsContext";

import "./styles.css";

function NotificationDetail() {
  let { id } = useParams();
  const { notifications, updateNotification } =
    useContext(NotificationsContext);
  const [commitment, setCommitment] = useState();

  useEffect(() => {
    const notification = notifications?.commitments?.find((n) => n.id == id);
    if (notification) {
      setCommitment(notification);
      if (notification?.already_read == 0) {
        updateNotification(id)
          .then(() => {
            toast.info("Notificação lida.", { position: "bottom-right" });
          })
          .catch((err) => console.log(err));
      }
    }
  }, [notifications]);

  return (
    <BaseLayout title={"Notificação"} page={true}>
      <Container>
        <h3>
          <i className="bi bi-person-workspace"></i>&nbsp; Evento{" "}
          {commitment?.title}
        </h3>
        <div
          className={
            commitment?.already_read
              ? "content-infos-notification read"
              : "content-infos-notification"
          }
        >
          <div className="content-item-notification">
            <p>Título:</p>
            <h6>{commitment?.title}</h6>
          </div>

          {commitment?.start_date ? (
            <div className="content-item-notification">
              <p>Início do compromisso:</p>
              <h6>
                {format(parseISO(commitment.start_date), "dd/MM/yyyy HH:mm")}
              </h6>
            </div>
          ) : null}

          {commitment?.end_date ? (
            <div className="content-item-notification">
              <p>Fim do compromisso:</p>
              <h6>
                {format(parseISO(commitment.end_date), "dd/MM/yyyy HH:mm")}
              </h6>
            </div>
          ) : null}

          {commitment?.client_id && commitment?.client_name ? (
            <div className="content-item-notification">
              <p>Cliente:</p>
              <h6>{commitment?.client_name}</h6>
            </div>
          ) : null}

          <div className="content-item-notification">
            <p>Qual o compromisso:</p>
            <h6>{commitment?.commitment_types_title}</h6>
          </div>

          {commitment?.notification_lawyer_time ? (
            <div className="content-item-notification">
              <p>Vou ser notificado:</p>
              <h6>
                {
                  mockNotificationLawyerTime().find(
                    (n) => n.value == commitment.notification_lawyer_time
                  )?.label
                }
              </h6>
            </div>
          ) : null}

          <div className="content-item-notification">
            <p>Observação:</p>
            <h6>
              {commitment?.obs_general
                ? commitment.obs_general
                : "Nenhuma observação cadastrada."}
            </h6>
          </div>
        </div>
      </Container>
    </BaseLayout>
  );
}

export default NotificationDetail;
