import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

async function getUserNotifications() {
  const query = await axiosAPI
    .get("/user_notifications")
    .then((res) => res.data);
  return query.notifications;
}

async function updateUserNotification(id) {
  const query = await axiosAPI
    .patch(`/user_notifications/${id}`)
    .then((res) => res.data);
  return query.notifications;
}

function useUserNotifications() {
  const queryClient = useQueryClient();

  const {
    data: notifications,
    isLoading,
    isError,
    isFetching,
  } = useQuery(["userNotifications"], getUserNotifications);

  const getNotificationMutation = useMutation(getUserNotifications, {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });

  const updateNotificationMutation = useMutation(updateUserNotification, {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });

  return {
    notifications: notifications || [],
    isLoading,
    isError,
    isFetching,

    getNotificationMutation: getNotificationMutation.mutateAsync,
    updateNotification: updateNotificationMutation.mutateAsync,
  };
}

export default useUserNotifications;
