import React from "react";
import { Spinner } from "react-bootstrap";

function ButtonPDF({ isFetching, setFnCallback }) {
  return (
    <p
      style={{
        textAlign: "left",
        fontSize: "14px",
        cursor: "pointer",
        fontWeight: "600",
        color: !isFetching ? "#3b71ca" : "#b3b3b3",
        textDecoration: "underline",
      }}
      onClick={() => {
        if (!isFetching) {
          setFnCallback(true);
        }
      }}
    >
      <i class="bi bi-file-pdf" style={{ marginRight: "2px" }}></i>
      Imprimir em PDF{" "}
      {isFetching ? (
        <Spinner
          size="sm"
          animation="border"
          variant="warning"
          style={{ marginLeft: "10px" }}
        />
      ) : null}
    </p>
  );
}

export default ButtonPDF;
