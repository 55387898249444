import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useLawsuits = (lawsuitId, filter = false) => {
  let url;
  let queryKey;

  if (lawsuitId === undefined && !filter) {
    queryKey = "list";
    url = "";
  }

  if (lawsuitId) {
    queryKey = "detail";
    url = `/${lawsuitId}`;
  }

  if (filter) {
    queryKey = "list";
    url = `?client_id=${filter.client_id ?? ""}&title=${
      filter.lawsuit_title ?? ""
    }&number=${filter.lawsuit_number}&lawsuit_status=${
      filter.lawsuit_status
    }&lawsuit_rite=${filter.lawsuit_rite}&lawsuit_type=${
      filter.lawsuit_type
    }&lawsuit_client_position=${
      filter.lawsuit_client_position
    }&opposite_value=${filter.opposite_value}`;
  }

  const query = useQuery(["lawsuits", queryKey, url], (url) =>
    axiosAPI.get("/lawsuits" + url.queryKey[2]).then((res) => res.data)
  );

  return {
    lawsuits: query.data?.lawsuits ? query.data?.lawsuits : query.data?.lawsuit,
    isLoading: query.isLoading,
    isError: query.error,
    isFetching: query.isFetching,
  };
};
