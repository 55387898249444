import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axiosAPI from "../../axiosAPI";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";

export default function TimesheetCreateEventModal({
  clientId,
  setReload,
  setReloadTotalHour,
}) {
  const [modalShow, setModalShow] = React.useState(false);
  const { register, handleSubmit, reset } = useForm();
  const queryClient = useQueryClient();

  const onSubmitCreateTimesheetEvent = (values) => {
    setReload(true);
    setReloadTotalHour(true);
    setModalShow(false);
    axiosAPI.post("timesheet", values).then((data) => {
      queryClient.invalidateQueries({
        queryKey: ["TimesheetAllTotalTime", "list"],
      });
      queryClient.invalidateQueries({ queryKey: ["timesheet"] });
      queryClient.invalidateQueries({ queryKey: ["timesheet_hour_price"] });
      queryClient.invalidateQueries({ queryKey: ["timesheet_total_time"] });
      toast.success("Evento Cadastrado!", {
        position: toast.POSITION.TOP_CENTER,
      });
      reset();
    });
  };

  return (
    <>
      <Button
        className="bg-light border shadow-sm fs-6 rounded-2 text-dark borderColor"
        onClick={() => setModalShow(true)}
      >
        Adicionar
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            Novo evento Timesheet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmitCreateTimesheetEvent)}>
            <Form.Group>
              <FloatingLabel label="Título">
                <Form.Control
                  className="form-control"
                  name="timesheet_title"
                  {...register("timesheet_title")}
                  required
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="Descrição">
                <Form.Control
                  as="textarea"
                  style={{ height: "100px" }}
                  className="form-control"
                  name="timesheet_description"
                  {...register("timesheet_description")}
                />
              </FloatingLabel>

              <div className="row">
                <div className="col-6">
                  <FloatingLabel controlId="floatingInput" label="Data Início">
                    <Form.Control
                      type="date"
                      class="form-control"
                      name="timesheet_start_date"
                      {...register("timesheet_start_date")}
                      placeholder="Insira a data de Inicio"
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="col-6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Horário Início"
                  >
                    <Form.Control
                      type="time"
                      class="form-control"
                      name="timesheet_start_time"
                      {...register("timesheet_start_time")}
                      placeholder="Insira a data de Inicio"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-6">
                  <FloatingLabel controlId="floatingInput" label="Data Fim">
                    <Form.Control
                      type="date"
                      class="form-control"
                      name="timesheet_end_date"
                      {...register("timesheet_end_date")}
                      placeholder="Insira a data de Inicio"
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="col-6">
                  <FloatingLabel controlId="floatingInput" label="Horário Fim">
                    <Form.Control
                      type="time"
                      class="form-control"
                      name="timesheet_end_time"
                      {...register("timesheet_end_time")}
                      placeholder="Insira a data de Inicio"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>
            </Form.Group>

            <Form.Control
              type="hidden"
              value={clientId}
              className="form-control"
              name="client_id"
              {...register("client_id")}
            />

            <div className="w-100">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5">
                <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                  <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                      onClick={() => setModalShow(false)}
                      type="button"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                      type="submit"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      <i className="bi bi-funnel"></i>&nbsp; Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
