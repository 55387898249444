import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useClients } from "../../hooks/useClients";

export function SelectClients({
  register,
  onChange,
  selected,
  required = false,
}) {
  const { clients, isLoading } = useClients(undefined);

  const handleClientUpdate = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <FloatingLabel controlId="floatingInput" label="Cliente">
            <Form.Select
              className="form-select form-control posicao_cliente"
              name="client"
              {...register("client_id")}
              aria-label="clientes"
              required
            >
              <option value="">Carregando... </option>
            </Form.Select>
          </FloatingLabel>
        </div>
      ) : (
        <FloatingLabel controlId="floatingInput" label="Cliente">
          <Form.Select
            className="form-select form-control posicao_cliente"
            name="client"
            {...register("client_id", {
              onChange: (e) => handleClientUpdate(e),
            })}
            value={selected}
            aria-label="clientes"
            required={required ? true : false}
          >
            <option value="">Selecione o cliente</option>
            {clients?.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      )}
    </>
  );
}
