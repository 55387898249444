import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Spinner, Badge } from "react-bootstrap";
import { format, parseISO, isToday } from "date-fns";
import axiosAPI from "../../../axiosAPI";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import NotificationsContext from "../../../contexts/Notifications/NotificationsContext";

import "./styles.css";

export default function NotificationsList() {
  const { notifications, isFetching } = useContext(NotificationsContext);
  const queryClient = useQueryClient();

  function isDateToday(dateString) {
    const date = parseISO(dateString);
    return isToday(date);
  }

  function deleteNotification(id) {
    axiosAPI
      .delete(`/user_notifications/${id}`)
      .then((data) => {
        if (data.status === 200) {
          queryClient.invalidateQueries({ queryKey: ["userNotifications"] });
          toast.success("Notificação deletada!", { position: "bottom-right" });
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  return (
    <Row>
      <Col>
        {isFetching ? (
          <div className="d-flex justify-content-center m-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          <>
            {notifications.commitments?.length > 0 ? (
              notifications.commitments?.map((notification) => (
                <div
                  key={notification.id}
                  className={
                    notification.already_read
                      ? "content-list-notifications read"
                      : "content-list-notifications"
                  }
                >
                  <Link
                    key={notification.id}
                    to={`/notificacoes/${notification.id}`}
                  >
                    <h5>
                      {notification.title}&nbsp;
                      {!notification.already_read &&
                      isDateToday(notification.created_at) ? (
                        <Badge bg="success">novo</Badge>
                      ) : null}
                    </h5>

                    <p>
                      <b>Data início:</b>&nbsp;
                      {format(
                        parseISO(notification.start_date),
                        "dd/MM/yyyy HH:mm:ss"
                      )}
                    </p>
                    <p>
                      <b>Data fim:</b>&nbsp;
                      {format(
                        parseISO(notification.end_date),
                        "dd/MM/yyyy HH:mm:ss"
                      )}
                    </p>
                    {notification.obs_general ? (
                      <p className="text-ellipsis">
                        <b>Observação:</b>&nbsp;
                        {notification.obs_general}
                      </p>
                    ) : null}
                  </Link>

                  <div className="footer-actions">
                    {notification.already_read ? (
                      <span className="notification-read">
                        <i className="bi bi-eye"></i>&nbsp; lido
                      </span>
                    ) : (
                      <span className="notification-noread">
                        <i className="bi bi-eye-slash"></i>&nbsp; não lido
                      </span>
                    )}

                    <div
                      className="delete-notification"
                      onClick={() => {
                        deleteNotification(notification.id);
                      }}
                    >
                      | <i className="bi bi-trash"></i>&nbsp; Excluir
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="container-empty">
                <p className="fs-6 text-left">
                  Nenhuma notificação encontrada.
                </p>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}
