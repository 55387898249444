import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useCheckAuthorizedPages = () => {
  function setWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  const query = useQuery(["AuthorizedEntities"], () =>
    axiosAPI.get("auth/authorized").then((res) => {
      setWithExpiry("authorized_entities", res.data.authorized, 60000);

      return res.data;
    })
  );

  return {
    authorizedPages: query.data?.authorized,
    isLoading: query.isLoading,
    isError: query.error,
    isFetching: query.isFetching,
  };
};
