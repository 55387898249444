import { useEffect, useState } from "react";
import axiosAPI from "../axiosAPI";

export const useTimesheetHourPrice = (clientId) => {
    const [timesheetHourPrice, setTimesheetHourPrice] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState();
    const [reload, setReload] = useState(false);

    function handleResponse(data, loading, error){
        setTimesheetHourPrice(data);
        setLoading(loading);
        setError(error);
    }

    useEffect(() => {

        setLoading(true)

        axiosAPI.get(`/timesheet_price?clientId=${clientId}`)
        .then((response) => handleResponse(response.data.price, false, false))

        setReload(false);

    }, [clientId, reload])

    return {
        timesheetHourPrice, isLoading, isError, setReload
    };
}