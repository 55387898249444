import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Spinner,
  Accordion,
  FloatingLabel,
  Form,
  Button,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import BaseLayout from "../../baseLayout";
import LawsuitDetailCard from "../../components/Cards/LawsuitDetailCard";
import ActivityLogItemList from "../../components/Lists/ActivtyLogItemList";
import ObservationsList from "../../components/Lists/ObservationsList";
import UserFilesList from "../../components/Lists/UserFilesList";
import TextBoxObservations from "../../components/TextBoxes/TextBoxObservations";
import { useActivityLog } from "../../hooks/useActivityLog";
import { useLawsuits } from "../../hooks/useLawsuits";
import { useObservations } from "../../hooks/useObservations";
import axiosAPI from "../../axiosAPI";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

function LawsuitDetail() {
  let { id } = useParams();
  const { lawsuits: lawsuit, isFetching: isFetchingLawsuit } = useLawsuits(id);
  const { activity, isFetching: isFetchingActivity } = useActivityLog({
    filter: { entity: "lawsuit", entity_id: id },
  });
  const { observations, isFetching: isFetchingObservations } = useObservations(
    null,
    {
      entity: "lawsuit",
      entity_id: id,
    }
  );
  const queryClient = useQueryClient();
  const [observationEditText, setObservationEditText] = useState();

  function editObservationTitle() {
    axiosAPI
      .post("lawsuits/observation/title", {
        id,
        observation_title: observationEditText,
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ["lawsuits", "detail"],
        });
        queryClient.invalidateQueries({
          queryKey: ["observations", "list"],
        });
        toast.success("Observação importante adicionada com sucesso.", {
          position: "bottom-right",
        });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  return (
    <BaseLayout title={"Detalhes do Processo"} page={"lawsuit"}>
      {isFetchingLawsuit ? (
        <div class="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Container>
          <Row>
            <Col className="col-12 col-md-4" style={{ textAlign: "center" }}>
              <LawsuitDetailCard
                lawsuit={lawsuit}
                observations={observations}
                isFetching={isFetchingLawsuit || isFetchingObservations}
              />
              <TextBoxObservations entity={"lawsuit"} id={id} />
            </Col>
            <Col>
              <Accordion defaultActiveKey="activityLog" alwaysOpen={true} flush>
                <Accordion.Item eventKey="activityLog">
                  <Accordion.Header>Log de Atividades</Accordion.Header>
                  <Accordion.Body
                    style={{ maxHeight: 450, overflowY: "scroll" }}
                  >
                    <ActivityLogItemList
                      activity={activity}
                      isFetching={isFetchingActivity}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="files">
                  <Accordion.Header>Arquivos</Accordion.Header>
                  <Accordion.Body
                    style={{ maxHeight: 450, overflowY: "scroll" }}
                  >
                    <UserFilesList lawsuit_id={id} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="notes">
                  <Accordion.Header>Histórico</Accordion.Header>
                  <Accordion.Body
                    style={{ maxHeight: 450, overflowY: "scroll" }}
                  >
                    <Col xs={12} className="d-flex flex-column align-items-end">
                      <FloatingLabel
                        className="w-100"
                        label="OBSERVAÇÃO IMPORTANTE"
                      >
                        <Form.Control
                          as="textarea"
                          rows={15}
                          style={{ height: "100px", textAlign: "justify " }}
                          className="form-control"
                          defaultValue={lawsuit.observation_title}
                          onChange={(e) =>
                            setObservationEditText(e.target.value)
                          }
                        />
                      </FloatingLabel>
                      <div>
                        <Button
                          className="mt-4 d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                          onClick={editObservationTitle}
                          style={{ backgroundColor: "#DAA520" }}
                        >
                          <i className="bi bi-funnel"></i>&nbsp; Salvar
                        </Button>
                      </div>
                    </Col>

                    <ObservationsList
                      observations={observations}
                      isFetching={isFetchingObservations}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      )}
    </BaseLayout>
  );
}

export default LawsuitDetail;
