import { useQuery } from '@tanstack/react-query'
import axiosAPI from "../axiosAPI";

export const useEmployeeTypes = ( ) => {

    const query = useQuery(['EmployeeTypes'], () =>
        axiosAPI.get('/employee_types')
        .then((res) => res.data)
        );  

        return {
            employeeTypes: query.data?.employee_types,
            isLoading: query.isLoading, 
            isError: query.error, 
            isFetching: query.isFetching
        };
}