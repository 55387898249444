import { Form } from "react-bootstrap"
import React, {useState} from 'react';

export default function UserPermissionCheckbox({register, entity, entitiesPermissions, permissions}){

    return (
        <>
            {
                permissions?.map((permission) => (
                    entitiesPermissions?.[entity] ?
                        <Form.Check 
                            inline
                            type='checkbox'
                            label={`${permission}`}
                            name={`${entity}`}
                            defaultChecked
                            {...register(entity)}
                        />
                        :                                 
                        <Form.Check 
                            inline
                            type='checkbox'
                            label={`${permission}`}
                            name={`${entity}`}
                            {...register(entity)}
                        />
                    )
            )}
        </>
    )
}