import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useLawsuits } from "../../hooks/useLawsuits";

export function SelectLawsuits({
  register,
  onChange,
  selected,
  filter,
  label,
  option,
}) {
  const { lawsuits, isLoading, isFetching } = useLawsuits(undefined, filter);
  const [selectedLawsuit, setSelectedLawsuit] = useState(0);

  const handleLawsuitUpdate = (event) => {
    setSelectedLawsuit(event.target.value);
    onChange(event.target.value);
  };

  if (isFetching) {
    return (
      <>
        <FloatingLabel controlId="floatingInput" label={label}>
          <Form.Select class="form-select form-control">
            <option value="0">Carregando...</option>
          </Form.Select>
        </FloatingLabel>
      </>
    );
  }

  return (
    <>
      <FloatingLabel controlId="floatingInput" label={label}>
        <Form.Select
          class="form-select form-control posicao_cliente"
          {...register("lawsuit_id", {
            onChange: (e) => handleLawsuitUpdate(e),
          })}
          value={selected ? selected : selectedLawsuit}
        >
          <option value="0">{option}</option>
          {lawsuits?.map((lawsuit) => (
            <option value={lawsuit.id}>{lawsuit.number}</option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}
