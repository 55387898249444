import React from "react";

function Footer() {
  const date = new Date().getFullYear();
  return (
    <div className="container-fluid d-flex justify-content-around footer2 col-12 col-sm-12 col-md-12 col-lg-12 pt-3">
      <div className="container d-flex flex-wrap justify-content-around footer2 col-12 col-sm-12 col-md-7 col-lg-8">
        <p className="text-footer2">
          &copy; Easyvog {date}
        </p>

        <a
          href="https://www.easyvogplanos.com/politica-de-privacidade/"
          target="_blank"
          className="text-footer2"
        >
          Termos de uso e privacidade
        </a>
      </div>
    </div>
  );
}

export default Footer;
