import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useActivityLog = ({ filter = false }) => {
  let url;
  let queryKey;

  if (filter) {
    queryKey = "list";
    url = `?entity=${filter.entity}&date_start=${filter.date_start}&date_end=${filter.date_end}&entity_id=${filter.entity_id}`;
  }

  if (!filter) {
    queryKey = "list";
    url = "";
  }

  const query = useQuery(["activityLog", queryKey, url], (url) =>
    axiosAPI.get("/activityLog" + url.queryKey[2]).then((res) => res.data)
  );

  return {
    activity: query.data?.activity,
    isLoading: query.isLoading,
    isError: query.error,
    isFetching: query.isFetching,
  };
};
