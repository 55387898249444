import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useSupportCases = (caseId, filter = false) => {
    let url;
    let queryKey;

    if(caseId === undefined && !filter){
        queryKey = "list";
        url = '';
    }

    if(caseId){
        queryKey = "detail";
        url = `/${caseId}`;
    }

    if(filter){
        queryKey = "list";
        url = `?client_id=${filter.client_id??''}&title=${filter.title??''}`
    }
     
    const query = useQuery(["support_case", queryKey, url], (url) =>
      axiosAPI.get("/support_case" + url.queryKey[2]).then((res) => res.data)
    );
    
    return {
      cases: query.data?.support_cases,
      isLoading: query.isLoading,
      isError: query.error,
      isFetching: query.isFetching,
    };
}

