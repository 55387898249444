import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputMask from "react-input-mask";
import axiosAPI from "../../axiosAPI";
import { SelectState } from "../../components/Selects/SelectState";
import { SelectCity } from "../../components/Selects/SelectCity";
import SelectEmployeeTypes from "../Selects/SelectEmployeeTypes";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function EmployeeEditForm({ employee, setEditEmployee }) {
  const [selectedUf, setSelectedUf] = useState(employee.state);

  const { register, handleSubmit, reset } = useForm();

  const queryClient = useQueryClient();

  const onSubmitEditEmployee = (values) => {
    axiosAPI
      .patch(`/employees/${values.employee_id}`, values)
      .then((result) => {
        if (result.status == 200) {
          queryClient.invalidateQueries({ queryKey: ["employees", "list"] });
          toast.success("Funcionário editado", { position: "bottom-right" });
          setEditEmployee(false);
        }
      })
      .catch((_) =>
        toast.error(
          "Ops, ocorreu um erro. Tente novamente."
        )
      );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitEditEmployee)}>
      <Form.Group className="mb-3">
        <div className="w-100">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <FloatingLabel label="Nome do funcionário">
              <Form.Control
                defaultValue={employee.name}
                {...register("employee_name")}
                type="text"
                id="inputNome"
                name="employee_name"
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <SelectEmployeeTypes
                register={register}
                selected={employee.type_id}
              />
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="OAB">
                <Form.Control
                  defaultValue={employee.oab}
                  {...register("employee_oab")}
                  type="text"
                  name="employee_oab"
                  area-label="oab"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Estado OAB">
                <Form.Control
                  defaultValue={employee.oab_state}
                  {...register("employee_oab_state")}
                  type="text"
                  name="employee_oab_state"
                  aria-label="Estado oab"
                />
              </FloatingLabel>
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="RG">
                <Form.Control
                  defaultValue={employee.rg}
                  {...register("employee_rg")}
                  type="text"
                  class="form-control"
                  name="employee_rg"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="CPF">
                <Form.Control
                  defaultValue={employee.cpf}
                  type="text"
                  {...register("employee_cpf")}
                  class="form-control"
                  name="employee_cpf"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <FloatingLabel label="Endereço completo">
              <Form.Control
                defaultValue={employee.address}
                type="text"
                {...register("employee_address")}
                name="employee_address"
              />
            </FloatingLabel>
          </div>
          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectState
                onChange={setSelectedUf}
                selected={selectedUf}
                register={register}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectCity
                uf={selectedUf}
                register={register}
                selected={employee.city}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Nacionalidade">
                <Form.Control
                  defaultValue={employee.nationality}
                  {...register("employee_nationality")}
                  type="text"
                  name="employee_nationality"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Estado civil">
                <Form.Control
                  defaultValue={employee.marital_status}
                  {...register("employee_marital_status")}
                  name="employee_marital_status"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Data de nascimento">
                <Form.Control
                  defaultValue={employee.birth_date}
                  {...register("employee_birth_date")}
                  type="date"
                  name="employee_birth_date"
                />
              </FloatingLabel>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Telefone">
                <InputMask
                  mask="(99) 9999-99999"
                  defaultValue={
                    employee.number ? employee.number.replace(/\D/g, "") : ""
                  }
                  {...register("employee_number")}
                  className="form-control"
                ></InputMask>
              </FloatingLabel>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-4">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
            {/* ADVISE  */}
            {/* <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                {employee.employee_advise_keyword_id ? 
                    <Form.Check 
                        type={'checkbox'}
                        {...register('employee_keyword_active')}
                        name='employee_keyword_active'
                        label={'Notificações Judiciais'}
                        defaultChecked
                    />
                    :

                    <Form.Check 
                        type={'checkbox'}
                        {...register('employee_keyword_active')}
                        name='employee_keyword_active'
                        label={'Notificações Judiciais'}
                    />
                }
            </div> */}
          </div>
          <Form.Control
            type="hidden"
            value={employee.employee_id}
            {...register("employee_id")}
            name="employee_id"
          />
          <Form.Control
            type="hidden"
            value={employee.number_id}
            {...register("number_id")}
            name="number_id"
          />
          <Form.Control
            type="hidden"
            value={employee.address_id}
            {...register("address_id")}
            name="address_id"
          />

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
            <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
              <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                <Button
                  className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                  style={{ backgroundColor: "#DAA520" }}
                  onClick={() => setEditEmployee(0)}
                >
                  Cancelar
                </Button>
              </div>
              <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                <Button
                  className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                  style={{ backgroundColor: "#DAA520" }}
                  type="submit"
                >
                  Salvar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
}
