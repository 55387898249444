import React from "react";

import "./styles.css";
import "../DropLoginDashboard/styles.css";
//import "../Navbar/styles.css";

import DropLoginDashboard from "../DropLoginDashboard";

function Navbar() {
  return (
    <div className="easyvog-navbar">
      <nav className="easyvog-nav">
        <div className="container">
          <div id="mainListDiv" className="main_list">
            <DropLoginDashboard />
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
