import { Spinner } from "react-bootstrap";
import { useChatUsers } from "../../../hooks/useChatUsers";
import ConversationsListItem from "./ConversationsListItem";
import "./styles.css";

export default function ConversationsList({ selectedChat, setSelectedChat }) {
  const { users, isLoading, isFetching } = useChatUsers();

  return (
    <>
      <p>Minhas conversas</p>

      {isLoading ? (
        <div className="loader-spinner">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        users.map((user) => (
          <div
            className={
              user.id == selectedChat
                ? "conversation-user active"
                : "conversation-user"
            }
            key={user.id}
            onClick={() => setSelectedChat(user.id)}
            style={{ cursor: "pointer" }}
          >
            <ConversationsListItem {...{ user }} />
          </div>
        ))
      )}
    </>
  );
}
