import { useQuery } from '@tanstack/react-query'
import axiosAPI from "../axiosAPI";


export const useLawsuitStatuses = ( ) => {

    const query = useQuery(['LawsuitStatuses'], () => 
        axiosAPI.get('/lawsuit_statuses')
        .then((res) => res.data)
    );

    return {
        lawsuitStatuses: query.data?.lawsuit_statuses, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}