import React from "react";

import $ from "jquery";

import "../ButtonLogin/styles.css";
import "./styles.css";

import ButtonLogin from "../ButtonLogin";

function Navbar() {
  $(window).scroll(function () {
    if ($(document).scrollTop() > 50) {
      $(".nav.navbar").addClass("affixx");
      $(".bi-person").addClass("text-white");
      $(".btn-login span").addClass("text-white");

      $(".vog").css({
        color: "#FFFFFF",
      });
    } else {
      $(".nav.navbar").removeClass("affixx");
      $(".bi-person").removeClass("text-white");
      $(".btn-login span").removeClass("text-white");

      $(".vog").css({
        color: "#000000",
      });
    }
  });

  return (
    <header className="section-header">
      <nav className="nav navbar navbar-expand-lg animate__animated animate__slideInDown animate__faster">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <p className="easy-title mb-0">
              easy<span className="vog"> VOG</span>
            </p>
          </a>

          <div className="content-items-navbar">
            <a
              href="https://api.whatsapp.com/send?l=pt_br&amp;phone=+5551992542818&amp;text=Gostaria de informações sobre o EASYVOG!"
              target="_blank"
            >
              <button class="wh-ap-btn"></button>
            </a>

            <ButtonLogin />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
