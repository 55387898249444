import React, { useContext, useState } from "react";

import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { Button, Form, FloatingLabel, Spinner } from "react-bootstrap";

import axiosAPI from "../../axiosAPI";

import AuthContext from "../../contexts/AuthContext/AuthContext";

import { SelectState } from "../../components/Selects/SelectState";
import { SelectCity } from "../../components/Selects/SelectCity";
import SelectEmployeeTypes from "../../components/Selects/SelectEmployeeTypes";
import { SelectOabState } from "../../components/Selects/SelectOabState";

export default function FormNovoFuncionario(props) {
  const { user, triggerModalUpgradePlan } = useContext(AuthContext);
  const [selectedUf, setSelectedUf] = useState();
  const [isSubmiting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (values) => {
    if (!disabledActionSubmit()) {
      setIsSubmitting(true);

      axiosAPI
        .post("/employees", values)
        .then((result) => {
          if (result.status == 200) {
            reset();
            setIsSubmitting(false);
            toast.success("Funcionário cadastrado ");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error.data?.message) {
            toast.error(error.data.message);
          } else {
            toast.error(
              "Ops, ocorreu um erro. Tente novamente ou entre em contato com o administrador do sistema."
            );
          }
        });
    } else {
      triggerModalUpgradePlan(true);
    }
  };

  const disabledActionSubmit = () => {
    if (user) {
      if (user.users_registered === null) {
        return false;
      }

      if (user.total_users_registered >= user.users_registered) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {isSubmiting ? (
        <div class="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <FloatingLabel label="Nome funcionário">
              <Form.Control
                type="text"
                className="form-control"
                id="inputNome"
                name="employee_name"
                {...register("employee_name")}
                placeholder="Insira o nome do funcionário"
                required
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <SelectEmployeeTypes register={register} />
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="OAB (apenas números)">
                <InputMask
                  mask="999999"
                  type="text"
                  className="form-control"
                  {...register("employee_oab")}
                  name="employee_oab"
                  placeholder="Insira o OAB"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <SelectOabState register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <SelectState onChange={setSelectedUf} register={register} />
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="RG">
                <Form.Control
                  type="text"
                  class="form-control"
                  name="employee_rg"
                  {...register("employee_rg")}
                  placeholder="Insira o RG"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="CPF">
                <InputMask
                  mask="999.999.999-99"
                  type="text"
                  class="form-control"
                  name="employee_cpf"
                  {...register("employee_cpf")}
                  placeholder="Insira seu CPF"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <FloatingLabel label="Endereço completo">
              <Form.Control
                type="text"
                class="form-control"
                name="employee_address"
                {...register("employee_address")}
                placeholder="Insira o endereço completo"
              />
            </FloatingLabel>
          </div>
          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <SelectCity uf={selectedUf} register={register} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Nacionalidade">
                <input
                  type="text"
                  class="form-control"
                  name="employee_nationality"
                  {...register("employee_nationality")}
                  placeholder="Insira a nacionalidade"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Estado civil">
                <Form.Select
                  class="form-select form-control"
                  name="employee_marital_status"
                  {...register("employee_marital_status")}
                >
                  <option selected>Selecione um estado civil</option>
                  <option value="casado">Casado</option>
                  <option value="solteiro">Solteiro</option>
                  <option value="divorciado">Divorciado</option>
                  <option value="separado">Separado</option>
                  <option value="viuvo">Viúvo</option>
                </Form.Select>
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Data de nascimento">
                <Form.Control
                  type="date"
                  class="form-control"
                  {...register("employee_birth_date")}
                  name="employee_birth_date"
                />
              </FloatingLabel>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Telefone fixo">
                <InputMask
                  mask="(99) 9999-9999"
                  className="form-control"
                  name="employee_phone"
                  {...register("employee_phone")}
                  placeholder="Insira um telefone fixo válido"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <FloatingLabel label="Telefone celular">
                <InputMask
                  mask="(99) 99999-9999"
                  className="form-control"
                  name="employee_mobile_phone"
                  {...register("employee_mobile_phone")}
                  placeholder="Insira um número de celular válido"
                />
              </FloatingLabel>
            </div>
          </div>

          <div className="d-flex justify-content-start col-12 col-sm-12 col-md-12 col-lg-3 mt-2 mb-5">
            <Button
              type="submit"
              className={
                "d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor btn-bg-primary" +
                (disabledActionSubmit() ? " custom-disabled" : "")
              }
            >
              <i class="bi bi-person-check"></i>&nbsp; Cadastrar funcionário
            </Button>
          </div>
        </Form>
      )}
    </>
  );
}
