import React ,{ useState } from "react";
import { useStates } from "../../hooks/useStates"
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export const  SelectOabState = ({ register, selected}) => {
    const { states } = useStates();
    const [selectedState, setSelectedState] = useState(0);

    const handleStateUpdate = (value) => {
        setSelectedState(value)
    }

    if(states?.length == 0){
        return (<></>)
    }

    return (
        <FloatingLabel
            label="Estado OAB"
        >
            <Form.Select 
                        value={selected ? selected : selectedState} 
                        class="form-select form-control" 
                        name='employee_oab_state' aria-label="Estado OAB"
                        {...register('employee_oab_state', {
                            onChange: (e) => {handleStateUpdate(e.target.value)}
                            }

                        )}
                        >
                            <option value="0">Selecione...</option>
                            {states && states.length > 0 && states.map(state => 
                                <option key={state.sigla+'-oab'} value={state.sigla}>{state.nome}</option>
                            )}
                            
            </Form.Select>
        </FloatingLabel>
    )
}