import { useFavoriteLawsuits } from "../../hooks/useFavoriteLawsuits";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

export default function FavortiteLawsuitCard(props) {
  const { favoriteLawsuits, isLoading } = useFavoriteLawsuits();

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center mt-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <>
          {favoriteLawsuits?.length > 0 ? (
            favoriteLawsuits?.map((favorite) => (
              <Link to={`/processo/${favorite.id}`}>
                <Card>
                  <Card.Header>Favorito</Card.Header>
                  <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                      Numero: {favorite.number}
                      <br />
                      Autor: {favorite.author}
                      <br />
                      Forum: {favorite.forum}
                      <br />
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </Card.Body>
                  <Card.Footer className="text-muted"></Card.Footer>
                </Card>
              </Link>
            ))
          ) : (
            <div className="container-empty">
              <p className="fs-6 text-left">Nenhum favorito encontrado.</p>
            </div>
          )}
        </>
      )}
    </>
  );
}
