import axios from "axios";

// API Local: "http://localhost:8010/api/"

const axiosAPI = axios.create({
  baseURL: "http://api.easyvog.com.br/api/",
});

axiosAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      axiosAPI.defaults.headers.Authorization = undefined;
      
      window.location.replace("/login");
    }

    return Promise.reject(error.response);
  }
);

export default axiosAPI;
