import {
  Document,
  PDFViewer,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Container, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { format, parseISO } from "date-fns";
import { fontSemiBold } from "../../utils/fontWeight";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    marginLeft: 0,
    marginRight: 20,
    padding: 5,
    flexGrow: 1,
  },
  headerTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  headerSubtitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
  },
  headerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  body: {
    fontSize: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    border: "1 solid #c9c9c9",
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  bodyTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  bodySubtitle: {
    fontSize: 10,
    paddingBottom: 2,
    marginBottom: 5,
  },
  itemDiv: {
    marginTop: 10,
    borderBottom: "1 solid #c9c9c9",
    textAlign: "justify",
  },
});

const Reports = ({ lawsuits, filter }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerDiv}>
            {lawsuits && lawsuits?.length > 0 ? (
              <Text style={styles.headerSubtitle}>
                Processos: <Text style={styles.headerTitle}>{filter}</Text>
              </Text>
            ) : null}
          </View>

          {lawsuits.map((lawsuit) => {
            return (
              <View key={lawsuit.id}>
                <View wrap={false} style={styles.body}>
                  <View style={styles.table}>
                    <Text style={styles.bodySubtitle}>
                      Cliente:{" "}
                      <Text style={styles.bodyTitle}>
                        {lawsuit.client_name}
                      </Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Número:{" "}
                      <Text style={styles.bodyTitle}>{lawsuit.number}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Autor:{" "}
                      <Text style={styles.bodyTitle}>{lawsuit.author}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Posição do Cliente:{" "}
                      <Text style={styles.bodyTitle}>{lawsuit.position}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Rito: <Text style={styles.bodyTitle}>{lawsuit.rite}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Tipo do processo:{" "}
                      <Text style={styles.bodyTitle}>{lawsuit.type}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Status do Processo:{" "}
                      <Text style={styles.bodyTitle}>{lawsuit.status}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Comarca:{" "}
                      <Text style={styles.bodyTitle}>
                        {lawsuit.district_court}
                      </Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Fórum:{" "}
                      <Text style={styles.bodyTitle}>{lawsuit.forum}</Text>
                    </Text>

                    <Text style={styles.bodySubtitle}>
                      Criado em:{" "}
                      <Text style={styles.bodyTitle}>
                        {format(
                          parseISO(lawsuit.created_at),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </Text>
                    </Text>
                  </View>
                </View>

                {lawsuit.observation_title && (
                  <View style={styles.body}>
                    <Text style={{ ...fontSemiBold, marginTop: 5 }}>
                      OBSERVAÇÃO IMPORTANTE:
                    </Text>
                    <Text
                      style={{
                        ...styles.bodySubtitle,
                        lineHeight: 1.8,
                      }}
                    >
                      {lawsuit.observation_title}
                    </Text>
                  </View>
                )}

                {lawsuit.observations.map((item) => (
                  <View
                    key={item.id}
                    style={{ ...styles.itemDiv, ...styles.body }}
                  >
                    <Text
                      style={{
                        ...styles.bodySubtitle,
                        ...fontSemiBold,
                      }}
                    >
                      {format(parseISO(item.created_at), "dd/MM/yyyy HH:mm")}
                    </Text>
                    <Text
                      style={{
                        ...styles.bodySubtitle,
                        lineHeight: 1.8,
                      }}
                    >
                      {item.observation}
                    </Text>
                  </View>
                ))}

                {!lawsuit.observations || lawsuit.observations?.length === 0 ? (
                  <View style={{ ...styles.itemDiv, ...styles.body }}>
                    <Text style={styles.bodySubtitle}>
                      Nenhum histórico registrado
                    </Text>
                  </View>
                ) : null}
              </View>
            );
          })}

          {!lawsuits || lawsuits?.length === 0 ? (
            <View style={styles.itemDiv}>
              <Text style={styles.bodySubtitle}>
                Nenhum processo registrado
              </Text>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

const ModalLawsuits = ({ show, handleClose, lawsuits, filter }) => {
  const returnFileNamePDF = () => {
    return `processos-${new Date().getTime()}`;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="xl"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Processos disponíveis para download</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          {isMobile ? (
            <PDFDownloadLink
              document={<Reports lawsuits={lawsuits} filter={filter} />}
              fileName={returnFileNamePDF()}
            >
              Download: {returnFileNamePDF()}.pdf
            </PDFDownloadLink>
          ) : (
            <PDFViewer width={"100%"} height={"900px"} title="Relatório">
              <Reports lawsuits={lawsuits} filter={filter} />
            </PDFViewer>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ModalLawsuits;
