import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useTimesheetAllTotalTime = (clientId, filter = false) => {

    let url;
    let queryKey;

    if(clientId === undefined && !filter){
        queryKey = "list";
        url = '';
    }

    if(clientId){
        queryKey = "detail";
        url = `/${clientId}`;
    }

    const query = useQuery(['TimesheetAllTotalTime', queryKey, url], (url) =>
        axiosAPI.get('/timesheet_all_total_time' + url.queryKey[2])
        .then((res) => res.data)
    );
    
    return {
        timesheetTotalTime: query.data?.timesheet, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}