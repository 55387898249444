import React from "react";

function SignPlan() {
  const refBtnSign = React.useRef(null);

  React.useEffect(() => {
    const btnSign = refBtnSign.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Adiciona a classe da animação quando o elemento estiver visível
          btnSign.classList.add(
            "animate__animated",
            "animate__fadeIn",
            "animate__faster"
          );
        } else {
          // Remove a classe da animação quando o elemento não estiver visível
          btnSign.classList.remove(
            "animate__animated",
            "animate__fadeIn",
            "animate__faster"
          );
        }
      });
    });

    observer.observe(btnSign);

    // Limpa o observador quando o componente for desmontado
    return () => {
      observer.unobserve(btnSign);
    };
  }, []);

  return (
    <div className="container-fluid section-process-acess">
      <div className="container d-flex flex-column flex-wrap justify-content-around align-items-center">
        <div
          ref={refBtnSign}
          className="col-12 col-sm-12 col-md-6 col-lg-4 d-grid align-items-center marginTop"
        >
          <li className="btn fs-6">
            <a
              href="https://www.easyvogplanos.com/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              Assinar agora um plano
            </a>
          </li>
          <p className="fs-6 text-center text-black text-bold">
            Tenha acesso a nossas ferramentas
          </p>
        </div>

        <div
          ref={refBtnSign}
          className="col-12 col-sm-12 col-md-6 col-lg-4 d-grid align-items-center marginTop"
        >
          <li className="btn fs-6">
            <a
              href="https://easyvog-pdfs.s3.sa-east-1.amazonaws.com/E-BOOK+EASYVOG.pdf"
              target="_blank"
              className="text-white"
            >
              Baixar E-Book EASYVOG
            </a>
          </li>
          <p className="fs-6 text-center text-black text-bold">
            Leia o Passo a Passo do software
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignPlan;
