import React from "react";

import "./styles.css";

import Banner from "../../components/Banner";
import NavbarDashboard from "../../components/NavbarDashboard";
import Menu from "../../components/Menu";

function Main() {
  return (
    <>
      <NavbarDashboard />

      <section className="home">
        <Menu />
        <Banner />
      </section>
    </>
  );
}

export default Main;
