import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useClients = (clientId, filter = false) => {
    let url;
    let queryKey;

    if(clientId === undefined && !filter){
        queryKey = "list";
        url = '';
    }

    if(clientId){
        queryKey = "detail";
        url = `/${clientId}`;
    }

    if(filter){
        queryKey = "list";
        url = `?name=${filter.name??''}&date=${filter.date??''}&city=${filter.city??''}&state=${filter.state??''}`
    }
    
    const query = useQuery(['clients', queryKey, url], (url) =>
        axiosAPI.get('/clients' + url.queryKey[2])
        .then((res) => res.data)
    );

    return {
        clients: query.data?.clients, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}

