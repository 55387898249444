import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useChatUsers = () => {
  const query = useQuery(["chatUsers"], () =>
    axiosAPI.get("chat/users").then((res) => res.data)
  );

  return {
    users: query.data?.users,
    isLoading: query.isLoading,
    isError: query.error,
    isFetching: query.isFetching,
  };
};
