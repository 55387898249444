import React, { createContext, useState } from "react";

const FirstLoadContext = createContext();

export const FirstLoadProvider = ({ children }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const updateFirstLoad = () => {
    setIsFirstLoad(false);
  };

  return (
    <FirstLoadContext.Provider value={{ isFirstLoad, updateFirstLoad }}>
      {children}
    </FirstLoadContext.Provider>
  );
};

export default FirstLoadContext;
