import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import TimesheetCreateEventModal from "../../../components/Modals/TimesheetCreateEventModal";
import Card from "react-bootstrap/Card";
import { Spinner } from "react-bootstrap";
import TimesheetListItem from "../../../components/ListItems/TimesheetListItem";
import EditClientHourPriceModal from "../../../components/Modals/EditClientHourPriceModal";
import { useTimesheetHourPrice } from "../../../hooks/useTimesheetHourPrice";
import { useTimesheet } from "../../../hooks/useTimesheet";
import { useTimesheetTotalTime } from "../../../hooks/useTimesheetTotalTime";
import { convertMsToHM } from "../../../helper/timeConversion";
import { totalPay } from "../../../helper/totalPay";
import TimesheetRelatorios from "../../../components/Modals/TimesheetRelatorios";

export default function ClientTimesheet({ clientId }) {
  const [filter, setFilter] = useState(true);
  const { timesheet, isLoading, setReload } = useTimesheet(undefined, filter);
  const {
    timesheetHourPrice,
    isLoading: isLoadingHourPrice,
    setReload: setPriceHourReload,
  } = useTimesheetHourPrice(clientId);
  const {
    timesheetTotalTime,
    isLoading: isLoadingTotalTime,
    setReload: setTotalTimeReload,
  } = useTimesheetTotalTime(clientId);

  useEffect(() => {
    setFilter({ clientId: clientId });
  }, [clientId]);

  return (
    <>
      <div className="row">
        <TimesheetRelatorios clientId={clientId} />
      </div>
      <div className="row">
        <div className="col-12 col-lg-4 mb-3">
          <ListGroup>
            <ListGroup.Item
              variant="success"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Custo/Hora</div>
                {isLoadingHourPrice ? (
                  <Spinner size="sm" animation="border" variant="success" />
                ) : timesheetHourPrice ? (
                  timesheetHourPrice.value
                ) : (
                  <>Não definido</>
                )}
              </div>
              <EditClientHourPriceModal
                setReload={setPriceHourReload}
                clientId={clientId}
              />
            </ListGroup.Item>
            <ListGroup.Item
              variant="primary"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Horas Totais</div>
                {isLoadingTotalTime ? (
                  <Spinner size="sm" animation="border" variant="primary" />
                ) : timesheetTotalTime ? (
                  convertMsToHM(timesheetTotalTime.total_time)
                ) : (
                  <>Não definido</>
                )}
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              variant="warning"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Custo Total</div>
                {isLoadingHourPrice || isLoadingTotalTime ? (
                  <Spinner size="sm" animation="border" variant="warning" />
                ) : timesheetTotalTime && timesheetHourPrice ? (
                  Number(
                    totalPay(
                      timesheetTotalTime.total_time,
                      timesheetHourPrice.value
                    )
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <>Não Calculado</>
                )}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        
        <div className="col-12 col-lg-8 mb-5">
          <Card>
            <Card.Header>
              <TimesheetCreateEventModal
                setReload={setReload}
                clientId={clientId}
                setReloadTotalHour={setTotalTimeReload}
              />
            </Card.Header>
            <ListGroup variant="flush">
              {isLoading ? (
                <div className="d-flex justify-content-center m-5">
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                timesheet?.map((event) => (
                  <>
                    <TimesheetListItem
                      setReload={setReload}
                      setReloadTotalHour={setTotalTimeReload}
                      event={event}
                    />
                  </>
                ))
              )}
            </ListGroup>
          </Card>
        </div>
      </div>
    </>
  );
}
