import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import axiosAPI from "../axiosAPI";

export const useEmployees = (employeeId, filter = false) => {
    let url;
    let queryKey;

    if(employeeId === undefined && !filter){
        queryKey = "list";
        url = '';
    }

    if(employeeId){
        queryKey = "detail";
        url = `/${employeeId}`;
    }

    if(filter){
        queryKey = "list";
        url = `?name=${filter.name??''}&date=${filter.data??''}&city=${filter.city??''}&state=${filter.state??''}&role=${filter.employee_role??''}`
    }
    
    const query = useQuery(['employees', queryKey, url], (url) =>
        axiosAPI.get('/employees' + url.queryKey[2])
        .then((res) => res.data)
    );

    return {
        employees: query.data?.employees, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
} 
