import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import ViewFileModal from "../Modals/ViewFileModal";
import DeleteFileButton from "../Buttons/DeleteFileButton";

export default function UserFileListItem({ file }) {
  return (
    <ListGroup.Item>
      <Row>
        {file?.employee_name ? (
          <div className="container d-flex w-100">
            <h6 className="text-ellipsis">Funcionário: {file.employee_name}</h6>
          </div>
        ) : null}

        {file?.support_case_title ? (
          <div className="container d-flex w-100">
            <h6 className="text-ellipsis">
              Atendimento #{file.support_case_id}: {file.support_case_title}
            </h6>
          </div>
        ) : null}

        <div className="container d-flex align-items-center justify-content-between">
          <h6 className="text-ellipsis" style={{ width: "80%" }}>
            {file.title + "." + file.extension}
          </h6>

          <span style={{ fontSize: "14px" }}>
            {format(parseISO(file.created_at), "dd/MM/yyyy HH:mm")}
          </span>
        </div>
      </Row>
      <Row>
        <div className="container d-flex align-items-center justify-content-between">
          <ViewFileModal id={file.id} extension={file.extension} />
          <DeleteFileButton id={file.id} />
        </div>
      </Row>
    </ListGroup.Item>
  );
}
