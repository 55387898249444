import {
  Document,
  PDFViewer,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Container, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { format, parseISO } from "date-fns";
import { fontSemiBold } from "../../utils/fontWeight";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 15,
    paddingBottom: 20,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    marginLeft: 0,
    marginRight: 20,
    padding: 0,
    flexGrow: 1,
  },
  headerTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  headerSubtitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
  },
  headerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  body: {
    fontSize: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    border: "1 solid #c9c9c9",
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  bodyTitle: {
    fontSize: 11,
    paddingBottom: 2,
    marginBottom: 5,
    ...fontSemiBold,
  },
  bodySubtitle: {
    fontSize: 10,
    paddingBottom: 2,
    marginBottom: 5,
  },
  itemDiv: {
    marginTop: 10,
    borderBottom: "1 solid #c9c9c9",
    textAlign: "justify",
  },
});

const Reports = ({ lawsuit, observations }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerDiv}>
            <Text style={styles.headerSubtitle}>
              Processo: <Text style={styles.headerTitle}>{lawsuit.title}</Text>
            </Text>
            <Text style={styles.headerSubtitle}>
              Número: <Text style={styles.headerTitle}>{lawsuit.number}</Text>
            </Text>
          </View>

          <View style={styles.body}>
            <View style={styles.table}>
              <Text style={styles.bodySubtitle}>
                Cliente:{" "}
                <Text style={styles.bodyTitle}>{lawsuit.client_name}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Autor: <Text style={styles.bodyTitle}>{lawsuit.author}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Posição do Cliente:{" "}
                <Text style={styles.bodyTitle}>{lawsuit.position}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Rito: <Text style={styles.bodyTitle}>{lawsuit.rite}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Tipo do processo:{" "}
                <Text style={styles.bodyTitle}>{lawsuit.type}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Status do Processo:{" "}
                <Text style={styles.bodyTitle}>{lawsuit.status}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Comarca:{" "}
                <Text style={styles.bodyTitle}>{lawsuit.district_court}</Text>
              </Text>

              <Text style={styles.bodySubtitle}>
                Fórum: <Text style={styles.bodyTitle}>{lawsuit.forum}</Text>
              </Text>
            </View>

            <Text style={{ ...styles.headerSubtitle, marginTop: 10 }}>
              Histórico
            </Text>

            {lawsuit.observation_title && (
              <View>
                <Text style={{ ...fontSemiBold, marginTop: 5 }}>
                  OBSERVAÇÃO IMPORTANTE:
                </Text>
                <Text
                  style={{
                    ...styles.bodySubtitle,
                    lineHeight: 1.8,
                  }}
                >
                  {lawsuit.observation_title}
                </Text>
              </View>
            )}

            {observations.map((item) => {
              return (
                <View key={item.id} style={styles.itemDiv}>
                  <Text
                    style={{
                      ...styles.bodySubtitle,
                      ...fontSemiBold,
                    }}
                  >
                    {format(parseISO(item.created_at), "dd/MM/yyyy HH:mm")}
                  </Text>
                  <Text
                    style={{
                      ...styles.bodySubtitle,
                      lineHeight: 1.8,
                    }}
                  >
                    {item.observation}
                  </Text>
                </View>
              );
            })}

            {!observations || observations?.length === 0 ? (
              <View style={styles.itemDiv}>
                <Text style={styles.bodySubtitle}>
                  Nenhum histórico registrado
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ModalLawsuitDetails = ({ show, handleClose, lawsuit, observations }) => {
  const returnFileNamePDF = () => {
    const number = lawsuit.number.replace(/[^a-zA-Z0-9]/g, "");
    return `processo-${number}-${new Date().getTime()}`;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="xl"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Processo disponível para download</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          {isMobile ? (
            <PDFDownloadLink
              document={
                <Reports lawsuit={lawsuit} observations={observations} />
              }
              fileName={returnFileNamePDF()}
            >
              Download: {returnFileNamePDF()}.pdf
            </PDFDownloadLink>
          ) : (
            <PDFViewer width={"100%"} height={"900px"} title="Relatório">
              <Reports lawsuit={lawsuit} observations={observations} />
            </PDFViewer>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ModalLawsuitDetails;
