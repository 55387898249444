function totalPay(seconds, rate){
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    
    minutes = minutes % 60;

    return (( hours + (minutes / 60 ) ) * rate).toFixed(2)

}

export {totalPay}