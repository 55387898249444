import { Col, ListGroup, Row, Button } from "react-bootstrap";
import { format } from "date-fns";
import EditTimesheetEvent from "../Modals/EditTimesheetEvent";
import { useQueryClient } from "@tanstack/react-query";
import axiosAPI from "../../axiosAPI";

export default function TimesheetListItem({
  event,
  setReload,
  setReloadTotalHour,
}) {
  const queryClient = useQueryClient();

  const deleteTimesheetEvent = (values) => {
    axiosAPI.delete(`timesheet/${values.id}`).then((result) => {
      if (result.status == 200) {
        queryClient.invalidateQueries({
          queryKey: ["TimesheetAllTotalTime", "list"],
        });
        queryClient.invalidateQueries({ queryKey: ["timesheet"] });
        queryClient.invalidateQueries({
          queryKey: ["timesheet_hour_price"],
        });
        queryClient.invalidateQueries({
          queryKey: ["timesheet_total_time"],
        });
        setReloadTotalHour(true);
        setReload(true);
      }
    });
  };

  return (
    <ListGroup.Item>
      <Row>
        <Col xs={6}>
          <h6>{event.title}</h6>
          <p>{event.description}</p>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <span style={{ fontSize: "14px" }}>
            {format(new Date(event.event_start), "HH:mm, dd/MM/yyyy")}
          </span>
          <br />
          <span style={{ fontSize: "14px" }}>
            {format(new Date(event.event_end), "HH:mm, dd/MM/yyyy")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <EditTimesheetEvent
            setReload={setReload}
            setReloadTotalHour={setReloadTotalHour}
            event={event}
          />

          <Button
            className="bg-danger border shadow-sm fs-6 rounded-1 text-white borderColor"
            onClick={() => {
              deleteTimesheetEvent(event);
            }}
          >
            Deletar
          </Button>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
