import { useEffect, useState } from "react";
import axiosAPI from "../axiosAPI";

export const useTimesheetTotalTime = (clientId) => {
    const [timesheetTotalTime, setTimesheetTotalTime] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState();
    const [reload, setReload] = useState(false);

    function handleResponse(data, loading, error){
        setTimesheetTotalTime(data);
        setLoading(loading);
        setError(error);
    }

    useEffect(() => {

        setLoading(true)

        axiosAPI.get(`/timesheet_total_time?clientId=${clientId}`)
        .then((response) => handleResponse(response.data.timesheet, false, false))

        setReload(false);

    }, [clientId, reload])

    return {
        timesheetTotalTime, isLoading, isError, setReload
    };
}