import React from "react";
import { Card, ListGroup } from "react-bootstrap";

import ModalLawsuitDetails from "../../components/Modals/LawsuitDetails";
import ButtonPDF from '../../components/ButtonPDF';

export default function LawsuitDetailCard({
  lawsuit,
  observations,
  isFetching,
}) {
  const [isViewModal, setIsViewModal] = React.useState(false);

  return (
    <Card>
      <Card.Body>
        <ButtonPDF
          isFetching={isFetching}
          setFnCallback={() => setIsViewModal(true)}
        />

        <hr></hr>

        <p
          style={{
            fontSize: 20,
            fonstWeight: 700,
            textAlign: "left",
            marginBottom: "2px",
          }}
        >
          <b>Processo Número:</b>
        </p>
        <p
          style={{
            textAlign: "left",
          }}
        >
          {lawsuit?.number}
        </p>

        <hr></hr>
        <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto2">
              <div className="fw-bold">Cliente</div>
            </div>
            <div>{lawsuit.client_name}</div>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto2">
              <div className="fw-bold">Autor</div>
            </div>
            <div>{lawsuit.author}</div>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Posição do Cliente</div>
            </div>
            <div>{lawsuit.position}</div>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Rito</div>
            </div>
            <div>{lawsuit.rite}</div>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Tipo do processo</div>
            </div>
            <div>{lawsuit.type}</div>
          </ListGroup.Item>

          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Status do Processo</div>
            </div>
            <div>{lawsuit.status}</div>
          </ListGroup.Item>

          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Comarca</div>
            </div>
            <div>{lawsuit.district_court}</div>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Fórum</div>
            </div>
            <div>{lawsuit.forum}</div>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>

      <ModalLawsuitDetails
        show={isViewModal}
        handleClose={() => setIsViewModal(!isViewModal)}
        lawsuit={lawsuit}
        observations={observations}
      />
    </Card>
  );
}
