import { Badge, FloatingLabel, Form, Button } from "react-bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import axiosAPI from "../../axiosAPI";
import { toast } from 'react-toastify';
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

export default function EditClientHourPriceModal({clientId, setReload}){
    const [modalShow, setModalShow] = React.useState(false);
    const {register, handleSubmit, reset} = useForm();
    const queryClient = useQueryClient()

    const onSubmitEditValue = values => {
        setReload(true)
        setModalShow(false)
        axiosAPI.post('timesheet_price', values)
        .then((data) => {
            queryClient.invalidateQueries({ queryKey: ['TimesheetAllTotalTime', 'list'] });
            queryClient.invalidateQueries({ queryKey: ['timesheet'] });
            queryClient.invalidateQueries({ queryKey: ['timesheet_hour_price'] });
            queryClient.invalidateQueries({ queryKey: ['timesheet_total_time'] });
            toast.success("Valor editado !", {
                position: toast.POSITION.TOP_CENTER
            });
        })
    }

    useEffect(() => {
        reset({
            price_hour_value : '',
            clientId: ''
        })
    }, [clientId])

    return (
    <>
        <Badge onClick={() => setModalShow(true)} style={{ cursor: "pointer" }} bg="success" pill>
            <i className="bi bi-pencil-square text-white"></i>
        </Badge>

        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}    
            size="sm"
            centered
            scrollable
        >
            <Modal.Header closeButton className='modalGradient'>
                <Modal.Title id="contained-modal-title-vcenter" className='text-white'>
                    <i class="bi bi-person-workspace"></i>&nbsp; Editar Custo/Hora
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmitEditValue)}>
                    <Form.Group>
                        <FloatingLabel
                            label="Valor"
                        >
                            <Form.Control type="number" className='form-control' name="price_hour_value" {...register('price_hour_value')}/>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Control type='hidden' defaultValue={clientId}  className='form-control' name="client_id" {...register('client_id')}/>

                    <Button className='mt-4 d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor' type='submit'  style={{ backgroundColor: '#DAA520' }}>
                        <i className="bi bi-funnel"></i>&nbsp;
                        Salvar
                    </Button> 
                </Form>
            </Modal.Body>

        </Modal>
        </>
    )
}