import { useEffect, useState } from "react";
import axiosAPI from "../axiosAPI";

export const useTimesheet = (timesheetId, filter = false) => {
    const [timesheet, setTimesheet] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState();
    const [reload, setReload] = useState();

    function handleResponse(data, loading, error){
        setTimesheet(data);
        setLoading(loading)
        setError(error)
    }   

    useEffect(() => {
        if(reload) {
            setLoading(true)
        }
        if(timesheetId === undefined && !filter){
            setLoading(true)
            axiosAPI.get('/timesheet')
            .then((response) => handleResponse(response.data.timesheet, false, false))
        }
        
        if(timesheetId){
            axiosAPI.get('/timesheet/' + timesheetId)
            .then((response) => handleResponse(response.data.timesheet, false, false))
        }

        if(filter){
            if (!Object.values(filter).every(x => x === null || x === '')){
                setLoading(true)
                axiosAPI.get(`/timesheet?clientId=${filter.clientId??''}`)
                .then((response) => handleResponse(response.data.timesheet, false, false))
            }
        }
        setReload(0)
    }, [filter, reload])

    return {
        timesheet, isLoading, isError, setReload
    };
}
