import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Spinner, Button, Form, FloatingLabel } from "react-bootstrap";
import { toast } from "react-toastify";

import axiosAPI from "../../axiosAPI";

import { SelectClients } from "../../components/Selects/SelectClients";
import SelectEmployeesUsers from "../../components/Selects/SelectEmployeesUsers";

export default function FormRegisterCase(props) {
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [supportOwner, setSupportOwner] = useState(1);
  const { register, handleSubmit, reset, control } = useForm();

  const onSubmit = (values) => {
    const payload = {
      client_id: values.client_id,
      title: values.title,
      description: values.description,
      obs: values.obs,
      employee_id: supportOwner == 1 ? null : values.user_id,
    };

    setIsSubmitting(true);

    axiosAPI
      .post("/support_case", payload)
      .then((result) => {
        if (result.status == 200) {
          reset();
          setIsSubmitting(false);
          toast.success("Atendimento cadastrado");
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(
            "Ops, ocorreu um erro. Tente novamente ou entre em contato com o administrador do sistema."
          );
        }
      });
  };

  return (
    <>
      {isSubmiting ? (
        <div className="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <SelectClients register={register} required/>
            </div>

            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Título">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="title"
                  {...register("title")}
                  placeholder="Insira um título descritivo para o atendimento"
                  required
                />
              </FloatingLabel>
            </div>
          </div>

          <div className="d-flex flex-wrap mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form.Control
                as="textarea"
                name="description"
                placeholder="Descrição"
                style={{ height: "150px" }}
                {...register("description")}
              />
            </div>
          </div>

          <div className="d-flex flex-wrap mt-3 mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form.Control
                as="textarea"
                name="obs"
                placeholder="Observações"
                style={{ height: "100px" }}
                {...register("obs")}
              />
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel
                controlId="floatingInput"
                label="Esse atendimento é:"
              >
                <Form.Select
                  onChange={(e) => setSupportOwner(e.target.value)}
                  className="form-select form-control"
                  name="support_case_owner"
                >
                  <option value="1">Para Mim</option>
                  <option value="2">Para um funcionário</option>
                </Form.Select>
              </FloatingLabel>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              {supportOwner == 2 && (
                <SelectEmployeesUsers
                  register={register}
                  label={"Responsável"}
                />
              )}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center col-12 col-sm-12 col-md-5 col-lg-4 mt-2 mb-5">
            <Button
              type="submit"
              className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor btn-bg-primary"
            >
              <i className="bi bi-headset"></i>&nbsp; Cadastrar atendimento
            </Button>
          </div>
        </Form>
      )}
    </>
  );
}
