import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FormLogin(props) {
  const { register, handleSubmit, reset } = useForm();

  return (
    <Form onSubmit={handleSubmit(props.onSubmitLogin)}>
      <FloatingLabel label="E-mail" className="mb-3">
        <Form.Control
          type="email"
          {...register("email")}
          disabled={false}
          id="email"
          placeholder="nome@email.com"
        />
      </FloatingLabel>
      <FloatingLabel label="Senha" className="mb-3">
        <Form.Control
          type="password"
          {...register("password")}
          id="senha"
          placeholder="Senha"
        />
      </FloatingLabel>

      <p className="text-end mb-0">
        <Link to="/esqueci-minha-senha" className="text-black">
          Esqueceu sua senha?
        </Link>
      </p>

      <br />
      <div className="w-100">
        {props.loadingLogin ? (
          <Button
            className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal"
            style={{ backgroundColor: "#DAA520" }}
            disabled
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="text-white"
            />
            <span className="text-white">&nbsp;Carregando...</span>
          </Button>
        ) : (
          <Button
            type="submit"
            className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-black borderColor"
            style={{ backgroundColor: "#DAA520" }}
          >
            <i className="bi bi-check-circle"></i>&nbsp; Entrar
          </Button>
        )}
      </div>
    </Form>
  );
}
