import React from "react";
import Menu from "./components/Menu";
import NavbarDashboard from "./components/NavbarDashboard";
import AuthorizedPages from "./authorizedPages";

const BaseLayout = ({ title, page, children }) => {
  return (
    <>
      <NavbarDashboard />

      <Menu />

      <div className="height"></div>

      <div
        style={{ height: "100%", minHeight: "100vh" }}
        className="container d-flex flex-wrap justify-content-center uk-margin-xlarge-bottom"
      >
        <div className="col-12 col-sm-12 col-md-10 col-lg-10 ">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 titlePage">
              <p className="fs-4 text-center fw-bold">{title}</p>
            </div>
            <AuthorizedPages page={page}>{children}</AuthorizedPages>
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseLayout;
