import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import axiosAPI from "./axiosAPI";

import { AppContextProvider } from "./contexts/AppContextProvider";

import Calendar from "./pages/Calendar";
import Chat from "./pages/Chat";
import Customers from "./pages/Customers";
import Dashboard from "./pages/Dashboard";
import LawsuitDetail from "./pages/DetailedEntites/Lawsuit";
import Favorites from "./pages/Favorites";
import Arquivos from "./pages/Files";
//import Financial from "./pages/Financial";
import LawSuit from "./pages/LawSuit";
import SupportCase from "./pages/SupportCase";
import SupportCaseDetail from "./pages/SupportCaseDetail";
//import LawsuitNotifications from "./pages/LawsuitNotifications";
import Login from "./pages/Login";
import NotificationDetail from "./pages/NotificationDetail";
import Notifications from "./pages/Notifications";
import Presentation from "./pages/Presentation";
//import ProfilePage from "./pages/Profile";
import Questions from "./pages/Questions";
import Team from "./pages/Team";
import TimeSheet from "./pages/TimeSheet";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function PrivateRoute({ children }) {
  const token = localStorage.getItem("token");

  if (token) {
    axiosAPI.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }

  return token ? (
    <AppContextProvider>{children}</AppContextProvider>
  ) : (
    <Navigate to="/login" />
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Presentation />} />
      <Route path="/perguntas-frequentes" element={<Questions />} />
      <Route path="/login" element={<Login />} />
      <Route path="/esqueci-minha-senha" element={<ForgotPassword />} />
      <Route path="/resetar-senha/:token" element={<ResetPassword />} />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadastro-cliente"
        element={
          <PrivateRoute>
            <Customers />
          </PrivateRoute>
        }
      />

      <Route
        path="/chat"
        element={
          <PrivateRoute>
            <Chat />
          </PrivateRoute>
        }
      />

      <Route
        path="/processos"
        element={
          <PrivateRoute>
            <LawSuit />
          </PrivateRoute>
        }
      />

      <Route
        path="/processo/:id"
        element={
          <PrivateRoute>
            <LawsuitDetail />
          </PrivateRoute>
        }
      />

      <Route
        path="/atendimento"
        element={
          <PrivateRoute>
            <SupportCase />
          </PrivateRoute>
        }
      />

      <Route
        path="/atendimento/:id"
        element={
          <PrivateRoute>
            <SupportCaseDetail />
          </PrivateRoute>
        }
      />

      <Route
        path="/equipe"
        element={
          <PrivateRoute>
            <Team />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/financeiro"
        element={
          <PrivateRoute>
            <Financial />
          </PrivateRoute>
        }
      /> */}

      <Route
        path="/calendario"
        element={
          <PrivateRoute>
            <Calendar />
          </PrivateRoute>
        }
      />

      <Route
        path="/favoritos"
        element={
          <PrivateRoute>
            <Favorites />
          </PrivateRoute>
        }
      />

      <Route
        path="/arquivos"
        element={
          <PrivateRoute>
            <Arquivos />
          </PrivateRoute>
        }
      />

      <Route
        path="/timesheet"
        element={
          <PrivateRoute>
            <TimeSheet />
          </PrivateRoute>
        }
      />

      <Route
        path="/notificacoes"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />

      <Route
        path="/notificacoes/:id"
        element={
          <PrivateRoute>
            <NotificationDetail />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/lawsuitnotificacoes"
        element={
          <PrivateRoute>
            <LawsuitNotifications />
          </PrivateRoute>
        }
      /> */}

      {/* <Route
        path="/perfil"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      /> */}
    </Routes>
  );
}

export default AppRoutes;
