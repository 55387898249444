import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import { useCheckAuthorizedPages } from "../../hooks/useCheckAuthorizedPages";
import "../ButtonLogin/styles.css";
import "../Navbar/styles.css";
import "./styles.css";
import ModalDeleteAccount from "./ModalDeleteAccount";
import IconCoroa from "../../assets/coroa.png";

function Menu() {
  const navigate = useNavigate();
  const { authorizedPages, isLoading, isError } = useCheckAuthorizedPages();
  const [viewModalDeleteAccount, setViewModalDeleteAccount] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [permissions, setPermissions] = useState({
    timesheet: false,
    chat: false,
    calcjuris: false,
    quantiko: false,
  });

  useEffect(() => {
    axiosAPI(`/user/permissions/entities/${user.id}`).then(({ data }) => {
      setPermissions(data.permissions);
    });
  }, [user]);

  //TODO LINK CORRECT TO ACTION DELETE ACCOUNT
  const handleDeleteAccount = () => {
    setViewModalDeleteAccount(false);
    axiosAPI
      .delete(`/user/softdelete/${user.id}`)
      .then(({ data }) => {
        toast.info(
          "Sua solicitação de exclusão de conta foi solicitada com sucesso."
        );
        navigate("/login");
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  };

  return (
    <>
      <div className="container">
        <ul className="unorderedList">
          {isLoading ? (
            <div className="loader-spinner">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : (
            <>
              <Link to="/dashboard">
                <li>
                  <span>Dashboard &nbsp;&nbsp;</span>
                  <i className="bi bi-house"></i>
                </li>
              </Link>

              {authorizedPages?.client ? (
                <Link to="/cadastro-cliente">
                  <li>
                    <span>Clientes &nbsp;&nbsp;</span>
                    <i className="bi bi-person-plus"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.support_case ? (
                <Link to="/atendimento">
                  <li>
                    <span>Atendimento &nbsp;&nbsp;</span>
                    <i className="bi bi-headset"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.lawsuit ? (
                <Link to="/processos">
                  <li>
                    <span>Processos &nbsp;&nbsp;</span>
                    <i className="bi bi-info-circle"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.employee ? (
                <Link to="/equipe">
                  <li>
                    <span>Equipe &nbsp;&nbsp;</span>
                    <i className="bi bi-people"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.calendar ? (
                <Link to="/calendario">
                  <li>
                    <span>Calendário &nbsp;&nbsp;</span>
                    <i className="bi bi-calendar-event"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {/* {authorizedPages?.finance ? (
                <li>
                  <Link
                    to="/financeiro"
                    
                  >
                    Financeiro
                  </Link>
                  &nbsp;&nbsp;<i className="bi bi-graph-up-arrow"></i>
                </li>
              ) : (
                ""
              )} */}

              {authorizedPages?.file ? (
                <Link to="/arquivos">
                  <li>
                    <span>Arquivos &nbsp;&nbsp;</span>
                    <i className="bi bi-folder"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.favorite ? (
                <Link to="/favoritos">
                  <li>
                    <span>Favoritos &nbsp;&nbsp;</span>
                    <i className="bi bi-star"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.timesheet && permissions.timesheet ? (
                <Link to="/timesheet">
                  <li>
                    <span>Timesheet &nbsp;&nbsp;</span>
                    <i className="bi bi-file-spreadsheet"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.chat && permissions.chat ? (
                <Link to="/chat">
                  <li>
                    <span>Chat &nbsp;&nbsp;</span>
                    <i className="bi bi-chat-left-dots"></i>
                  </li>
                </Link>
              ) : (
                ""
              )}

              {authorizedPages?.calcjuris && permissions.calcjuris && (
                <a
                  href="https://www.softwaresadvog.com.br/calcjuris/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>
                    <span>CalcJuris &nbsp;&nbsp;</span>
                    <img
                      src={IconCoroa}
                      alt="icon premium"
                      id="icon-premium"
                      width="18"
                    />
                  </li>
                </a>
              )}

              {authorizedPages?.quantiko && permissions.quantiko && (
                <a
                  href="https://www.softwaresadvog.com.br/quantiko/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>
                    <span>QuantiKo &nbsp;&nbsp;</span>
                    <img
                      src={IconCoroa}
                      alt="icon premium"
                      id="icon-premium"
                      width="18"
                    />
                  </li>
                </a>
              )}

              {user.owner_id === null && (
                <a
                  href="https://www.easyvogplanos.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>
                    <span>Planos &nbsp;&nbsp;</span>
                    <i className="bi bi-bag-dash"></i>
                  </li>
                </a>
              )}

              <li>
                <Button
                  onClick={() =>
                    setViewModalDeleteAccount(!viewModalDeleteAccount)
                  }
                  rel="noreferrer"
                >
                  Excluir conta
                </Button>
              </li>
            </>
          )}
        </ul>
        <ModalDeleteAccount
          show={viewModalDeleteAccount}
          handleClose={() => setViewModalDeleteAccount(!viewModalDeleteAccount)}
          handleSubmit={handleDeleteAccount}
        />
      </div>
    </>
  );
}

export default Menu;
