import React from "react";
import { useSupportCases } from "../../hooks/useSupportCases";
import { useActivityLog } from "../../hooks/useActivityLog";
import { useUserFiles } from "../../hooks/useUserFiles";
import { useObservations } from "../../hooks/useObservations";
import { useParams } from "react-router-dom";
import { Container, Spinner, Accordion } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import BaseLayout from "../../baseLayout";

import UserFileListItem from "../../components/ListItems/UserFileListItem";
import TextBoxObservations from "../../components/TextBoxes/TextBoxObservations";
import ObservationsList from "../../components/Lists/ObservationsList";
import ActivityLogItemList from "../../components/Lists/ActivtyLogItemList";
import ButtonPDF from "../../components/ButtonPDF";
import ModalSupportCaseDetail from "../../components/Modals/SupportCaseDetail";

import "./styles.css";

function SupportCaseDetail() {
  const { id } = useParams();
  const { cases, isFetching } = useSupportCases(id);
  const { activity, isFetching: isFetchingActivity } = useActivityLog({
    filter: { entity: "support_case", entity_id: id },
  });
  const { files } = useUserFiles(null, {
    support_case_id: id,
  });
  const { observations, isFetching: isFetchingObservations } = useObservations(
    null,
    {
      entity: "support_case",
      entity_id: id,
    }
  );
  const [isViewModal, setIsViewModal] = React.useState(false);

  return (
    <BaseLayout title={"Atendimento: #" + id} page={"support_case"}>
      <Container>
        {isFetching ? (
          <div className="d-flex justify-content-center m-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          <div className="container-items-support">
            <Accordion alwaysOpen>
              <ButtonPDF
                isFetching={isFetching}
                setFnCallback={() => setIsViewModal(true)}
              />

              <hr></hr>

              <Accordion.Item eventKey="0">
                <Accordion.Header>Log de atividades</Accordion.Header>
                <Accordion.Body style={{ maxHeight: 450, overflowY: "scroll" }}>
                  <ActivityLogItemList
                    activity={activity}
                    isFetching={isFetchingActivity}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Arquivos</Accordion.Header>
                <Accordion.Body style={{ maxHeight: 450, overflowY: "scroll" }}>
                  {files?.map((file) => (
                    <UserFileListItem file={file} />
                  ))}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Histórico</Accordion.Header>
                <Accordion.Body style={{ maxHeight: 450, overflowY: "scroll" }}>
                  <ObservationsList
                    observations={observations}
                    isFetching={isFetchingObservations}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <TextBoxObservations entity={"support_case"} id={id} />
            </Accordion>

            <div className="content-infos-support">
              <div className="content-item-support">
                <p>Título:</p>
                <h6>{cases?.title}</h6>
              </div>

              <div className="content-item-support">
                <p>Cliente:</p>
                <h6>{cases?.client_name}</h6>
              </div>

              <div className="content-item-support">
                <p>Descrição:</p>
                <h6>
                  {cases?.description
                    ? cases.description
                    : "Nenhuma descrição cadastrada."}
                </h6>
              </div>

              <div className="content-item-support">
                <p>Observação:</p>
                <h6>
                  {cases?.obs ? cases.obs : "Nenhuma observação cadastrada."}
                </h6>
              </div>

              <div className="content-item-support">
                <p>Responsável:</p>
                <h6>{cases?.employee_name}</h6>
              </div>

              <div className="content-item-support">
                <p>Data criação:</p>
                <h6>
                  {cases?.created_at
                    ? format(parseISO(cases.created_at), "dd/MM/yyyy HH:mm")
                    : null}
                </h6>
              </div>

              <div className="content-item-support">
                <p>Atualizado em:</p>
                <h6>
                  {cases?.updated_at
                    ? format(parseISO(cases?.updated_at), "dd/MM/yyyy HH:mm")
                    : null}
                </h6>
              </div>
            </div>
          </div>
        )}

        <ModalSupportCaseDetail
          show={isViewModal}
          handleClose={() => setIsViewModal(!isViewModal)}
          cases={cases}
          observations={observations}
          id={id}
        />
      </Container>
    </BaseLayout>
  );
}

export default SupportCaseDetail;
