import { useQuery } from '@tanstack/react-query'

export const useStates = () => {

    const fetchStates = async () => {
        const res = await fetch("https://brasilapi.com.br/api/ibge/uf/v1");
        return res.json();
      };
    const query = useQuery(['States'], fetchStates); 

        return {
            states: query.data,
            isLoading: query.isLoading, 
            isError: query.error, 
            isFetching: query.isFetching
        };
}

