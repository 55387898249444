import React, { createContext } from "react";

import useAuth from "../../hooks/useAuth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {
    authenticated,
    user,
    modalUpgradePlan,
    handleLogin,
    handleLogout,
    getUser,
    triggerModalUpgradePlan,
    updateUsersRegistered,
    updateUsersCaptured,
    updateLawsuitsRegistered,
    updateCloudStorage,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        modalUpgradePlan,
        handleLogin,
        handleLogout,
        getUser,
        triggerModalUpgradePlan,
        updateUsersRegistered,
        updateUsersCaptured,
        updateLawsuitsRegistered,
        updateCloudStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
