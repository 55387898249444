import React, { useState } from "react";

import "animate.css";
import "../Presentation/styles.css";
import "./styles.css";

import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

function ForgotPassword() {
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmitLogin = (values) => {
    setLoading(true);
    axiosAPI
      .post("/auth/forgot-password", values)
      .then((data) => {
        if (data.status === 200) {
          toast.success("Link enviado para o e-mail solicitado.", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        const message = error.data.message;
        let errorMessage = message;

        if (!errorMessage) {
          errorMessage =
            "Ops, ocorreu um erro na solicitação. Tente novamente.";
        }

        if (message.includes("user")) {
          errorMessage =
            "Usuário não encontrado. Digite um e-mail já cadastrado anteriormente.";
        } else if (message.includes("throttled")) {
          errorMessage =
            "Você já solicitou essa operação. Acesse o link enviado para o e-mail solicitado.";
        }

        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar />

      <section className="section-body">
        <div className="container-forgot-password">
          <div
            className="d-flex flex-wrap justify-content-center p-2 mt-5 w-100 shadow"
            id="boxLogin"
          >
            <div className="container ">
              <p className="fs-5 fw-bold text-black text-center p-3">
                Esqueci minha senha
              </p>

              <p className="text-black">
                Esqueceu sua senha? Por favor, digite seu
                <strong> E-mail</strong> abaixo. <br /> Vamos enviar um link
                para você criar uma nova senha.
              </p>

              <div className="content-form-forgot-password">
                <Form onSubmit={handleSubmit(onSubmitLogin)}>
                  <FloatingLabel label="E-mail" className="mb-3">
                    <Form.Control
                      type="email"
                      {...register("email")}
                      disabled={false}
                      id="email"
                      placeholder="nome@email.com"
                      required
                    />
                  </FloatingLabel>

                  {loading ? (
                    <Button
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 mt-5 rounded-2 w-100 fw-normal"
                      style={{ backgroundColor: "#DAA520" }}
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="text-white"
                      />
                      <span className="text-white">&nbsp;Enviando...</span>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="d-flex justify-content-center shadow-sm fs-6 p-2 mt-5 rounded-2 w-100 fw-normal text-black borderColor"
                      style={{ backgroundColor: "#DAA520" }}
                    >
                      <i className="bi bi-check-circle"></i>&nbsp; Enviar
                      solicitação
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </>
  );
}

export default ForgotPassword;
