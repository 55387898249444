import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import { AuthContextProvider } from "./contexts/AuthContextProvider";
import AppRoutes from "./routes";

import { Font } from "@react-pdf/renderer";
import VazirmatnBold from "./assets/Vazirmatn-Bold.ttf";
import VazirmatnSemiBold from "./assets/Vazirmatn-SemiBold.ttf";

Font.register({
  family: "Vazirmatn",
  fonts: [
    {
      src: VazirmatnBold,
      fontWeight: 800,
    },
    {
      src: VazirmatnSemiBold,
      fontWeight: 700,
    },
  ],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <AppRoutes />
        <ToastContainer
          position="top-center"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AuthContextProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  );
}

export default App;
