import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useLawsuitStatuses } from "../../hooks/useLawsuitStatuses";
import { Controller, useFormContext } from "react-hook-form";

export default function SelectLawsuitStatuses({ register, selected }) {
  const { lawsuitStatuses, isLoading, isError } = useLawsuitStatuses();
  const methods = useFormContext();

  if (selected) {
    return (
      <>
        {isLoading ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Status Processo">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_status"
                {...methods?.register("lawsuit_status")}
                aria-label="Status Processo"
              >
                <option>Carregando... </option>
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : methods ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Status Processo">
              <Form.Select
                className="form-select form-control"
                aria-label="Status Processo"
                {...methods.register("lawsuit_status")}
                defaultValue={selected}
                required
              >
                <option value="">Selecione ...</option>
                {lawsuitStatuses?.map((lawsuitStatuses) => (
                  <option key={lawsuitStatuses.id} value={lawsuitStatuses.id}>
                    {lawsuitStatuses.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : (
          ""
        )}
      </>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <div>
            <FloatingLabel controlId="floatingInput" label="Status Processo">
              <Form.Select
                className="form-select form-control"
                name="lawsuit_status"
                {...register("lawsuit_status")}
                aria-label="Status Processo"
                required
              >
                <option>Carregando... </option>
              </Form.Select>
            </FloatingLabel>
          </div>
        ) : (
          <div>
            <FloatingLabel controlId="floatingInput" label="Status Processo">
              <Form.Select
                className="form-select form-control"
                aria-label="Status Processo"
                {...register("lawsuit_status")}
              >
                <option value="">Selecione ...</option>
                {lawsuitStatuses?.map((lawsuitStatuses) => (
                  <option key={lawsuitStatuses.id} value={lawsuitStatuses.id}>
                    {lawsuitStatuses.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </div>
        )}
      </>
    );
  }
}
