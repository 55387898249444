import React, { createContext } from "react";

import useUserNotifications from "../../hooks/useUserNotifications";

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const {
    notifications,
    isLoading,
    isFetching,
    getNotificationMutation,
    updateNotification,
  } = useUserNotifications();

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        isLoading,
        isFetching,
        getNotificationMutation,
        updateNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
