import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function EditIconButton({ action, param, title, children }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <i class="bi bi-pencil-square" onClick={() => setModalShow(true)}></i>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i class="bi bi-person-workspace"></i>&nbsp; {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
          <Col className="d-flex justify-content-between align-center">
            <Button
              className="mt-4 d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
              onClick={() => setModalShow(false)}
              style={{ backgroundColor: "#DAA520" }}
            >
              Cancelar
            </Button>

            <Button
              className="mt-4 d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
              onClick={() => {
                action(param);
                setModalShow(false);
              }}
              style={{ backgroundColor: "#DAA520" }}
            >
              <i className="bi bi-funnel"></i>&nbsp; Salvar
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}
