import React, { useState } from "react";
import { useStates } from "../../hooks/useStates"
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export const SelectState = ({ onChange, register, selected }) => {
    const { states } = useStates();
    const [selectedState, setSelectedState] = useState(selected);

    const handleStateUpdate = (value) => {
        setSelectedState(value)
        onChange(value)
    }

    if (states?.length == 0) {
        return (<></>)
    }

    return (
        <FloatingLabel
            label="Estado"
        >
            <Form.Select
                value={selectedState}
                class="form-select form-control"
                name='state' aria-label="Estado"
                {...register('state', {
                    onChange: (e) => { handleStateUpdate(e.target.value) }
                }

                )}
            >
                <option value="">Selecione um estado...</option>
                {states && (
                    <>
                        {states.length > 0 && states?.map(state =>
                            <option key={state.sigla} value={state.sigla}>{state.nome}</option>
                        )}
                    </>
                )}
            </Form.Select>
        </FloatingLabel>
    )
}