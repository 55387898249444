import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import BaseLayout from "../../baseLayout";
import UserFilesList from "../../components/Lists/UserFilesList";
import UploadFileModal from "../../components/Modals/UploadFileModal";
import { useUserFiles } from "../../hooks/useUserFiles";

function Files() {
  const convertMBtoGB = (megabytes) => {
    const gigabits = ((megabytes / 1024 / 1024) * 8) / 1000;
    return gigabits;
  };
  const { files, isLoading, isFetching } = useUserFiles(null, {
    category: null,
    lawsuit_id: null,
  });
  const [sizeFolder, setSizeFolder] = useState(0);

  useEffect(() => {
    files &&
      files.map((file) => {
        setSizeFolder(convertMBtoGB(sizeFolder + parseInt(file.size)));
      });
  }, [files]);

  return (
    <>
      <BaseLayout title={"Arquivos"} page={"file"}>
        <Container>
          <Row>
            <Col xs="12" sm="12" md="6" lg="4">
              <UploadFileModal />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Tab.Container
                unmountOnExit={true}
                id="left-tabs-example"
                defaultActiveKey="general"
              >
                <Row>
                  <Col sm={3}>
                    <Nav
                      variant="pills"
                      className="flex-column"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="general">Arquivos Gerais</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="clients">
                          Arquivos de Clientes
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="lawsuits">
                          Arquivos de Processos
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="employees">
                          Arquivos de Funcionários
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="support_case">
                          Arquivos de Atendimento
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane unmountOnExit={true} eventKey="general">
                        <UserFilesList category={"general"} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit={true} eventKey="clients">
                        <UserFilesList category={"clients"} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit={true} eventKey="lawsuits">
                        <UserFilesList category={"lawsuits"} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit={true} eventKey="employees">
                        <UserFilesList category={"employees"} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit={true} eventKey="support_case">
                        <UserFilesList category={"support_case"} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </BaseLayout>
    </>
  );
}

export default Files;
