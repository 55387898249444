import { useQueryClient } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";

export default function DeleteFileButton({ id }) {
  const queryClient = useQueryClient();

  function deleteFile(id) {
    axiosAPI
      .delete(`files/${id}`)
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["userFiles", "list"] });
        toast.success("Arquivo deletado");
      })
      .catch((_) => {
        toast.error(
          "Ops, ocorreu um erro. Tente novamente ou entre em contato com o administrador do sistema."
        );
      });
  }

  return (
    <div className="d-grid gap-2  d-md-flex justify-content-md-end">
      <Button
        className="btn btn-primary me-md-2 shadow-sm fs-6 p-2 rounded-2 fw-normal text-white borderColor"
        style={{ backgroundColor: "#dc3545", borderColor: "#dc3545" }}
        onClick={() => deleteFile(id)}
      >
        <i className="bi bi-trash-fill"></i>&nbsp; Deletar
      </Button>
    </div>
  );
}
