import React from "react";

import Logo from "../../assets/logo.png";

function Banner() {
  return (
    <section className="banner-presentation">
      <div className="container-fluid">
        <img src={Logo} alt="logotipo easy vog" id="logo-section" />

        <div className="typewriter">
          <p className="text-white">
            Um jeito <span className="facil">fácil</span> de se resolver
          </p>
        </div>
      </div>
    </section>
  );
}

export default Banner;
