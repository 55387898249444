import React from "react";
import { Chart } from "react-google-charts";

export const array = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7], 
];


export default function PieChartGraph({data, name}) {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={{
        title: name,
        pieHole: 0.4,
        is3D: false,
      }}
    />
  );
}
