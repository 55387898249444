import { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useEmployees } from "../../hooks/useEmployees";

export function SelectEmployeesByRoles({
  register,
  onChange,
  selected,
  role,
  label,
  option,
}) {
  const { employees, isLoading, isFetching } = useEmployees(
    undefined,
    role ? { role: role } : false
  );
  const [selectedEmployee, setSelectedEmployee] = useState(selected);

  const handleEmployeeUpdate = (event) => {
    setSelectedEmployee(event.target.value);
  };

  if (isFetching) {
    return (
      <>
        <FloatingLabel controlId="floatingInput" label={label}>
          <Form.Select
            class="form-select form-control posicao_cliente"
            name="advogado"
            aria-label="advogado"
            required
          >
            <option value="0">Carregando...</option>
          </Form.Select>
        </FloatingLabel>
      </>
    );
  }

  return (
    <>
      <FloatingLabel controlId="floatingInput" label={label}>
        <Form.Select
          class="form-select form-control posicao_cliente"
          name="advogado"
          {...register("client_employee_by_employee_id", {
            onChange: (e) => handleEmployeeUpdate(e),
          })}
          value={selectedEmployee}
          aria-label="advogado"
          required
        >
          <option value="0">{option}</option>
          {employees?.map((employee) => (
            <option value={employee.employee_id}>{employee.name}</option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}
