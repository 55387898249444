import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axiosAPI from "../../../axiosAPI";
import { useChatMessages } from "../../../hooks/useChatMessages";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import "./styles.css";

export default function ChatBox({ selectedChat }) {
  const { messages, isFetching, isLoading } = useChatMessages({
    chatId: selectedChat,
  });
  const queryClient = useQueryClient();
  const [isSubmmiting, setIsSubmmiting] = useState(false);
  const messagesEndRef = useRef(null);

  const { register, handleSubmit, reset } = useForm();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onSubmitMessage = (values) => {
    values["chat"] = selectedChat;

    if (values?.message.trim() === "" || values?.message === "") {
      return false;
    }

    setIsSubmmiting(true);
    axiosAPI
      .post("/chat/messages", values)
      .then((result) => {
        if (result.status == 200) {
          queryClient.invalidateQueries({
            queryKey: ["chatMessages", "detail"],
          });
          setIsSubmmiting(false);
          reset();
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  };

  return (
    <>
      <div className="container-chat">
        {isLoading ? (
          <div className="loader-spinner mt-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          messages.map((message, i) => {
            if (
              message.user_id === JSON.parse(localStorage.getItem("user")).id
            ) {
              return (
                <div
                  key={message.id + i}
                  className="container-chat-message message-send-me"
                >
                  <div className="content-chat-message message-send-me">
                    <div className="chat-message me">
                      <p className="message">{message.message}</p>
                      <p className="date">
                        {format(
                          parseISO(message.created_at),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={message.id + i}
                  className="container-chat-message message-send-other"
                >
                  <div className="content-chat-message message-send-other">
                    <div className="chat-message other">
                      <p className="message">{message.message}</p>
                      <p className="date">
                        {format(
                          parseISO(message.created_at),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          })
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit(onSubmitMessage)}>
        <Row>
          <Col xs={9}>
            <input
              type="text"
              className="form-control"
              name="message-box"
              {...register("message")}
              placeholder="Escreva uma mensagem"
            />
          </Col>
          <Col>
            {isSubmmiting ? (
              <Button
                disabled
                style={{ backgroundColor: "#DAA520" }}
                className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
              >
                <span className="text-white">Enviando...</span>&nbsp;
                <Spinner size="sm" animation="border" variant="light" />
              </Button>
            ) : (
              <Button
                type="submit"
                style={{ backgroundColor: "#DAA520" }}
                className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
              >
                Enviar
              </Button>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
}
