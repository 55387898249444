import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useObservations = (id, filter = false) => {
    let url;
    let queryKey;

    if(id){
        queryKey = 'detail';
        url = `/${id}`;
    }

    
    if(filter){
        queryKey = "list";
        url = `?entity=${filter.entity??''}&entity_id=${filter.entity_id??''}`
    }

    const query = useQuery(['observations', queryKey, url], (url) =>
        axiosAPI.get('/observations' + url.queryKey[2])
        .then((res) => res.data)
    );

    return {
        observations: query.data?.observations, 
        isLoading: query.isLoading, 
        isError: query.error, 
        isFetching: query.isFetching
    };
}