import React from "react";

import { Button, Modal } from "react-bootstrap";

import IconCoroa from "../../assets/coroa.png";
import IconAviso from "../../assets/aviso.png";

function ModalUpgradePlan({ show, update }) {
  return (
    <Modal show={show} onHide={() => update()} size="xs" scrollable>
      <Modal.Header closeButton className="modalGradient">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-white"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 className="subtitle-modal-tools mb-4">
          <img
            src={IconAviso}
            alt="icon aviso"
            id="icon-aviso"
            width="18"
            className="text-white"
          />{" "}
          Ops! Limite de recursos atingido
        </h2>

        <h2 className="title-modal-tools text-center">
          Faça <span>upgrade do seu plano atual </span> para aproveitar todos os
          recursos.
        </h2>

        <div className="container">
          <div className="content-btns-modal-tools">
            <div className="btn-modal-tools">
              <a
                href="https://www.easyvogplanos.com/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-white"
                    style={{ backgroundColor: "#222222" }}
                  >
                    <img
                      src={IconCoroa}
                      alt="icon premium"
                      id="icon-premium"
                      width="18"
                      className="text-white"
                    />
                    &nbsp; Ver planos
                  </Button>
                </div>
              </a>
              <div className="modal-tools-information">
                <i className="bi bi-info-circle"></i>
                &nbsp;
                <p>
                  Escolha seu Plano! A partir de R$ 89,90 mensais! Valores
                  PROMOCIONAIS de lançamento!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalUpgradePlan;
