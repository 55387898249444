import React from "react";

import VideoAbout from "../../assets/presentation.mp4";
import Poster from "../../assets/poster.png";

function Video() {
  return (
    <div className="video-container">
      <video
        className="shadow-lg"
        controls="controls"
        preload="none"
        poster={Poster}
      >
        <source id="mp4" src={VideoAbout} type="video/mp4" />
        <source id="webm" src={VideoAbout} type="video/webm" />
        <source id="ogv" src={VideoAbout} type="video/ogg" />
      </video>

      <p className="fs-2 textBold aboutMore"></p>
    </div>
  );
}

export default Video;
