import { useEffect, useState } from "react";
import {
  Controller,
  useForm,
  useFormContext,
  FormProvider,
} from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputMask from "react-input-mask";
import axiosAPI from "../../axiosAPI";
import { format } from "date-fns";
import { SelectClients } from "../Selects/SelectClients";
import IntlCurrencyInput from "react-intl-currency-input";
import SelectLawsuitClientPositions from "../Selects/SelectLawsuitClientPositions";
import SelectLawsuitStatuses from "../Selects/SelectLawsuitStatuses";
import SelectLawsuitTypes from "../Selects/SelectLawsuitTypes";
import SelectLawsuitRites from "../Selects/SelectLawsuitRites";
import { Spinner } from "react-bootstrap";
import { DevTool } from "@hookform/devtools";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function LawsuitEditForm({
  lawsuit,
  setEditLawsuit,
  modalShow,
  setModalShow,
}) {
  const methods = useForm();

  const queryClient = useQueryClient();

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const onSubmitEditLawsuit = (values) => {
    axiosAPI
      .patch(`/lawsuits/${lawsuit.id}`, values)
      .then((result) => {
        if (result.status == 200) {
          queryClient.invalidateQueries({ queryKey: ["lawsuits", "list"] });
          toast.success("Processo editado", { position: "bottom-right" });
          setEditLawsuit(false);
        }
      })
      .catch((_) =>
        toast.error(
          "Ops, ocorreu um erro. Tente novamente."
        )
      );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmitEditLawsuit)}>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <SelectClients
                register={methods.register}
                selected={lawsuit.client_id}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <InputMask
                mask="9999999-99.9999.9.99.9999"
                defaultValue={lawsuit.number}
                className="form-control"
                {...methods.register("lawsuit_number")}
                name="lawsuit_number"
                placeholder="Insira o número do processo"
              />
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Título">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="lawsuit_title"
                  defaultValue={lawsuit.title}
                  {...methods.register("lawsuit_title")}
                  placeholder="Insira um título descritivo para o processo"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Palavra chave">
                <InputMask
                  className="form-control"
                  name="lawsuit_keyword"
                  defaultValue={lawsuit.keyword}
                  {...methods.register("lawsuit_keyword")}
                  placeholder="Insira a palavra chave"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className=" d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Autor">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="lawsuit_author"
                  defaultValue={lawsuit.author}
                  {...methods.register("lawsuit_author")}
                  placeholder="Insira o nome do autor"
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Data ingresso">
                <Form.Control
                  type="date"
                  className="form-control"
                  defaultValue={format(
                    new Date(lawsuit.created_at),
                    "yyyy-MM-dd"
                  )}
                  name="lawsuit_creation_date"
                  {...methods.register("lawsuit_creation_date")}
                  placeholder="Insira a data ingresso"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Fórum">
                <Form.Control
                  type="text"
                  className="form-control"
                  name="forum"
                  defaultValue={lawsuit.forum}
                  placeholder="Insira o fórum"
                  {...methods.register("forum")}
                />
              </FloatingLabel>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <FloatingLabel controlId="floatingInput" label="Comarca">
                <Form.Control
                  type="text"
                  className="form-control"
                  defaultValue={lawsuit.district_court}
                  name="district_court"
                  placeholder="Insira a comarca"
                  {...methods.register("district_court")}
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitClientPositions
                selected={lawsuit?.client_position_id ?? 1}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitStatuses selected={lawsuit?.status_id ?? 1} />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitTypes selected={lawsuit?.type_id ?? 1} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <SelectLawsuitRites selected={lawsuit?.rite_id ?? 1} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <FloatingLabel
              controlId="floatingInput"
              label="Valor arbitrado a causa"
            >
              <Controller
                name="lawsuit_budget"
                control={methods.control}
                defaultValue={lawsuit.budget}
                render={({ field }) => (
                  <IntlCurrencyInput
                    name="lawsuit_budget"
                    max={10000000000}
                    currency="BRL"
                    className="form-control"
                    onChange={(e, value) => field.onChange(value)}
                    value={lawsuit.budget}
                    config={currencyConfig}
                  />
                )}
              />
            </FloatingLabel>
          </div>
          <div className="w-100">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5">
              <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                    onClick={() => setEditLawsuit(false)}
                    type="button"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    Cancelar
                  </Button>
                </div>
                <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                    type="submit"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <i className="bi bi-funnel"></i>&nbsp; Salvar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DevTool control={methods.control} /> {/* set up the dev tool */}
        </form>
      </FormProvider>
    </>
  );
}
