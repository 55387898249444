import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import AuthContext from "../../contexts/AuthContext/AuthContext";
import FormLogin from "./forms/FormLogin";
import { testFullNameRegex } from "../../helper/testFullName";

import "./styles.css";

function Login() {
  const [recoverViewState, setRecoverViewState] = useState(false);
  const { handleLogin } = useContext(AuthContext);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();

  const onSubmitLogin = (values) => {
    setLoadingLogin(true);
    handleLogin(values, setLoadingLogin);
  };

  const onSubmitSignIn = (values) => {
    if (!testFullNameRegex(values.name)) {
      setError("name");
      return;
    }

    setLoadingSignIn(true);
    axiosAPI
      .post("/auth/register", values)
      .then((data) => {
        if (data.status === 201) {
          toast.success("Usuário cadastrado !", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoadingSignIn(false);
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          let errorMessage = "";
          if (
            JSON.parse(error.response.data).email ==
            "The email has already been taken."
          ) {
            errorMessage = errorMessage + "Email já cadastrado. ";
          }
          if (
            JSON.parse(error.response.data).password ==
            "The password must be at least 6 characters."
          ) {
            errorMessage =
              errorMessage + "A senha precisa ter no mínimo, 6 caracteres. ";
          }

          toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoadingSignIn(false);
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container d-flex flex-wrap justify-content-center">
          <h1 className="login-title">
            Entre ou crie uma conta, é rápido e{" "}
            <span className="fw-bold" id="easyText">
              fácil
            </span>
            !
          </h1>
        </div>

        <div className="container d-flex flex-wrap justify-content-center mt-3">
          <div
            className="d-flex flex-wrap justify-content-center p-2 w-100 shadow"
            id="boxLogin"
          >
            <div className="container ">
              <p className="fs-5 fw-bold text-black text-center p-3">
                Faça o login
              </p>
              <div className="tabs">
                <div className="tabby-tab">
                  <input
                    type="radio"
                    id="tab-1"
                    name="tabby-tabs"
                    defaultChecked
                  />
                  <label
                    htmlFor="tab-1"
                    style={{ display: !recoverViewState ? "block" : "none" }}
                    className="text-black text-uppercase text-center"
                  >
                    Sou assinante | membro | cliente
                  </label>
                  <div className="tabby-content">
                    <FormLogin
                      onSubmitLogin={onSubmitLogin}
                      loadingLogin={loadingLogin}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-wrap justify-content-center p-2 w-100"
            id="boxLogin"
          >
            <div className="container p-3">
              <p className="fs-5 fw-bold text-black text-center">
                Ainda não é cadastrado?
              </p>
              <p className="fs-6 text-black text-center">
                Cadastre-se a assine um plano agora mesmo
                <br /> para obter os benefícios de nossa plataforma.
              </p>
              <div className="tabs">
                <div className="tabby-tab">
                  <div>
                    <Form onSubmit={handleSubmit(onSubmitSignIn)}>
                      <FloatingLabel label="Nome Completo" className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Nome Completo"
                          id="name"
                          {...register("name", { required: true })}
                        />
                        {errors.name && (
                          <span className="span-error">
                            Preencha seu nome completo
                          </span>
                        )}
                      </FloatingLabel>
                      <FloatingLabel label="Celular" className="mb-3">
                        <InputMask
                          mask="(99) 99999-9999"
                          placeholder="Celular"
                          id="cellphone"
                          {...register("cellphone", { required: true })}
                          className="form-control"
                        ></InputMask>
                        {errors.cellphone && (
                          <span className="span-error">
                            Preencha seu celular
                          </span>
                        )}
                      </FloatingLabel>
                      <FloatingLabel label="E-mail" className="mb-3">
                        <Form.Control
                          type="email"
                          id="email"
                          placeholder="nome@email.com"
                          {...register("email", { required: true })}
                        />
                        {errors.email && (
                          <span className="span-error">Preencha seu email</span>
                        )}
                      </FloatingLabel>
                      <FloatingLabel label="Senha" className="mb-3">
                        <Form.Control
                          type="password"
                          placeholder="Senha"
                          id="password"
                          {...register("password", { required: true })}
                        />
                        {errors.password && (
                          <span className="span-error">Preencha sua senha</span>
                        )}
                      </FloatingLabel>
                      <FloatingLabel label="Repetir senha" className="mb-3">
                        <Form.Control
                          type="password"
                          id="password_confirmation"
                          placeholder="Repetir senha"
                          {...register("password_confirmation", {
                            required: true,
                          })}
                        />
                        {errors.password_confirmation && (
                          <span className="span-error">Confirme sua senha</span>
                        )}
                      </FloatingLabel>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "0.5rem",
                        }}
                      >
                        {/* <Form.Check required type="checkbox" label="Aceitar" />{" "}
                        <a href="#" className="term" {...register("accept")}>
                          política de privacidade
                        </a> */}
                      </Form.Group>
                      <div className="w-100">
                        {loadingSignIn ? (
                          <Button
                            className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal"
                            style={{ backgroundColor: "#DAA520" }}
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="text-white"
                            />
                            <span className="text-white">
                              &nbsp;Enviando...
                            </span>
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-black borderColor"
                            style={{ backgroundColor: "#DAA520" }}
                          >
                            <i className="bi bi-check-circle"></i>
                            <span className="text-register">
                              Cadastrar e assinar plano gratuito
                            </span>
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
