import { useQuery } from "@tanstack/react-query";
import axiosAPI from "../axiosAPI";

export const useChatMessages = ({ chatId, filter = false }) => {
  let url;
  let queryKey;

  if (chatId === undefined && !filter) {
    queryKey = "list";
    url = "";
  }

  if (chatId) {
    queryKey = "detail";
    url = `/${chatId}`;
  }

  if (filter) {
    queryKey = "list";
    url = `?message=${filter.client_id ?? ""}&last=${filter.last ?? false}`;
  }

  if (filter && chatId) {
    queryKey = "list";
    url = `/${chatId}?message=${filter.client_id ?? ""}&last=${
      filter.last ?? false
    }`;
  }

  const query = useQuery(
    ["chatMessages", queryKey, url],
    async (url) => {
      return axiosAPI
        .get("/chat/messages" + url.queryKey[2])
        .then((res) => res.data);
    },
    {
      refetchInterval: 4000,
    }
  );

  return {
    messages: query.data?.messages,
    isLoading: query.isLoading,
    isError: query.error,
    isFetching: query.isFetching,
  };
};
